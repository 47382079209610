import { ControllerHelper, PostController, PostDownloadDto } from 'collaboration-service';
import { LoaderInline } from 'imaginarity-react-ui';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import ComponentsFactory from 'services/ComponentsFactory';



interface PreviewMomentProps {
    id: string;
}

const PreviewMoment = (p: PreviewMomentProps) => {
    const dispatch = useDispatch();
    const [post, setPost] = React.useState<PostDownloadDto>();
    const id = p.id;

    React.useEffect(() => {
        const load = async () => {
            if (id) {
                const p = await ControllerHelper.singleCall({ id }, PostController.GetSinglePost);
                setPost(p);
            }
        }
        load();
    }, [id]);

    const postChanged = React.useMemo(() => (post: PostDownloadDto) => {
        dispatch(Actions.updatePost(post));
    }, [dispatch]);


    if (post === undefined)
        return <LoaderInline active />;

    const C = ComponentsFactory.getInstance().getPostContainerRenderer();
    return (
        <C key={post.id} post={post} postChanged={postChanged}>
            {ComponentsFactory.getInstance().renderPost(post.type ?? "")({ post, postChanged })}
        </C>
    );
}
export default PreviewMoment;