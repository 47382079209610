import { ControllerHelper, EventController, EventUploadDto, FileEntryDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Dimmer, getLinkByName, Icon } from 'imaginarity-react-ui';
import * as React from 'react';
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { DueInfo, shallowCompare } from 'services/Helpers';
import { sanitizedNothingAllowedAttributes } from 'services/Helpers/SanitizedHelper';
import { getCMSContent, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { PlaylistDetailSC as T } from "../../PlaylistDetailSC";
import MarkAsReadButton from './MarkAsReadButton';

const mapper = (s: ApplicationState) => ({
    contentLanguage: s.contentLanguage,
    currentGroup: s.currentGroup,
});

interface PlaylistFileEntryImageDimmerProps {
    playlistStateId?: string;
    id: string;
    item: FileEntryDownloadDto;
    result: DueInfo;
    playlistId: string;
    state: PlaylistItemStateDownloadDto;
    isOptional: boolean;
    transition: PlaylistEdgeTransitionFunction | undefined;
    elementIsReference: boolean;
    nodeIsDone: boolean;
    warning: boolean;
    closeDimmer: () => void;
    isPodcast?: boolean;
}

const PlaylistFileEntryImageDimmer = (p: PlaylistFileEntryImageDimmerProps) => {
    const { contentLanguage, currentGroup } = useAppSelector(mapper, shallowCompare);
    const { item, result, playlistId, state, isOptional, transition, elementIsReference, nodeIsDone, warning, closeDimmer } = p;
    const { t, currentLanguage } = useImgI18N('playlist');
    const language = contentLanguage ?? currentLanguage;
    const translatedContent = React.useMemo(() => {
        const translatedContent = getCMSContent({ fileEntry: item, filePosts: {}, language });
        return translatedContent;
    }, [item, language])

    React.useEffect(() => {
        if (currentGroup && playlistId) {
            const ev: EventUploadDto = {
                eventType: "PostViewed",
                group_id: currentGroup.id,
                payload: {
                    "PostId": item.id,
                    "From": "playlist",
                    "PlaylistId": playlistId,
                }
            };
            ControllerHelper.singleCall({ ev }, EventController.PostEvent);
        }
    }, [currentGroup, playlistId, item.id]);

    const contentFE = item?.type === "FileEntry" ? getCMSContent({ fileEntry: item as FileEntryDownloadDto, filePosts: {}, language }) : undefined;
    const title = getTranslated(item.headlines, contentLanguage)?.text ?? contentFE?.media?.fileName;

    return (
        <Dimmer active >
            <T.DimmerContainer style={{ maxWidth: "90%" }}>
                <T.DimmerFileEntryContainer >
                    <img src={getLinkByName(translatedContent?.media, "self")?.uri ?? ""} alt="Scrollable content" />
                </T.DimmerFileEntryContainer>
                <T.DimmerInfoContainer>
                    <T.DimmerInfoContainerTitle>
                        <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
                    </T.DimmerInfoContainerTitle>
                    <T.DimmerInfoContainerButton>
                        <MarkAsReadButton
                            playlistId={playlistId}
                            playlistItemState={state}
                            item={item}
                        />
                    </T.DimmerInfoContainerButton>
                    <T.DimmerInfoContainerDate>
                        {result.dueToDate &&
                            <div style={{ float: "left" }}>
                                <DateTimeFns
                                    date={result.dueToDate}
                                    noTime
                                    hideIcons
                                    textColor="grey"
                                />
                            </div>
                        }
                        {isOptional ?
                            <T.ColoredSpan color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                                {state.state === "Finished" ? t("100%") : ""}
                            </T.ColoredSpan>
                            :
                            (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                                elementIsReference ?
                                    <T.ColoredSpan color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                        {nodeIsDone ? "100%" : warning ? "warning" : result.text}
                                    </T.ColoredSpan>
                                    :
                                    <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : "@middleLightGrey"}>
                                        {result.text === "100%" ? "100%" : t("optional")}
                                    </T.ColoredSpan>
                                :
                                <T.ColoredSpan color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                                    {result.text}
                                </T.ColoredSpan>
                        }
                    </T.DimmerInfoContainerDate>
                </T.DimmerInfoContainer>

                <T.DimmerInfoContainerDescription>
                    <SafeHTML
                        html={getTranslated(item.descriptions, contentLanguage).text ?? ""}
                        allowedTags={["strong", "em", "b", "i", "u"]}
                        allowedAttributes={sanitizedNothingAllowedAttributes}
                    />
                </T.DimmerInfoContainerDescription>
                <T.DimmerInfoContainerClose onClick={closeDimmer} >
                    <Icon name="times" size={27} marginTop={3} />
                </T.DimmerInfoContainerClose>
            </T.DimmerContainer>
        </Dimmer>
    );
}
export default PlaylistFileEntryImageDimmer;