import * as _ from "lodash";
import { CMSState } from "services/ApplicationState/ApplicationState";

export const cmsFindIndexPathAndFileInCurrentFiles = (id: string, currentFiles?: CMSState['currentFiles']) => {
    if (!currentFiles)
        return undefined;
    const oKeys = Object.keys(currentFiles);
    for (let k = 0; k < oKeys.length; k++) {
        const key = oKeys[k];
        const cf = currentFiles[key];
        const i = _.findIndex(cf, f => f.id === id);
        if (i >= 0) {
            return { idx: i, key, file: cf[i] }
        }
    }
    return undefined;
}