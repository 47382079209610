import { RecursivePartial, Theme } from "imaginarity-react-ui";
import { CMSTheme } from "views/CMS/CMSTheme";
import { ContentTheme } from "views/ContentPage/ContentMain";
import { FeedTheme } from "views/Feed/FeedMain";
import { PlaylistTheme } from "views/Playlist/PlaylistMain";
import { PodcastTheme } from "views/Podcast/PodcastMain";
import { QuizTheme } from "views/Quiz/QuizMain";
import { ScormTheme } from "views/Scorm/ScormMain";
import { TubeTheme } from "views/Tube/TubeMain";
import { WikiTheme } from "views/Wiki/WikiTheme";



export const appThemeManipulation = (t: Readonly<Theme>): RecursivePartial<Theme> => {
    const tubeMainBackground = "#232325";
    const podcastMainBackground = "#1b1b1b";
    const tubeTheme: RecursivePartial<TubeTheme> = {
        loadCount: true,
        homeHeaderTitle: "Porsche Tube",
        noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/pagNoImageAvailable.svg",
        homeImage: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
        color: "@veryLightGrey", //fontColor
        backgroundColor: tubeMainBackground,
        darkMode: true,
        postItem: {
            backgroundColor: `lighten('${tubeMainBackground}', 70%)`,
            color: "@veryLightGrey",
        },
        tile: {
            backgroundColor: `lighten('${tubeMainBackground}', 50%)`,
            color: "@veryLightGrey",
        },
        headerRightImage: {
            opacity: 0.5,
            filter: "grayscale(0.5)",
            url: "https://cdn.imaginarity.com/public/projectImages/pagTubeHeaderRight.png"
        },
        showTooltips: false,
        showTubeTableView: false,
        showUploadForm: false,
        tubeAccent: "#D5001C",
        showInfo: true
    };
    const podcastTheme: RecursivePartial<PodcastTheme> = {
        loadCount: true,
        homeHeaderTitle: "Porsche Podcast",
        noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/pagNoImageAvailable.svg",
        homeImage: "https://cdn.imaginarity.com/public/projectImages/imgPodcastHeaderImageV4.png",
        color: "@veryLightGrey", //fontColor
        backgroundColor: podcastMainBackground,
        darkMode: true,
        postItem: {
            backgroundColor: `lighten('${podcastMainBackground}', 70%)`,
            color: "@veryLightGrey",
        },
        tile: {
            backgroundColor: `lighten('${podcastMainBackground}', 50%)`,
            color: "@veryLightGrey",
        },
        headerRightImage: {
            opacity: 1,
            filter: "grayscale(0)",
            url: "https://cdn.imaginarity.com/public/projectImages/pagPodcastHeaderRight.jpg"
        },
        showTooltips: false,
        showPodcastTableView: false,
        showUploadForm: false,
        podcastAccent: "#2DC9F2",
        showInfo: true,
        showNewTileAppearance: true
    };
    const contentTheme: RecursivePartial<ContentTheme> = {
        homeHeaderTitle: "Smart Mobility World",
        backgroundColor: "linear-gradient(135deg, #021132, #010816)",
        darkMode: true,
        color: "@mainBackground",
        showContentTableView: false,
        homeImage: "https://cdn.imaginarity.com/public/projectImages/pagContentHeaderImage.svg",
        noCategoryImage: "https://cdn.imaginarity.com/public/thepage/content/smwNoCategory.svg",
        contentAccent: "#35B2DB",
        tile: {
            backgroundColor: "fade('@accentBlack', 30%)",
            color: "@veryLightGrey",
        },
        headerRightImage: {
            opacity: 0.2,
            url: "https://cdn.imaginarity.com/public/projectImages/pagContentHeaderRight.svg"
        },
        postItem: {
            backgroundColor: "fade('@accentBlack', 20%)",
            color: "@veryLightGrey",
        },
    };
    const quizTheme: RecursivePartial<QuizTheme> = {
        headerTitle: "Porsche Quiz",
        headerImage: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
        noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/pagNoImageAvailable.svg",
        showTooltips: false,
        showTableView: false
    };
    const scormTheme: RecursivePartial<ScormTheme> = {
        accent: "#012126",
    };
    const cmsTheme: RecursivePartial<CMSTheme> = {
        showCmsTableMenuLegend: true,
        showCmsTableSortingAsTabs: false,
        showCmsHomeTableBottomLineButtons: true,
        showCmsAdditionalButtonsOnAdminTabs: true,
        emptyImageUrl: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
        select: {
            emptyColor: "@lightGrey",
            filledColor: "@accentLightBlue",
            emptyFadings: [10, 15, 20, 25], //[bg, border, hoverBg, hoverBorder]
            filledFadings: [15, 10, 25, 30],
        },
    };
    const feedTheme: RecursivePartial<FeedTheme> = {
        imageThankYou: "https://cdn.imaginarity.com/public/excite/excite.jpg",
        // backgroundColor: "@darkGrey",
        // color: "@lightGrey",
    };
    const wikiTheme: RecursivePartial<WikiTheme> = {
        selectVideo: {
            selectVideoFromTube: {
                showFilter: false
            }
        },
    };
    const playlistTheme: RecursivePartial<PlaylistTheme> = {
        warningLess_X_Hours: 48,
        flushText: false,
        limitDistance: 250,
        marginTopStep: 15,
        playlistDetailStickyHeader: true,
        editPlaylistPhasesLanguageSelectionINHeaderBar: true
    };


    return ({
        loaderInitialDelay: 0,
        mobileGap: 0,
        fontSize: 14,
        emptyImageUrl: "https://cdn.imaginarity.com/public/ppe/ppe_noImage.svg",
        components: {
            transparentButton: {
                overrides: {
                    // backgroundColor: "fade(lighten('@mainBackground', 90%), 50%)",
                    //backgroundColor: "rgba(0,0,0,0.05)",
                    color: "color",
                    hoverColor: {
                        backgroundColor: "rgba(0,0,0,0.15)",
                        color: "color",
                    },
                    disabledColor: {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        color: "color",
                    },
                }
            },
            halfTransparentButton: {
                overrides: {
                    // backgroundColor: "fade(lighten('@mainBackground', 90%), 50%)",
                    backgroundColor: "rgba(0,0,0,0.05)",
                    color: "color",
                    hoverColor: {
                        backgroundColor: "rgba(0,0,0,0.15)",
                        color: "color",
                    },
                    disabledColor: {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        color: "color",

                    }
                }
            },
        },
        checkbox: {
            notAnimated: true,
            notAnimatedIcon: {
                selected: {
                    name: "checkbox checked",
                },
                notSelected: {
                    name: "checkbox unchecked"
                }
            }
        },
        radioButton: {
            notAnimated: true,
            notAnimatedIcon: {
                selected: {
                    name: "circle dot",
                },
                notSelected: {
                    name: "circle"
                }
            }
        },
        sidebars: {
            padding: {
                left: 10,
                right: 0,
                top: 5,
                bottom: 5
            }
        },
        fontFaces: [
            {
                family: "PorscheNEXTWla",
                fontWeight: "thin",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-ThinItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNEXTWla",
                fontWeight: "thin",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-Thin.woff",
                format: "woff"
            },
            {
                family: "PorscheNEXTWla",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-Regular.woff",
                format: "woff"
            },
            {
                family: "PorscheNEXTWla",
                fontWeight: "normal",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-Italic.woff",
                format: "woff"
            },
            {
                family: "PorscheNEXTWla",
                fontWeight: "bold",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-BoldItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNEXTWla",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWLa-Bold.woff",
                format: "woff"
            },


            {
                family: "PorscheNextJPTP",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextJPTP-Bold.otf",
                format: "opentype"
            },
            {
                family: "PorscheNextJPTP",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextJPTP-Regular.otf",
                format: "opentype"
            },
            {
                family: "PorscheNextJPTP",
                fontWeight: "thin",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextJPTP-Thin.otf",
                format: "opentype"
            },
            {
                family: "PorscheNextKorean",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextKorean-Bold.woff2",
                format: "woff2"
            },
            {
                family: "PorscheNextKorean",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextKorean-Regular.woff2",
                format: "woff2"
            },
            {
                family: "PorscheNextKorean",
                fontWeight: "thin",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextKorean-Thin.woff2",
                format: "woff2"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-Bold.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "bold",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-BoldItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "normal",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-Italic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-Regular.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "thin",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-Thin.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWCy",
                fontWeight: "thin",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWCy-ThinItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-Bold.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "bold",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-BoldItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "normal",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-Italic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-Regular.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "thin",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-Thin.woff",
                format: "woff"
            },
            {
                family: "PorscheNextWGr",
                fontWeight: "thin",
                fontStyle: "italic",
                url: "https://cdn.imaginarity.com/public/fonts/pag/PorscheNextWGr-ThinItalic.woff",
                format: "woff"
            },
            {
                family: "PorscheNextZHTW",
                fontWeight: "normal",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/DFHeiStd-W3.otf",
                format: "opentype"
            },
            {
                family: "PorscheNextZHTW",
                fontWeight: "bold",
                fontStyle: "normal",
                url: "https://cdn.imaginarity.com/public/fonts/pag/DFHeiStd-W7.otf",
                format: "opentype"
            },
        ],
        fontFamily: `"PorscheNEXTWla", Verdana, Arial`,
        langFontFamilies: {
            "ja-JP": `"PorscheNEXTWla", "PorscheNextJPTP"`,
            "ru-RU": `"PorscheNEXTWla", "PorscheNextWCY"`,
            "ko-KR": `"PorscheNEXTWla", "PorscheNextKorean"`,
            "el-GR": `"PorscheNEXTWla", "PorscheNextWGr"`,
            "zh-CN": `"PorscheNEXTWla", SimSun`,
            "zh-TW": `"PorscheNEXTWla", "PorscheNextZHTW"`,
        },
        fontWeights: {
            light: 200,
            default: 400,
            heavy: 600,
            veryheavy: 900
        },
        emptyAvatarImageUrl: "https://cdn.imaginarity.com/public/thepage/noAvatar.svg",
        shadowedBordersOverMaxSize: "3px 0 12px -6px rgba(0,0,0,0.8), -3px 0 12px -6px rgba(0,0,0,0.8)",
        applicationLogo: "https://cdn.imaginarity.com/public/thepage/PagSvgLogo.svg",
        header: {
            // logoURL: "https://cdn.imaginarity.com/public/thepage/Porsche_wordmark_black_rgb.svg",
            logoURL: "https://cdn.imaginarity.com/public/thepage/pag_sz.svg",
            //padding: "0px 20px 0 65px",
            //rightLogoURL: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
            //collapseSides: true
        },
        colors: {
            // New Colors
            // https://designsystem.porsche.com/v3/styles/theme

            //DEFAULT COLORS
            // accent: "#D5001C",
            accent: "#E00000",

            lightGrey: "#737278",
            middleLightGrey: "#b5b4ba",
            veryLightGrey: "#f5f4f7",
            darkGrey: "#403F45",
            middleDarkGrey: "#626669",
            darkerGrey: "#000000",
            mainBackground: "#FFFFFF",
            active: "fade('@accent', 100%)",
            inactive: "fade('@lightGrey', 30%)",
            hoverColor: "#980014",

            // ADDITIONAL COLORS
            //accentRed: "#94001B",
            accentRed: "#E00000",
            accentOrange: "#FF9B00",
            accentYellow: "#FFBE00",
            accentGreen: "#018A16",
            accentLightGreen: "#09D087",
            accentTurquoise: "#017379",
            // accentLightBlue: "#5391C4",
            accentLightBlue: "#178BFF",
            accentBlue: "#0061BD",
            accentPurple: "#3C007E",
            accentPink: "#700043",
            mainForeground: "@darkGrey",
            mainBorderColor: "@middleLightGrey",
            accentBlack: "#000000",
            alertInfoSuccessTitle: "fade('@accentGreen', 20%)",
            alertInfoSuccess: "#e4f5c4",
            alertInfoErrorTitle: "fade('@accentRed', 20%)",
            alertInfoError: "#fad0d8",
            alertInfoInformationTitle: "fade('@accentBlue', 20%)",
            alertInfoInformation: "#cbdff3",
            alertInfoWarningTitle: "fade('@accentYellow', 20%)",
            alertInfoWarning: "#f8eac1",
            formInputErrorBackground: "fade('@accent', 10%)",
            formInputErrorBorder: "@accent",
            formInputActiveBackground: "@veryLightGrey",
            formInputActiveBorder: "@darkGrey",
            formInpuDefaultBackground: "@mainBackground",
            formInputDefaultBorder: "@middleLightGrey",
            disabled: "#96989A",
            warning: "#FF9B00",
            neutralLow: "#E3E4E5",
            error: "#E00000",
            success: "#018A16"
        },
        leftArea: {
            //backgroundColor: "@middleDarkGrey",
            backgroundColor: "@darkGrey",
            color: "@veryLightGrey",
        },
        headerArea: {
            backgroundColor: "@mainBackground",
        },
        mainArea: {
            backgroundColor: "@mainBackground",
            color: "@mainForeground",
        },
        rightArea: {
            backgroundColor: "@veryLightGrey",
            color: "@darkGrey",
        },
        donut: {
            //shadowed: false
        },
        applicationTheming: {
            tubeTheme,
            wikiTheme,
            contentTheme,
            scormTheme,
            quizTheme,
            feedTheme,
            cmsTheme,
            podcastTheme,
            playlistTheme
        },
        editor: {
            listFontSize: '14px',
            listFontWeight: 'none',
            p: { lineHeight: 1.4825, margin: '0 0 1em' },
            h1: { fontSize: '1.3em', lineHeight: 1.3 },
            h2: { fontSize: '1.15em', lineHeight: 1.15 },
            containerFontFamily: `"PorscheNEXTWla", Arial`,
            fontSize: '14px',
            height: 'fit-content',
            toolbarPadding: '5px',
            listPaddingLeft: '2em',
        },
        appStart: {
            // boxShadow: "0px 4px 4px -4px rgb(49, 54, 57)",
            boxShadow: "none",
            backgroundUrl: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
            color: "#313639"
        },
        tooltips: {
            primary: {
                color: "@accent",
                backgroundColor: "@mainBackground",
            },
            secondary: {
                color: "@mainForeground",
                backgroundColor: "@mainBackground",
            },
            tertiary: {
                color: "@mainForeground",
                backgroundColor: "@middleLightGrey",
            }
        },
        buttons: {
            blank: {
                color: "@middleLightGrey",
                iconColor: "@middleLightGrey",
            },
            primary: {
                backgroundColor: "@accent",
                iconColor: "@mainBackground",
                color: "@mainBackground",
                hoverColor: {
                    backgroundColor: "@hoverColor",
                },
                activeColor: {
                    backgroundColor: "@hoverColor"
                },
                disabledColor: {
                    backgroundColor: "@veryLightGrey",
                    color: "@lightGrey",
                    iconColor: "@lightGrey",
                }
            },

            secondary: {
                //backgroundColor: "@darkGrey",
                backgroundColor: "#323639",
                iconColor: "@mainBackground",
                color: "@mainBackground",
                hoverColor: {
                    backgroundColor: "#151718",
                },
                activeColor: {
                    backgroundColor: "#151718",
                },
                disabledColor: {
                    backgroundColor: "@veryLightGrey",
                    color: "@lightGrey",
                    iconColor: "@lightGrey",
                }
            },

        },
        loadingDataTable: {
            cell: {
                alternateTableRowColor: true,
                backgroundColor: "@mainBackground",
                backgroundColorSelected: "fade('@accentBlue', 8%)",
                cellBorders: {
                    bottom: {
                        thickness: 1,
                        color: "@veryLightGrey"
                    }
                },
                firstChildBorders: {
                    left: {
                        thickness: 0,
                        color: "@middleLightGrey"
                    }
                },
                lastChildBorders: {
                    right: {
                        thickness: 0,
                        color: "@middleLightGrey"
                    }

                }
            },
            borders: {
                left: {
                    color: "transparent",
                    thickness: 0
                },
                right: {
                    color: "transparent",
                    thickness: 0
                },
                top: {
                    color: "transparent",
                    thickness: 0
                },
                bottom: {
                    color: "@middleLightGrey",
                    thickness: 1
                },
            },
            header: {
                backgroundColor: "@mainBackground",
                cellBorders: {
                    bottom: {
                        thickness: 1,
                        color: "@middleLightGrey"
                    },
                    top: {
                        thickness: 0,
                        color: "transparent"
                    }
                },
                firstChildBorders: {
                    left: {
                        thickness: 0,
                        color: "@middleLightGrey"
                    }
                },
                lastChildBorders: {
                    right: {
                        thickness: 0,
                        color: "@middleLightGrey"
                    }

                }
            },
            footer: {
                // boxShadow: "0 6px 8px -6px #313639",
                backgroundColor: "@mainBackground",
                cellBorders: {
                    bottom: {
                        thickness: 0,
                        color: "transparent"
                    },
                    top: {
                        thickness: 0,
                        color: "transparent"
                    }
                },

                firstChildBorders: {
                    bottom: {
                        thickness: 0,
                        color: "transparent"
                    },
                    left: {
                        thickness: 0,
                        color: "transparent"
                    }
                },
                lastChildBorders: {
                    bottom: {
                        thickness: 0,
                        color: "transparent"
                    },
                    right: {
                        thickness: 0,
                        color: "transparent"
                    }

                }
            }
        },
        menus: {
            tertiary_menu: {
                contentAlignment: "left",
                backgroundColor: "backgroundColor",
                color: "color",
                hoverColor: {
                    backgroundColor: "lighten('@mainBackground', 97%)",
                    color: "@darkerGrey",
                    iconColor: "@darkerGrey",
                },
                activeColor: {
                    backgroundColor: "@mainForeground",
                    color: "@mainBackground",
                },
                disabledColor: {
                    backgroundColor: "@mainBackground",
                    color: "@middleLightGrey",
                },
            },
        },
        form: {
            inputs: {
                borderlessDark: {
                    themeViewStates: {
                        active: {
                            color: "@accent",
                            backgroundColor: "transparent",
                        },
                        inactive: {
                            backgroundColor: "transparent",
                            color: "@middleLightGrey",
                        }
                    }
                },
                borderless: {
                    themeViewStates: {
                        active: {
                            color: "@accent",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                        },
                        inactive: {
                            backgroundColor: "transparent",
                            color: "@middleLightGrey",
                        }
                    }
                },
                default: {
                    themeViewStates: {
                        active: {
                            backgroundColor: "@veryLightGrey",

                        },
                    },
                },
                defaultTransparent: {
                    themeViewStates: {
                        active: {
                            borderColor: "@darkGrey",
                            backgroundColor: "fade('@accentBlue', 8%)",
                            color: "color",
                            borderless: true,
                            bottomBorder: false,
                        },
                        inactive: {
                            borderColor: "@middleLightGrey",
                            backgroundColor: "lighten('backgroundColor', 100%)",
                            color: "color",
                            borderless: true,
                            bottomBorder: false,
                        },
                    },
                },
            }
        },
        mobileButtonBar: {
            fluid: false,
            //iconColor: "@accentGreen",
            backgroundColor: "@veryLightGrey",
            buttonKind: "tertiary"
        },

        dropZoneSvg: 'https://cdn.imaginarity.com/public/images/filedropzone2.svg',

        // TOOLTIP        
        tooltipBackground: "#FFF",
        tooltipColor: "#000",
        toolTipBoxShadow: "0px 0px 30px 1px rgba(0,0,0,0.3)",

        contentInteraction: {
            actionButtons: {
                kind: "secondary",
                size: "default",
                floated: "left",
            },
            topActionButtons: {
                kind: "primary",
                size: "default",
                floated: "left",
            },
            inSideNavigationButtons: {
                kind: "transparentButton",
                size: "default",
                floated: "right",
            },
            navigationButtons: {
                kind: "secondary",
                size: "default",
                floated: "right",
            }
        },
        pagination: {
            desktopRange: 7,
            mobileRange: 5
        },
        tabsBar: {
            tabsBarBackgroundColor: "fade('@accentBlack', 2%)",
            fontColor: "@lightGrey",
            fontColorActive: "@darkerGrey",
            fontColorHover: "@accentRed",
            tabBackground: "transparent",
            tabBackgroundActive: "@mainBackground",
            tabsBorderColor: "@middleLightGrey",
            underlineBarColor: "@accent",
            underlineBarAnimated: true
        },
        progressbarsSchemes: {
            minimal: {
                backgroundColor: "fade('color', 3%)",
                barColor: "fade('color',7%)",
                labelColor: "lighten('backgroundColor', 50%)",
                valueColor: "lighten('color', 80%)",
            },
        },

        scrollbarWidth: 7,
        scrollbarHeight: 9,
        scrollbarRadius: 0,
        scrollbarTrack: "fade('@accentBlack', 10%)",
        scrollbarThumb: "fade('@accentBlack', 20%)",

        dimmer: {
            backgroundColor: "rgba(0, 0, 0, 0.85)",
        },
    });
};


// const TestIcon = (p: IconBaseProps) => {
//     const { color, ...rest } = p;
//     return (
//         <IconContainer {...rest}>
//             <IconSVG
//                 color={color}
//                 inheritColor={p.inheritColor}
//                 fill="none"
//                 x="0px"
//                 y="0px"
//                 viewBox="0 0 30 30"
//                 stroke={color}
//                 strokeLinejoin="round"
//                 strokeLinecap="round"
//             >
//                 {/* <path style={{stroke: color}} d="M15,5.3h7.6c1.2,0,2.2,1,2.2,2.2v15.2c0,1.2-1,2.2-2.2,2.2H15 M15,5.3H7.4 c-1.2,0-2.2,1-2.2,2.2v15.2c0,1.2,1,2.2,2.2,2.2H15"/> */}
//                 <g transform="translate(-2.167 -2.167)">
//                     <rect x="5.3" y="5.3" width="23.8" height="23.8" />
//                 </g>
//             </IconSVG>
//         </IconContainer>
//     );
// }

export const defineIcons = () => {
    //imgIconDefs["add"] = TestIcon;
}
