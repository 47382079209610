import { GroupDownloadDto, PlatformFeaturesV2DownloadDto, PostDownloadDto, UserDownloadDto } from "collaboration-service";
import { ButtonProps, LottieButtonProps } from "imaginarity-react-ui";
import { AnyAction } from "redux";
import { MasterLayoutModule } from "./MasterLayout";
import { AppEntry, AppView } from "./MasterLayoutRouter";


export interface PluginState {
    buttons?: Array<ButtonProps | LottieButtonProps>,
    route: string;
    currentAppEntry?: AppEntry;
    currentAppView?: AppView;
    // set by app, may be extended by modules/plugins
    appEntries: AppEntry[];
    // set by app, may be extended by modules/plugins
    modules: MasterLayoutModule[];
    search?: string;
    // implicit set by reducer after setting capabilities
    activeModules: MasterLayoutModule[];

    user?: UserDownloadDto;
    userGroups?: GroupDownloadDto[];
    adminGroups?: GroupDownloadDto[];
    posts?: PostDownloadDto[];
    features?: PlatformFeaturesV2DownloadDto;
}

export type PluginAction =
    | { type: "BATCH_ACTIONS", value: AnyAction[] }
    | { type: "SET_POSTS", value?: PostDownloadDto[] }
    | { type: "ADD_APPENTRIES", value: AppEntry[] | AppEntry }
    | { type: "ADD_MODULES", value: MasterLayoutModule[] | MasterLayoutModule }
    | { type: "SET_MODULE_BADGE", value: { module: MasterLayoutModule, badge: number, add?: boolean } }
    | { type: "OPEN_SINGLE_MODULE", value: { moduleToOpen: MasterLayoutModule | undefined, modulesToClose: MasterLayoutModule[] } }
    | { type: "SET_MODULE_OPEN", value: { module: MasterLayoutModule, open: boolean } }
    | { type: "SET_USER_GROUPS", value?: GroupDownloadDto[] }
    | { type: "SET_ADMIN_GROUPS", value?: GroupDownloadDto[] }
    | { type: "SET_CURRENT_APP_ENTRY_AND_VIEW", value: { appEntry?: AppEntry, appView?: AppView } }
    | { type: "SET_PLATFORM_FEATURES", value?: PlatformFeaturesV2DownloadDto }


export const PluginActions = {
    batchActions(actions: AnyAction[]): PluginAction {
        return { type: "BATCH_ACTIONS", value: actions };
    },
    setPosts(posts?: PostDownloadDto[]): PluginAction {
        return { type: "SET_POSTS", value: posts };
    },
    addAppEntries(appEntries: AppEntry[] | AppEntry): PluginAction {
        return { type: "ADD_APPENTRIES", value: appEntries };
    },
    addModules(modules: MasterLayoutModule[] | MasterLayoutModule): PluginAction {
        return { type: "ADD_MODULES", value: modules };
    },
    setModuleBadge(module: MasterLayoutModule, badge: number, add?: boolean): PluginAction {
        return { type: "SET_MODULE_BADGE", value: { module, badge, add } };
    },
    openSingleModule(moduleToOpen: MasterLayoutModule | undefined, modulesToClose: MasterLayoutModule[]): PluginAction {
        return { type: "OPEN_SINGLE_MODULE", value: { moduleToOpen, modulesToClose } };
    },
    openModule(module: MasterLayoutModule, open: boolean): PluginAction {
        return { type: "SET_MODULE_OPEN", value: { module, open } };
    },
    setUserGroups(groups?: GroupDownloadDto[]): PluginAction {
        return { type: "SET_USER_GROUPS", value: groups };
    },
    setAdminGroups(groups?: GroupDownloadDto[]): PluginAction {
        return { type: "SET_ADMIN_GROUPS", value: groups };
    },
    setCurrentAppEntry(appEntry?: AppEntry, appView?: AppView): PluginAction {
        return { type: "SET_CURRENT_APP_ENTRY_AND_VIEW", value: { appEntry, appView } };
    },
    setPlatformFeatures(features?: PlatformFeaturesV2DownloadDto): PluginAction {
        return { type: "SET_PLATFORM_FEATURES", value: features };
    }
}


/*

export const returnNewState = <T extends PluginState>(state: T) => {
    return {
        ...state,
        activeModules: _.filter(state.modules, m => !m.available || m.available(state)),
    } as T;
}

export const pluginReducer = <T extends PluginState>(state: PluginState, action: PluginAction): T => {
    switch(action.type){
        case "BATCH_ACTIONS":
    }
    if (action.type > PluginActionTypes.PLUGIN_ACTIONS_END)
        return state as T;
    switch (action.type) {
        case PluginActionTypes.BATCH_ACTIONS: {
            let s = state;
            _.forEach(action.actions, a => {
                s = mainReducer(s as T, a);
            });
            return s as T;
        }
        case PluginActionTypes.SET_POSTS:
            return {
                ...state,
                posts: action.posts
            } as T;
        case PluginActionTypes.ADD_MODULES:
            {
                const mods = _.clone(state.modules);
                _.forEach(action.modules, m => {
                    const idx = _.findIndex(mods, mm => mm.name === m.name);
                    if (idx < 0)
                        mods.push(m);
                })
                return returnNewState({
                    ...state,
                    modules: mods,
                }) as T;
            }
        case PluginActionTypes.ADD_APPENTRIES:
            {

                const appEntries: AppEntry[] = [...state.appEntries, ...action.appEntries];
                const rNames: string[] = [];
                _.forEach(appEntries, a => {
                    if (a.routeName)
                        rNames.push(a.routeName);
                    _.forEach(a.views, v => {
                        if (v.routeName)
                            rNames.push(v.routeName);
                    })
                });
                const ordered = _.orderBy(rNames, r => r);
                const double: string[] = [];
                for (let i = 1; i < ordered.length; i++) {
                    if (ordered[i - 1] === ordered[i])
                        double.push(ordered[i]);
                }
                if (double.length === 0)
                    return returnNewState({
                        ...state,
                        appEntries: [...state.appEntries, ...action.appEntries]
                    }) as T;
                else {
                    console.log("can not add app entries! the following route names are not unique : ", double);
                }
                break;
            }
        case PluginActionTypes.SET_MODULE_BADGE:
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.module)
                        m.badge = (action.add ? (m.badge ?? 0) : 0) + action.badge;
                    return m;
                })
            }) as T;
        case PluginActionTypes.OPEN_MODULE:
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.moduleToOpen)
                        m.open = action.open;
                    return m;
                })
            }) as T;
        case PluginActionTypes.OPEN_SINGLE_MODULE:
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.moduleToOpen)
                        m.open = true;
                    else if (m.open && _.findIndex(action.modulesToClose, mtc => mtc === m) >= 0)
                        m.open = false;
                    return m;
                })
            }) as T;
        case PluginActionTypes.SET_ROUTE:
            return returnNewState({
                ...state,
                route: action.route,
            }) as T;
        case PluginActionTypes.SET_BUTTONS:
            return returnNewState({
                ...state,
                buttons: action.buttons,
            }) as T;
        case PluginActionTypes.SET_USER_GROUPS:
            if (!_.isEqual(state.userGroups, action.groups))
                return returnNewState({
                    ...state,
                    userGroups: action.groups,
                }) as T;
            break;
        case PluginActionTypes.SET_ADMIN_GROUPS:
            if (!_.isEqual(state.adminGroups, action.groups))
                return returnNewState({
                    ...state,
                    adminGroups: action.groups,
                }) as T;
            break;
        case PluginActionTypes.SET_USER:
            if (!_.isEqual(state.user, action.user))
                return returnNewState({
                    ...state,
                    user: action.user,
                }) as T;
            break;
        case PluginActionTypes.SET_CURRENT_APP_ENTRY_AND_VIEW:
            if (!_.isEqual(state.currentAppEntry, action.appEntry) || !_.isEqual(state.currentAppView, action.appView))
                return returnNewState({
                    ...state,
                    currentAppEntry: action.appEntry,
                    currentAppView: action.appView,
                }) as T;
            break;
        case PluginActionTypes.SET_PLATFORM_FEATURES:
            if (!_.isEqual(state.features, action.features))
                return returnNewState({
                    ...state,
                    features: action.features
                }) as T;
            break;
    }
    return state as T;
}


*/