import { GroupDownloadDto, UserShortInfoDownloadDto } from 'collaboration-service';
import FacetSelection from 'components/FacetSelection/FacetSelection';
import SidebarButton from 'components/Sidebars/SidebarButton';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import { Checkbox, Flag, fadeColor, getColor, lightenColor, saturationColor, styled } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { CurrentSettings } from 'services/Config';
import { getFrontEndSettingFromState, setFrontEndSetting, shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { isInGroup } from 'services/StoreDependantHelpers';
import { FeedSearchProps, createFetchPostFilter } from './FeedFilter';
import { feedTheme } from './FeedMain';
import FeedSearchByKeywords from './FeedSearchByKeywords';
import FeedSearchByUser from './FeedSearchByUser';

export const Container = styled.div`
    display: block;
    padding-left: 0px;
    padding-bottom:10px;
    line-height:30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const RadioButtonDescription = styled.div<{ selected: boolean }>`
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-out;
    border-right: 2px solid transparent;
    color:${p => p.selected ? p.theme.colors.mainBackground : p.theme.colors.middleLightGrey};
    padding-right: 5px;
    cursor: pointer;
    width: 100%;
    &:hover{
        border-right: 2px solid ${p => p.theme.colors.darkerGrey};
        color:${p => getColor(p, "color")};
        background: ${p => fadeColor(p, "color", 5)};
    }
`;
export const RadioButtonsContainer = styled.div`
    padding-left: 5px;
    svg{
        margin-top: 0px;
        float: left;
        }
    *{
        stroke: ${p => p.theme.backgroundColor};
    }
`;
export const RadioButtonsContainerElement = styled.div`
    //display: flex;
    //flex-direction: row ;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
`;
export const ContainerElementFigures = styled.div`
    color: ${p => getColor(p, p.theme.color)};
    width: 40px;
    height: 25px;
    text-align: center;
    //background: rgba(0,0,0,0.05);
    line-height: 25px;
    font-size: 0.9em;
    display: grid;
    grid-template-columns: max-content max-content max-content;
`;
export const TypeContainer = styled.div<{ iconColor?: string, hoverable?: boolean }>`
    display: grid;
    //grid-template-columns: 1fr 40px 70px;  
    grid-template-columns: 1fr 40px 40px;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: ${p => p.hoverable ? 0 : "5px 0"};
    transition: all 0.2s ease-out;
    border-left: ${p => p.hoverable ? "1px solid " + getColor(p, p.theme.backgroundColor) : "none"}; 
    cursor: ${p => p.hoverable ? "pointer" : "default"};
    svg{
        stroke: ${p => p.iconColor === "no" ? fadeColor(p, getColor(p, p.theme.color), 30) : "inherit"};
    }
    &:hover{
        background: ${p => p.hoverable ? getColor(p, "lighten('backgroundColor', 90%)") : "transparent"};
        border-left: ${p => p.hoverable ? "1px solid " + getColor(p, p.theme.colors.accent) : "none"}; 
        padding-left: ${p => p.hoverable ? "10px" : "0px"};
    }
`;
export const TypeContainerView = styled.div<{ iconColor?: string }>`
    display: grid;
    //grid-template-columns: 1fr 40px 70px;  
    grid-template-columns: 1fr max-content;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: 5px 0;
    transition: all 0.2s ease-out;
    *{
        color: ${p => getColor(p, p.theme.leftArea.color)};
        stroke: ${p => getColor(p, p.theme.leftArea.color)};
        svg{
            margin-top: 5px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    svg{
        stroke: ${p => p.iconColor === "no" ? fadeColor(p, getColor(p, p.theme.colors.mainBackground), 30) : "inherit"};
    }
`;
export const TypeContainerViewAlternative = styled.div<{ iconColor?: string }>`
    display: grid;
    grid-template-columns: 1fr max-content;  
    grid-template-rows: 40px ;
    width: 100%;
    margin: 5px 0;
    transition: all 0.2s ease-out;
`;
export const TCtitle = styled.div<{ selected?: boolean }>`
    line-height: 40px;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;  
    //letter-spacing: 0.5pt;
    font-weight: 400;
    border-left: 1px solid ${p => p.selected ? getColor(p, p.theme.colors.accent) : "transparent"};
    color: ${p => p.selected ? getColor(p, p.theme.color) : getColor(p, p.theme.color)};
    transition: all 0.2s ease-out;
    padding-left: ${p => p.selected ? 10 : 0}px;
    &:hover{
        //color: ${p => lightenColor(p, p.theme.color, 20)};
        //color: ${p => saturationColor(p, p.theme.color, 0.1)};
    } 
`;
export const FilterContainer = styled.div`
    width: 100%;
    grid-column: 1 / span 2;
`;

export const FilterContainerHeader = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
    font-weight: 500;
    color: ${p => p.theme.colors.veryLightGrey};
    background: rgba(0,0,0,0.05);
    border-left: 1px solid ${p => p.theme.colors.accent};
    line-height: 40px;
    padding-left: 10px;
    font-size: 0.8rem;
    margin: 0 0 5px 0;
    //text-transform: uppercase;
`;
export const FilterUserContainerHeader = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
    font-weight: 500;
    font-size: 0.85rem;
    color: ${p => p.theme.color};
    line-height: 24px;
    padding: 0px 10px 0 0;
    margin: 10px 0 5px 0;
    text-transform: uppercase;
`;


const mapper = (state: ApplicationState) => ({
    user: state.user,
    rerender: state.feedState.rerender,
    userGroups: state.userGroups,
    trending: state.feedState.trending,
    facets: state.feedState.facets,
});

export interface FeedFilterAllProps {
}

const FeedFilterAll = (p: FeedFilterAllProps) => {
    //const { } = p;
    const { user, trending, facets, rerender, userGroups } = useAppSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [showPMfilter, setShowPMfilter] = React.useState<boolean>(false);
    const [showComfilter, setShowComfilter] = React.useState<boolean>(false);
    const [excluded, setExcluded] = React.useState<string[]>([]);
    const [excludedGroups, setExcludedGroups] = React.useState<string[]>([]);
    const [exciteGroups, setExciteGroups] = React.useState<GroupDownloadDto[]>([]);
    const [comGroups, setComGroups] = React.useState<GroupDownloadDto[]>([]);
    const [toggleExcite, setToggleExcite] = React.useState<boolean>();
    const [filterExciteLuxury, setFilterExciteLuxury] = React.useState<boolean>(false);
    const [toggleCommunity, setToggleCommunity] = React.useState<boolean>();
    const [searchUser, setSearchUser] = React.useState<UserShortInfoDownloadDto>();
    const [org, setOrg] = React.useState<string | null>(null);
    const [org1, setOrg1] = React.useState<string | null>(null);
    const [org2, setOrg2] = React.useState<string | null>(null);
    const [userSelected, setUserSelected] = React.useState<UserShortInfoDownloadDto>();
    const [search, setSearch] = React.useState<string>();

    const exciteHasExcludedGroups = exciteGroups.some(group => excludedGroups.includes(group.id));
    const communityHasExcludedGroups = comGroups.some(group => excludedGroups.includes(group.id));

    const exciteExcludedGroupsCount = exciteGroups.filter(group => excludedGroups.includes(group.id)).length;
    const communityExcludedGroupsCount = comGroups.filter(group => excludedGroups.includes(group.id)).length;

    React.useEffect(() => {
        setExcluded(getFrontEndSettingFromState<string[]>(user, "searchStickyFilter") ?? []);
        setExcludedGroups(getFrontEndSettingFromState<string[]>(user, "searchStickyGroupFilter") ?? []);
    }, [user]);

    React.useEffect(() => {
        setExciteGroups(_.filter(userGroups, g => g.groupType === "EXCITE"));
        setComGroups(_.filter(userGroups, g => g.groupType === "COMMUNITY"));
    }, [userGroups]);

    const startSearch = React.useMemo(() => (feedSearchProps: FeedSearchProps) => {
        if (rerender)
            rerender(feedSearchProps);
        setSearchUser(cur => cur?.id !== feedSearchProps.user?.id ? feedSearchProps.user : cur);
    }, [rerender]);

    const turnOn = (postType: string) => () => {
        console.log('excluded 1 -> ', excluded);

        const newExcluded = _.clone(excluded);
        const idx = _.findIndex(newExcluded, f => f === postType);
        if (idx < 0)
            newExcluded.push(postType);
        else
            newExcluded.splice(idx, 1);

        setFrontEndSetting(user, "searchStickyFilter", changer => newExcluded);

        setExcluded(newExcluded);

        setShowPMfilter(postType === "PorscheMomentPost" && idx < 0 ? false : showPMfilter);
        setShowComfilter(postType === "CommunityFeedPost" && idx < 0 ? false : showComfilter);
    };

    const toggleShowPMfilter = () => {
        setShowPMfilter(v => !v);
    }
    const toggleShowComfilter = () => {
        setShowComfilter(v => !v);
    }

    // const toggleCommunityMthd = () => {
    //     let newExcludedGroups: string[] = _.clone(excludedGroups);
    //     const curToggleCommunity = toggleCommunity;

    //     //console.log("toggle community", toggleCommunityOn ? false : true);

    //     if (curToggleCommunity === undefined || !curToggleCommunity) {
    //         _.forEach(comGroups, g => {
    //             const idx = _.findIndex(newExcludedGroups, f => f === g.id);
    //             if (idx > -1)
    //                 newExcludedGroups.splice(idx, 1);
    //         });
    //     }
    //     else {
    //         _.forEach(comGroups, g => {
    //             newExcludedGroups.push(g.id);
    //         });
    //     }
    //     //excludedGroups = _.uniqBy(excludedGroups, g => g);

    //     setFrontEndSetting(user, "searchStickyGroupFilter", changer => newExcludedGroups);
    //     setExcludedGroups(newExcludedGroups);
    //     setToggleCommunity(curToggleCommunity ? false : true);
    // };

    // const toggleExciteMthd = () => {
    //     let newExcludedGroups: string[] = _.clone(excludedGroups);
    //     const curToggleExcite = toggleExcite;

    //     //console.log("toggle excite", toggleExciteOn ? false : true);
    //     if (curToggleExcite === undefined || !curToggleExcite) {

    //         _.forEach(exciteGroups, g => {
    //             const idx = _.findIndex(newExcludedGroups, f => f === g.id);
    //             if (idx > -1)
    //                 newExcludedGroups.splice(idx, 1);
    //         });
    //     }
    //     else {
    //         _.forEach(exciteGroups, g => {
    //             newExcludedGroups.push(g.id);
    //         });
    //     }
    //     //excludedGroups = _.uniqBy(excludedGroups, g => g);
    //     setFrontEndSetting(user, "searchStickyGroupFilter", changer => newExcludedGroups);
    //     setExcludedGroups(newExcludedGroups);
    //     setToggleExcite(curToggleExcite ? false : true);
    // };


    const selectAllCommunityMthd = () => {
        const newExcludedGroups = _.difference(excludedGroups, comGroups.map(g => g.id));
        setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
        setExcludedGroups(newExcludedGroups);
        setToggleCommunity(!toggleCommunity);
    };

    const deselectAllCommunityMthd = () => {
        const newExcludedGroups = _.union(excludedGroups, comGroups.map(g => g.id));
        setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
        setExcludedGroups(newExcludedGroups);
        setToggleCommunity(!toggleCommunity);
    };

    const selectAllExciteMthd = () => {
        const newExcludedGroups = _.difference(excludedGroups, exciteGroups.map(g => g.id));
        setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
        setExcludedGroups(newExcludedGroups);
        setToggleExcite(!toggleExcite);
    };

    const deselectAllExciteMthd = () => {
        const newExcludedGroups = _.union(excludedGroups, exciteGroups.map(g => g.id));
        setFrontEndSetting(user, "searchStickyGroupFilter", () => newExcludedGroups);
        setExcludedGroups(newExcludedGroups);
        setToggleExcite(!toggleExcite);
    };

    const filterByGroup = (groupId: string) => () => {
        let newExcludedGroups = _.clone(excludedGroups);
        const idx = _.findIndex(newExcludedGroups, f => f === groupId);
        if (idx < 0)
            newExcludedGroups.push(groupId);
        else
            newExcludedGroups.splice(idx, 1);

        setFrontEndSetting(user, "searchStickyGroupFilter", changer => newExcludedGroups);
        setExcludedGroups(newExcludedGroups);
    };


    const onClickTrending = () => {
        dispatch(Actions.setFeedTrending(!trending));
    };

    React.useEffect(() => {
        const filter = createFetchPostFilter(undefined, excluded, excludedGroups, undefined, undefined, searchUser, undefined, org, org1, org2, filterExciteLuxury);
        startSearch({ filter, user: searchUser });

    }, [org, org1, org2, searchUser, excluded, excludedGroups, filterExciteLuxury, startSearch])

    const momentsName = t(feedTheme.momentsName);

    const resetAll = () => {
        dispatch(Actions.setFeedTrending(undefined));
        setOrg(null);
        setOrg1(null);
        setOrg2(null);
        setShowPMfilter(false);
        setShowComfilter(false);
        turnOn("CommunityFeedPost");
        turnOn("PorscheMomentPost");
        setExcluded([]);
        setExcludedGroups([]);
        startSearch({});
        setFilterExciteLuxury(false);
        //setSearch(undefined);
        setUserSelected(undefined);
        setFrontEndSetting(user, "searchStickyFilter", changer => undefined);

        setFrontEndSetting(user, "searchStickyGroupFilter", () => undefined);



    };
    const resetSearch = () => {
        setSearch(undefined);
    };


    const resetButtonIsActive =
        trending === true ||
        org !== null ||
        org1 !== null ||
        org2 !== null ||
        excluded.includes("PorscheMomentPost") ||
        excluded.includes("CommunityFeedPost") ||
        excludedGroups.length > 0 ||
        filterExciteLuxury === true ||
        //search !== undefined ||
        userSelected !== undefined
        ;

    return (
        <Container>
            {search !== undefined && (
                <>
                    <SidebarButton
                        title={t("reset search")}
                        button={[
                            {
                                icon: "search close",
                                onClick: resetSearch,
                                tooltipText: t("close search"),
                                noArrow: false,
                            },
                        ]}
                    />
                </>
            )}
            {resetButtonIsActive &&
                <>
                    <SidebarButton
                        title={t("reset filters")}
                        button={[{
                            icon: "filter times",
                            onClick: resetAll,
                            kind: "primary",
                            tooltipText: t("reset all filters"),
                            noArrow: false,
                        }]}
                    />
                    <div style={{ height: 10 }} />
                </>
            }
            <SidebarTitleContainer icon="chevron up down" title={t("ordering")} />
            <SidebarButton
                title={trending ? t("trending order") : t("chronological order")}
                noContainerClick
                button={[
                    {
                        icon: "trending",
                        onClick: onClickTrending,
                        selected: trending,
                        // tooltipText: this.props.feedState.trending ? undefined : t("sort by trend"),
                        disabled: trending ? true : false
                    },
                    {
                        icon: "clock",
                        onClick: onClickTrending,
                        selected: !trending,
                        // tooltipText: !this.props.feedState.trending ? undefined : t("sort chronological"),
                        disabled: trending ? false : true
                    }
                ]}
            />
            <FeedSearchByKeywords user={user} search={search} setSearch={(s) => setSearch(s)} />
            {/* <FilterUserContainerHeader>{t("filter by user")}</FilterUserContainerHeader> */}


            <FacetSelection facetName='organization' facets={facets}
                //noCount 
                value={org} onChange={setOrg} header={<SidebarTitleContainer icon="filter" title={t("filter by organization")} />} />
            <FacetSelection
                facetName='organization1'
                facets={facets}
                //noCount
                value={org1}
                onChange={setOrg1}
                header={<SidebarTitleContainer icon="filter" title={t("filter by organization1")} />}
                iconRendererForLabels={(ctry) => <Flag name={ctry as any} size={"18px"} />}
                translateLabels={t} />
            <FacetSelection facetName='organization2' facets={facets}
                //noCount 
                value={org2} onChange={setOrg2} header={<SidebarTitleContainer icon="filter" title={t("filter by organization2")} />} />

            <SidebarTitleContainer icon="filter" title={t("filter by user")} />
            <FeedSearchByUser startSearch={startSearch} user={userSelected} getUserSelected={(user) => setUserSelected(user)} />

            <SidebarTitleContainer icon="filter" title={t("filter by type")} />

            {isInGroup("EXCITE") &&
                <SidebarButton
                    title={t(momentsName)}
                    noContainerClick
                    button={[
                        {
                            icon: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter ? 'chevron up' : 'chevron down' : "chevron down",
                            onClick: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? toggleShowPMfilter : undefined,
                            selected: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter : undefined,
                            tooltipText: exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? showPMfilter ? t("hide {{post}} filter", { post: momentsName }) : t("show {{post}} filter", { post: momentsName }) : undefined,
                            noArrow: false,
                            hidden: !(exciteGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0)
                        },
                        {
                            icon: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? "eye open" : "eye close",
                            onClick: turnOn("PorscheMomentPost"),
                            selected: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0,
                            tooltipText: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? t("hide {{post}}", { post: momentsName }) : t("show {{post}}", { post: momentsName }),
                            noArrow: false,
                        },
                        {
                            icon: (exciteHasExcludedGroups && !showPMfilter) ? "exclamation" : exciteHasExcludedGroups ? "checkbox unchecked" : "checkbox checked",
                            onClick: (exciteHasExcludedGroups && !showPMfilter) ? toggleShowPMfilter : exciteHasExcludedGroups ? selectAllExciteMthd : deselectAllExciteMthd,
                            hidden: exciteGroups.length < 2 || (!showPMfilter && !exciteHasExcludedGroups) || !(_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0),
                            tooltipText: (exciteHasExcludedGroups && !showPMfilter) ? t("{{type}} has {{count}} deselected groups", { type: momentsName, count: exciteExcludedGroupsCount }) : exciteHasExcludedGroups ? t("select all") : t("deselect all"),
                            tooltipPosition: "bottom",
                            noArrow: false,
                            iconColor: (exciteHasExcludedGroups && !showPMfilter) ? "@accentYellow" : undefined
                        },
                        {
                            icon: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? "crown" : undefined,
                            onClick: _.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0 ? () => setFilterExciteLuxury(!filterExciteLuxury) : undefined,
                            tooltipText: filterExciteLuxury ? t("show all moments") : t("show only luxury moments"),
                            noArrow: false,
                            active: filterExciteLuxury,
                            iconColor: filterExciteLuxury ? "@accentYellow" : undefined,
                            kind: filterExciteLuxury ? "secondary" : undefined,
                            hidden: !CurrentSettings.showLuxuryFilterInSidebar ? true : !(_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0)
                        },


                    ]}
                />
            }
            {showPMfilter &&
                <FilterContainer>
                    {/* {exciteGroups.length > 1 &&
                        <FilterContainerHeader style={{ paddingRight: 2 }}>
                            {t("select groups to show")}
                            <Button
                                kind="transparentButtonDark"
                                // icon={toggleExcite === undefined || toggleExcite === false ? "check circle" : "circle"}
                                // onClick={toggleExciteMthd}
                                // tooltip={{ tooltipText: toggleExcite === undefined || toggleExcite === false ? t("select all") : t("deselect all") }}
                                // icon={exciteHasExcludedGroups ? "check circle" : "circle"}
                                icon={exciteHasExcludedGroups ? "checkbox unchecked" : "checkbox checked"}
                                onClick={exciteHasExcludedGroups ? selectAllExciteMthd : deselectAllExciteMthd}
                                tooltip={{ tooltipText: exciteHasExcludedGroups ? t("select all") : t("deselect all") }}
                                floated="right"
                            />
                        </FilterContainerHeader>
                    } */}
                    {exciteGroups.length > 1 &&
                        <RadioButtonsContainer>
                            {_.map(exciteGroups, e => {
                                const selected = _.findIndex(excludedGroups, g => _.isEqual(g, e.id)) < 0;
                                return (
                                    <RadioButtonsContainerElement key={e.id}>
                                        <Checkbox
                                            onClick={filterByGroup(e.id)}
                                            selected={selected}
                                            label={e.name}
                                        />
                                    </RadioButtonsContainerElement>
                                );
                            })}
                        </RadioButtonsContainer>
                    }


                </FilterContainer>
            }
            {isInGroup("COMMUNITY") &&
                <SidebarButton
                    title={t("community")}
                    noContainerClick
                    button={[
                        {
                            icon: comGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? showComfilter ? 'chevron up' : 'chevron down' : undefined,
                            onClick: comGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? toggleShowComfilter : undefined,
                            selected: comGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? showComfilter : undefined,
                            tooltipText: _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? showComfilter ? t("hide community filter") : t("show community filter") : undefined,
                            noArrow: false,
                            hidden: !(comGroups.length > 1 && _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0)
                        },
                        {
                            icon: _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? "eye open" : "eye close",
                            onClick: turnOn("CommunityFeedPost"),
                            selected: _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0,
                            tooltipText: _.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0 ? t("hide community posts") : t("show community posts"),
                            noArrow: false
                        },
                        {
                            icon: (communityHasExcludedGroups && !showComfilter) ? "exclamation" : communityHasExcludedGroups ? "checkbox unchecked" : "checkbox checked",
                            onClick: (communityHasExcludedGroups && !showComfilter) ? toggleShowComfilter : communityHasExcludedGroups ? selectAllCommunityMthd : deselectAllCommunityMthd,
                            hidden: comGroups.length < 2 || (!showComfilter && !communityHasExcludedGroups) || !(_.findIndex(excluded, f => _.isEqual(f, "CommunityFeedPost")) < 0),
                            tooltipText: (communityHasExcludedGroups && !showComfilter) ? t("{{type}} has {{count}} deselected groups", { type: t("community"), count: communityExcludedGroupsCount }) : communityHasExcludedGroups ? t("select all") : t("deselect all"),
                            tooltipPosition: "bottom",
                            noArrow: false,
                            iconColor: (communityHasExcludedGroups && !showComfilter) ? "@accentYellow" : undefined
                        },
                    ]}
                />
            }
            {showComfilter &&
                <FilterContainer>
                    {/* <FilterContainerHeader style={{ paddingRight: 2 }}>
                        {t("select groups to show")}
                        <Button
                            kind="transparentButtonDark"
                            // icon={toggleCommunity === undefined || toggleCommunity === false ? "check circle" : "circle"}
                            // onClick={toggleCommunityMthd}
                            // tooltip={{ tooltipText: toggleCommunity === undefined || toggleCommunity === false ? t("select all") : t("deselect all") }}
                            // icon={communityHasExcludedGroups ? "check circle" : "circle"}
                            icon={communityHasExcludedGroups ? "checkbox unchecked" : "checkbox checked"}
                            onClick={communityHasExcludedGroups ? selectAllCommunityMthd : deselectAllCommunityMthd}
                            tooltip={{ tooltipText: communityHasExcludedGroups ? t("select all") : t("deselect all") }}
                            floated="right"
                        />
                    </FilterContainerHeader> */}
                    <RadioButtonsContainer>
                        {_.map(comGroups, e => {
                            const selected = _.findIndex(excludedGroups, g => _.isEqual(g, e.id)) < 0;
                            return (
                                <RadioButtonsContainerElement key={e.id}>
                                    <Checkbox
                                        onClick={filterByGroup(e.id)}
                                        selected={selected}
                                        label={e.name}
                                    />
                                </RadioButtonsContainerElement>
                            );
                        })}
                    </RadioButtonsContainer>
                </FilterContainer>
            }

            {resetButtonIsActive &&
                <>
                    <div style={{ height: 10 }} />
                    <SidebarButton
                        title={t("reset all filters")}
                        selected
                        button={[
                            {
                                icon: "filter times",
                                onClick: resetAll,
                                kind: "fullTransparentButton",
                            }
                        ]}
                    />
                </>
            }

            {/* <SidebarButton
                    title={t("your bookmarks")}
                    button={[
                        {
                            icon: "bookmark outline",
                            onClick: this.gotoBookmarks
                        }
                    ]}
                /> */}
        </Container>
    )
}

export default FeedFilterAll;
