import { ControllerHelper, GroupDownloadDto, PostController, WikiArticleDownloadDto, WikiCategoryDownloadDto, WikiColumnSearchResultDto, WikiController, WikiSearchResultDto, WikiSectionSearchResultDto } from 'collaboration-service';
import GroupSelection from 'components/General/GroupSelection';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { ExtendedDataCollection } from 'imaginarity-azure';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { Button, Icon, ImgThemeConsumer, Input, LoaderInline } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { visitWikiCats } from 'services/Helpers';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { WikiSC as T } from './WikiSC';
import WikiSearch from './WikiSearch';
interface ValueProps {
    articleId: string;
    sectionId?: string;
    columnId?: string;
}

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage, categories: state.wikiState.categories, userGroups: state.userGroups });

export interface WikiArticleSelectionProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
    value?: ValueProps | string;
    groupid: string;
    onFoundArticles?: (results?: WikiSearchResultDto[]) => void; // once supplied will block ToC
    noArticleView?: boolean;
    noTocView?: boolean;
    sectionColumnSelected?: (articleId: string, sectionId?: string, columnId?: string) => void;
    noMinHeight?: boolean;
    noMarginTop?: boolean;
    showCatLoader?: boolean;
}
export interface WikiArticleSelectionState {
    articles: { [catId: string]: { [subCatId: string]: WikiArticleDownloadDto[] } };
    currentArticle?: WikiSearchResultDto;
    currentCategory?: WikiCategoryDownloadDto;
    currentSubCategory?: WikiCategoryDownloadDto;
    show?: string;
    filter?: string;
    searchResults?: WikiSearchResultDto[];
    currentSectionId?: string;
    currentColumnId?: string;
    loaderActive: boolean;
    searchText?: string;
    article?: WikiArticleDownloadDto;
    selectedGroupid?: string;
}
class WikiArticleSelection extends React.Component<WikiArticleSelectionProps, WikiArticleSelectionState> {
    constructor(props: WikiArticleSelectionProps) {
        super(props);

        this.state = {
            articles: {},
            loaderActive: false
        }
    }

    async componentDidMount() {
        const groupid = this.props.groupid;
        const userGroups = this.props.userGroups;

        const group = _.find(userGroups, g => g.id === groupid);
        if (group && group.groupType === 'WIKI') {
            if (!this.props.categories) {
                const categories = await ControllerHelper.singleCall({ groupid }, WikiController.GetWikiCategories);
                this.props.dispatch(Actions.setWikiCategories(categories));
            }
            await this.selectValue();
        }
        else {
            this.setState({ selectedGroupid: "" });
        }
    }

    async componentDidUpdate(prevProps: WikiArticleSelectionProps, prevState: WikiArticleSelectionState) {
        if (!_.isEqual(prevProps.value, this.props.value))
            await this.selectValue();
    }

    public render() {
        const { articles, currentArticle, currentCategory, currentSubCategory, filter, show, searchText, selectedGroupid } = this.state;
        const { categories, contentLanguage, t } = this.props;

        if (selectedGroupid?.length === 0)
            return (
                <GroupSelection
                    groupTypes={["WIKI"]}
                    title={this.props.t("group selection")}
                    onChange={this.onChangeGroup}
                />
            );
        if (!categories)
            return (
                this.props.showCatLoader === true ?
                    <LoaderInline active infoText={t("loading categories")} />
                    : <div />
            );

        return (
            <ImgThemeConsumer>
                {(theme) => {
                    const selectedIsArticle = this.state.currentArticle?.id !== undefined && this.state.currentSectionId === undefined && this.state.currentColumnId === undefined;
                    const selectedIsSection = this.state.currentArticle?.id !== undefined && this.state.currentSectionId !== undefined && this.state.currentColumnId === undefined;
                    const selectedIsColumn = this.state.currentArticle?.id !== undefined && this.state.currentSectionId !== undefined && this.state.currentColumnId !== undefined;
                    return (
                        <T.PaneContentContainer noMinHeight={this.props.noMinHeight ?? false} noMarginTop={this.props.noMarginTop ?? false}>
                            {this.props.onFoundArticles &&
                                <T.PaneContainer>
                                    <Button
                                        icon="filter"
                                        content={t("filter")}
                                        kind={(show === undefined || this.state.show === "filter") ? "secondary" : "tertiary"}
                                        onClick={this.onShow("filter")}
                                        floated="left"
                                    />
                                    <Button
                                        icon="search"
                                        content={t("search")}
                                        kind={(this.state.show === "search") ? "secondary" : "tertiary"}
                                        onClick={this.onShow("search")}
                                        floated="left"
                                    />
                                    {show === "search" &&
                                        <div style={{ position: "relative", float: "left", width: "100%", marginRight: -9, marginLeft: -9, marginTop: 10 }}>
                                            <WikiSearch
                                                onSearched={this.onSearched}
                                                groupId={selectedGroupid ?? this.props.groupid}
                                                searchText={searchText ?? this.props.t("search article")}
                                                postType="WikiArticle"
                                                onSearchStarted={this.showLoader}
                                                lng={this.props.contentLanguage}
                                                dark
                                            />
                                        </div>
                                    }
                                </T.PaneContainer>
                            }
                            <T.ContentContainer visible={show === "filter" || show === undefined}>
                                {(show === undefined || show === "filter") &&
                                    <>
                                        <Input icon="filter" value={filter ?? ""} placeHolder={("filter categories")} change={this.filter} width="100%" />
                                        <T.WASTitleCats>
                                            {this.props.t("categories")}
                                        </T.WASTitleCats>
                                        <T.CategoriesContainer minimize={currentSubCategory !== undefined}>
                                            {(filter === undefined || filter === "") &&
                                                _.map(categories, cat => {
                                                    return (
                                                        <T.SubContainer marginLeft={0} key={cat.id} >
                                                            <T.CategoryTop
                                                                onClick={this.showSubcats(cat.id)}
                                                                selected={cat.id === this.state.currentCategory?.id}
                                                            >
                                                                <Icon name="box" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                {getTranslated(cat.names, contentLanguage).text}
                                                            </T.CategoryTop>
                                                            {currentCategory && currentCategory.id === cat.id &&
                                                                _.map(currentCategory.children, c => {
                                                                    return (
                                                                        <T.SubCategoryTop
                                                                            key={c.id}
                                                                            onClick={this.getArticlesInCategory(c)}
                                                                            selected={c.id === this.state.currentSubCategory?.id}
                                                                        >
                                                                            <Icon name="folder" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                            {getTranslated(c.names, contentLanguage).text}
                                                                        </T.SubCategoryTop>
                                                                    );
                                                                })
                                                            }
                                                        </T.SubContainer>
                                                    );
                                                })}
                                            {filter &&
                                                _.map(categories, cat => {
                                                    const filtered: WikiCategoryDownloadDto[] = _.filter(cat.children, c => getTranslated(c.names, contentLanguage).text.toLowerCase().includes(filter.toLowerCase()));
                                                    if (filtered.length === 0)
                                                        return <div />;
                                                    return (
                                                        <T.SubContainer marginLeft={0} key={cat.id} >
                                                            <T.CategoryTop selected={false} noHover>
                                                                <Icon name="box" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                {getTranslated(cat.names, contentLanguage).text}
                                                            </T.CategoryTop>
                                                            {_.map(filtered, c => {
                                                                return (
                                                                    <>
                                                                        <T.SubCategoryTop
                                                                            key={c.id}
                                                                            onClick={this.getArticlesInCategory(c)}
                                                                            selected={c.id === this.state.currentSubCategory?.id}
                                                                        >
                                                                            <Icon name="folder" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                            {getTranslated(c.names, contentLanguage).text}
                                                                        </T.SubCategoryTop>
                                                                    </>
                                                                );
                                                            })}
                                                        </T.SubContainer>
                                                    );
                                                })
                                            }
                                        </T.CategoriesContainer>
                                    </>
                                }

                                {currentSubCategory === undefined && currentCategory && !this.props.noArticleView &&
                                    articles &&
                                    articles[currentCategory.id] &&
                                    articles[currentCategory.id][currentCategory.id] &&
                                    <>
                                        <T.WASTitle>
                                            {this.state.currentCategory && this.props.t("articles in") + " "}
                                            {this.props.t("categories")}{getTranslated(this.state.currentCategory?.names, contentLanguage).text}
                                        </T.WASTitle>
                                        <T.ArticlesContainer style={{ marginTop: 10 }}>
                                            {_.map(articles[currentCategory.id][currentCategory.id], a => {
                                                return (
                                                    <T.SubCategoryTopSearch
                                                        style={{ paddingLeft: 30 }}
                                                        key={a.id}
                                                        onClick={this.showArticle(a)}
                                                        selected={a.id === this.state.currentArticle?.id}
                                                        selectedItem={selectedIsArticle && this.state.currentArticle?.id === a.id}
                                                    >
                                                        <Icon name="clipboard" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                        {getTranslated(a.headlines, contentLanguage).text}
                                                        {selectedIsArticle && this.state.currentArticle?.id === a.id &&
                                                            <Icon name="check" style={{ float: "right", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                        }
                                                    </T.SubCategoryTopSearch>
                                                );
                                            })}
                                        </T.ArticlesContainer>
                                    </>
                                }
                                {currentSubCategory && articles && articles[currentSubCategory.id] && !this.props.noArticleView &&
                                    <>
                                        <T.WASTitle>
                                            {this.state.currentCategory && this.state.currentSubCategory && this.props.t("articles in") + " "}
                                            {this.state.currentCategory && getTranslated(this.state.currentCategory.names, contentLanguage).text}
                                            {this.state.currentCategory && this.state.currentSubCategory && <span> / </span>}
                                            {this.state.currentSubCategory && getTranslated(this.state.currentSubCategory.names, contentLanguage).text}
                                        </T.WASTitle>
                                        <T.ArticlesContainer style={{ marginTop: 10 }}>
                                            {_.map(articles[currentSubCategory.id], articlesSubcat => {
                                                return (
                                                    _.map(articlesSubcat, a => {
                                                        return (
                                                            <T.SubCategoryTopSearch
                                                                style={{ paddingLeft: 30 }}
                                                                key={a.id}
                                                                onClick={this.showArticle(a)}
                                                                selected={a.id === this.state.currentArticle?.id}
                                                                selectedItem={selectedIsArticle && a.id === this.state.currentArticle?.id}
                                                            >
                                                                <Icon name="clipboard" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                {getTranslated(a.headlines, contentLanguage).text}
                                                                {selectedIsArticle && a.id === this.state.currentArticle?.id &&
                                                                    <Icon name="check" style={{ float: "right", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                }
                                                            </T.SubCategoryTopSearch>
                                                        );
                                                    })
                                                );
                                            })}
                                        </T.ArticlesContainer>
                                    </>
                                }
                                {currentArticle && this.props.onFoundArticles === undefined && !this.props.noArticleView && !this.props.noTocView &&
                                    <>
                                        <T.WASTitle>
                                            <SafeHTML
                                                allowedTags={["u"]}
                                                html={this.props.t("sections in {{article}}", { article: currentArticle?.headline ?? "" })}
                                            />
                                        </T.WASTitle>
                                        <T.ToC style={{ marginTop: 10 }}>
                                            {_.map(currentArticle?.sections, s => {
                                                let sectionTitle = this.getRidOfHtml(s.title);
                                                if (sectionTitle === "")
                                                    return false;
                                                return (
                                                    <div key={s.id}>
                                                        <T.CategoryTop
                                                            style={{ paddingLeft: 45 }}
                                                            key={s.id}
                                                            onClick={this.sectionColumnSelected(currentArticle.id ?? "", s.id)}
                                                            selected={this.state.currentSectionId === s.id}
                                                            selectedItem={selectedIsSection && this.state.currentSectionId === s.id}
                                                        >
                                                            <Icon name="chevron right" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                            {sectionTitle}
                                                            {selectedIsSection && this.state.currentSectionId === s.id &&
                                                                <Icon name="check" style={{ float: "right", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                            }

                                                        </T.CategoryTop>
                                                        {_.map(s.columns, c => {
                                                            const columnTitle = this.getRidOfHtml(c.title);
                                                            if (columnTitle === "")
                                                                return false;
                                                            return (
                                                                <T.CategoryTop
                                                                    style={{ width: "calc(100% + 45px)", marginLeft: -45, paddingLeft: 105 }}
                                                                    key={c.id}
                                                                    selected={this.state.currentColumnId === c.id}
                                                                    onClick={this.sectionColumnSelected(currentArticle.id ?? "", s.id, c.id)}
                                                                    selectedItem={selectedIsColumn && this.state.currentColumnId === c.id}
                                                                >
                                                                    <Icon name="branch top right" style={{ float: "left", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                    {columnTitle}
                                                                    {selectedIsColumn && this.state.currentColumnId === c.id &&
                                                                        <Icon name="check" style={{ float: "right", marginLeft: 5, marginRight: 5, marginTop: 4 }} marginTop={0} />
                                                                    }
                                                                </T.CategoryTop>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </T.ToC>
                                    </>
                                }
                            </T.ContentContainer>
                        </T.PaneContentContainer>
                    )
                }
                }
            </ImgThemeConsumer >
        );
    }
    private onChangeGroup = async (data?: GroupDownloadDto) => {
        const categories = await ControllerHelper.singleCall({ groupid: data!.id }, WikiController.GetWikiCategories);
        this.props.dispatch(Actions.setWikiCategories(categories));
        this.setState({ selectedGroupid: data!.id });
    };

    private selectValue = async () => {
        if (this.props.value) {
            let v: ValueProps = { articleId: "" }

            if (typeof this.props.value === "string") {
                const s = this.props.value.split("/");
                v.articleId = s[0];
                v.sectionId = s[1];
                v.columnId = s[2];
            }
            else
                v = this.props.value;

            const post = await ControllerHelper.singleCall({ id: v.articleId }, PostController.GetSinglePost);

            const article = post as WikiArticleDownloadDto;
            const findCat = (id: string): WikiCategoryDownloadDto | undefined => {
                let toRet: WikiCategoryDownloadDto | undefined = undefined;
                visitWikiCats(this.props.categories, c => {
                    if (c.id === id) {
                        toRet = c;
                        return false;
                    }
                    return true;
                });
                return toRet;
            }
            let subCategory: WikiCategoryDownloadDto | undefined = undefined;
            for (let i = 0; i < article.categoryIds.length; i++) {
                subCategory = findCat(article.categoryIds[i]);
            }
            if (subCategory) {
                let parentCategory: WikiCategoryDownloadDto | undefined = subCategory;
                while (parentCategory!.parentId) {
                    parentCategory = findCat(parentCategory!.parentId);
                }
                if (parentCategory)
                    await this.showSubcats(parentCategory.id)();
                if (subCategory)
                    await this.getArticlesInCategory(subCategory)();

                const currentArticle = this.toWikiSearchResult(article);
                this.setState({ currentArticle, currentSectionId: v.sectionId, currentColumnId: v.columnId });
            }
        }
    };
    private sectionColumnSelected = (articleId: string, sectionId?: string, columnId?: string) => () => {
        if (this.props.sectionColumnSelected)
            this.props.sectionColumnSelected(articleId, sectionId, columnId);
        this.setState({ currentSectionId: sectionId, currentColumnId: columnId });
    }
    private getRidOfHtml = (html?: string): string => {
        if (html === undefined) {
            return "";
        }
        return html.replace(/<[^>]*>?/gm, '');
    };
    private onSearched = (searchResults?: ExtendedDataCollection<WikiSearchResultDto, string>) => {
        this.setState({ searchResults: searchResults?.elements, loaderActive: false, searchText: searchResults?.data });

        if (this.props.onFoundArticles)
            this.props.onFoundArticles(searchResults?.elements);
    };
    private onShow = (show: string) => () => {
        this.setState({
            show: this.state.show === undefined ? show : undefined,
            currentCategory: undefined,
            currentSubCategory: undefined,
            currentArticle: undefined,
        });
        if (this.props.onFoundArticles) {
            if (show === "filter")
                this.props.onFoundArticles(undefined);
            else
                this.props.onFoundArticles(this.state.searchResults);
        }
    };
    private filter = (filter?: string) => {
        this.setState({ filter });
    };
    private showSubcats = (id: string) => async () => {

        let currentCategory = _.find(this.props.categories, subcat => subcat.id === id);
        const articles = _.cloneDeep(this.state.articles);
        if (_.isEqual(currentCategory, this.state.currentCategory) && this.state.currentSubCategory === undefined)
            currentCategory = undefined;
        else {
            if (!articles[id]) {
                articles[id] = await ControllerHelper.singleCall({ id, groupId: this.state.selectedGroupid ?? this.props.groupid }, WikiController.GetArticlesInCatById, true);
            }
            if (this.props.onFoundArticles && articles && articles[id] && articles[id][id]) {
                let results = new Array<WikiSearchResultDto>();
                for (const a of articles[id][id]) {
                    results.push(this.toWikiSearchResult(a));
                }
                this.props.onFoundArticles(results);
            }
        }

        this.setState({ currentCategory, currentArticle: undefined, currentSubCategory: undefined, articles });
    };
    private showArticle = (article: WikiArticleDownloadDto) => async () => {
        let currentArticle: WikiSearchResultDto = {};
        if (this.state.currentArticle === undefined || this.state.currentArticle.id !== article.id) {
            const result = await ControllerHelper.singleCall({ id: article.id }, PostController.GetSinglePost) as any as WikiArticleDownloadDto;
            currentArticle = this.toWikiSearchResult(result);
        }
        this.setState({ currentArticle });
        this.sectionColumnSelected(article.id)();
    };

    private toWikiSearchResult = (a: WikiArticleDownloadDto): WikiSearchResultDto => {
        let currentArticle: WikiSearchResultDto = {};
        const sections: WikiSectionSearchResultDto[] = new Array<WikiSectionSearchResultDto>();
        for (const s of a.sections) {
            const columns: WikiColumnSearchResultDto[] = new Array<WikiColumnSearchResultDto>();
            for (const c of s.columns) {
                const column: WikiColumnSearchResultDto = {
                    title: getTranslated(c.title, this.props.contentLanguage).text,
                    id: c.id
                };
                columns.push(column);
            }
            const section: WikiSectionSearchResultDto = {
                title: getTranslated(s.title, this.props.contentLanguage).text,
                id: s.id,
                columns
            };
            sections.push(section);
        }
        currentArticle = {
            headline: getTranslated(a.headlines, this.props.contentLanguage).text,
            id: a.id,
            sections
        };
        return currentArticle;
    };
    private getArticlesInCategory = (currentSubCategory: WikiCategoryDownloadDto) => async () => {
        const articles = _.cloneDeep(this.state.articles);
        if (!articles[currentSubCategory.id])
            articles[currentSubCategory.id] = await ControllerHelper.singleCall({ id: currentSubCategory.id, groupId: this.state.selectedGroupid ?? this.props.groupid }, WikiController.GetArticlesInCatById, true);
        this.setState({ articles, currentSubCategory, currentArticle: undefined });

        if (articles[currentSubCategory.id] && this.props.onFoundArticles) {
            let results = new Array<WikiSearchResultDto>();
            for (const a of articles[currentSubCategory.id][currentSubCategory.id]) {
                results.push(this.toWikiSearchResult(a));
            }
            this.props.onFoundArticles(results);
        }
    };
    private showLoader = () => {
        this.setState({ loaderActive: true });
    }
}
export default translate("wiki")(connect(mapper)(WikiArticleSelection));