import { ControllerHelper, PostController, TagDownloadDto, UserController, UserShortInfoDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { Button, ButtonKind, getColor, getUriFromLinkByName, Image, ImgThemeConsumer, ImgThemeManipulation, Input } from "imaginarity-react-ui";
import * as _ from "lodash";
import Quill, { BoundsStatic, RangeStatic, StringMap } from "quill";
import 'quill/dist/quill.snow.css';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { getTranslatedUserName } from 'services/Helpers/TranslationHelpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { EmojiBlot, HttpsMailto, Httpsmailto, PPELink, PPElink, UserLink, UserProfileLink } from './EditorBlots';
import { EditorSC as T } from './EditorSC';
import EditorToolbar from "./EditorToolbar";
import EditorToolbarHelp from './EditorToolbarHelp';

const formats = [
    'header',
    'bold', 'italic', 'underline',
    'color',
    'background',
    'list',
    Httpsmailto,
    PPElink,
    UserLink,
    '@',    // will react on @
    '#',     // will react on #
    //Emoji
];

const mapper = (state: ApplicationState) => ({ currentGroup: state.currentGroup });

export interface EditorProps extends ImgI18NTranslatedComponentProps, ComponentWithMappedApplicationState<typeof mapper> {
    value: string;
    onChange?: (html: string) => void;
    formats?: string[];
    toolbarFormat?: StringMap;
    onChangeHashtags?: (hashtags?: TagDownloadDto[]) => void;
    fontSize?: string;
    lineHeight?: number;
    paragraphMarginBottom?: string;
    textareaHeight?: string;
    buttonSize?: "small" | "default";
    toolbarBackground?: "string";
    toolbarFontColor?: "string";
    showExpandButton?: boolean;
    showHelpButton?: boolean;
    showPreviewButton?: boolean;
    hideToolbar?: boolean;
    rightButtonsKind?: ButtonKind;
    blockButtonsKind?: ButtonKind;
    fontButtonsKind?: ButtonKind;
    linkButtonsKind?: ButtonKind;
    height?: number;
}

interface EditorState {
    users?: UserShortInfoDownloadDto[];
    search?: string;
    searching: boolean;
    newHashtags?: TagDownloadDto[];
    hashtagsSearching: TagDownloadDto[];
    atActive: boolean;
    hashtagActive: boolean;
    expandEditor: boolean;
    showPreview: boolean;
    showHelp: boolean;
}
class Editor extends React.Component<EditorProps, EditorState> {

    //private static whyDidYouRender = true;
    private quill?: Quill;
    private setQuillInToolbar?: (q: Quill) => void;
    private editorRef: any;
    private toolbar: HTMLDivElement | undefined;
    private editor: HTMLDivElement | undefined;
    private edit: boolean = false;
    private range?: RangeStatic;
    private bounds?: BoundsStatic;
    private inputRef?: any;

    constructor(props: EditorProps) {
        super(props);
        this.keyHit = this.keyHit.bind(this);
        this.state = {
            hashtagsSearching: [],
            searching: false,
            atActive: false,
            hashtagActive: false,
            expandEditor: true,
            showPreview: false,
            showHelp: false,
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.keyHit, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyHit, false);
    }
    componentDidUpdate(prevProps: EditorProps, prevState: EditorState) {
        if (this.quill) {
            const valDif = this.props.onChange ? this.props.value !== this.quill.root.innerHTML : false;
            if (valDif && this.props.value !== '' && !this.quill.root.innerHTML.includes("ql-cursor")) {
                this.divSet(this.editorRef);
            }
        }
    }
    public render() {
        const { users, hashtagsSearching, searching, search, atActive, hashtagActive } = this.state;
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    // EDITOR SETTINGS
                    const buttonSize = this.props.buttonSize ?? "default";
                    // const toolbarBackground = this.props.toolbarBackground ?? lightenColor({ theme }, getColor({ theme }, "@middleLightGrey"), 114);
                    const toolbarBackground = this.props.toolbarBackground ?? getColor({ theme }, "@veryLightGrey");
                    const toolbarFontColor = this.props.toolbarFontColor ?? getColor({ theme }, "@accent");
                    const showExpandButton = this.props.showExpandButton ?? true;
                    const showHelpButton = this.props.showHelpButton ?? true;
                    const showPreviewButton = this.props.showPreviewButton ?? true;
                    const rightButtonsKind = this.props.rightButtonsKind ?? "editorButton";
                    const blockButtonsKind = this.props.blockButtonsKind ?? "editorButton";
                    const fontButtonsKind = this.props.fontButtonsKind ?? "editorButton";
                    const linkButtonsKind = this.props.linkButtonsKind ?? "editorButton";
                    const fontSize = this.props.fontSize ?? "0.9rem";
                    const lineHeight = this.props.lineHeight ?? 1.2;
                    const paragraphMarginBottom = this.props.paragraphMarginBottom ?? "0.5rem";
                    const textareaHeight = this.props.textareaHeight ?? "150px";
                    return (
                        <ImgThemeManipulation manipulation={
                            t => ({
                                editor: {
                                    height: textareaHeight,
                                    listFontSize: fontSize,
                                    p: { lineHeight: lineHeight, margin: `0 0 ${paragraphMarginBottom} 0` },
                                    fontSize: fontSize,
                                }
                            })
                        }>
                            <div style={{ position: "relative" }}>

                                <T.EditorToolbarContainer hidden={this.state.showPreview || this.state.showHelp} >
                                    {!this.props.hideToolbar &&
                                        <EditorToolbar
                                            groupId={this.props.currentGroup?.id}
                                            formats={this.props.formats ?? formats}
                                            toolbarFormat={this.props.toolbarFormat}
                                            quillFunctionCallback={this.getToolbarQuill}
                                            expand={this.toggleExpandEditor}
                                            preview={this.togglePreview}
                                            expandIcon={this.state.expandEditor ? "decrease" : "expand"}
                                            previewIcon={this.state.showPreview ? "eye close" : "eye open"}
                                            help={this.toggleHelp}
                                            showExpandButton={showExpandButton}
                                            showHelpButton={showHelpButton}
                                            showPreviewButton={showPreviewButton}
                                            rightButtonsKind={rightButtonsKind}
                                            blockButtonsKind={blockButtonsKind}
                                            fontButtonsKind={fontButtonsKind}
                                            linkButtonsKind={linkButtonsKind}
                                            buttonSize={buttonSize}
                                            toolbarBackground={toolbarBackground}
                                        />
                                    }
                                </T.EditorToolbarContainer>

                                <T.EditorContainer height={this.props.height} ref={this.setUpHandlers} notFixed={this.state.expandEditor} hidden={this.state.showPreview || this.state.showHelp} className="EditorContainer">
                                    <div ref={this.divSet} />
                                    <T.UserListPopup top={this.bounds?.top} left={this.bounds?.left} hasUsers={users && users?.length > 0 ? true : false} style={{ display: searching ? "block" : "none" }}>
                                        <div style={{ display: "flex", flexDirection: "row" }} >
                                            <Input
                                                onKeyUp={this.onKeyUp}
                                                innerRef={this.setInputRef}
                                                value={search ?? ""}
                                                onChange={this.setSearch}
                                                icon={hashtagActive ? "tag" : "search"}
                                                placeHolder={hashtagActive ? this.props.t("enter hashtag") : this.props.t("search user")}
                                            />
                                            <Button
                                                icon="times"
                                                kind="halfTransparentButton"
                                                onClick={this.resetSearch}
                                                floated="right"
                                            />
                                        </div>
                                        <div style={{ height: "auto", maxHeight: 130, overflowY: "auto" }}>
                                            {atActive && _.map(users, u => {
                                                const linkUri = getUriFromLinkByName(u.avatar, "self") ?? "";
                                                return (
                                                    <T.UserListed key={u.id} onClick={this.selectUser(u)} >
                                                        <Image
                                                            src={linkUri === "" ? theme.emptyAvatarImageUrl : linkUri}
                                                            width="20px"
                                                            floated="left"
                                                            squared
                                                            rounded
                                                            borderColor={theme.colors.middleLightGrey}
                                                        />
                                                        {getTranslatedUserName(u)}
                                                    </T.UserListed>
                                                );
                                            })}
                                            {hashtagActive && _.map(hashtagsSearching, (h, idx) => {
                                                return (
                                                    <T.UserListed key={idx} onClick={this.selectHashtag(h.tag)} >
                                                        {h.tag}
                                                    </T.UserListed>
                                                );
                                            })}
                                        </div>
                                    </T.UserListPopup>
                                </T.EditorContainer>
                                <T.PreviewContainer hidden={!this.state.showPreview}>
                                    <T.PCHeader toolbarHeight={buttonSize} toolbarBackground={toolbarBackground} toolbarFontColor={toolbarFontColor}>
                                        <Button
                                            icon="times"
                                            kind="primary"
                                            onClick={this.togglePreview}
                                            floated="right"
                                            size={buttonSize}
                                        />
                                        {this.props.t("preview")}
                                    </T.PCHeader>
                                    <T.PCContent>
                                        <SafeHTML html={this.props.value ?? ""} />
                                    </T.PCContent>
                                </T.PreviewContainer>
                                <T.PreviewContainer hidden={!this.state.showHelp} >
                                    <T.PCHeader toolbarHeight={buttonSize} toolbarBackground={toolbarBackground} toolbarFontColor={toolbarFontColor}>
                                        <Button
                                            icon="times"
                                            kind="primary"
                                            onClick={this.toggleHelp}
                                            floated="right"
                                            size={buttonSize}
                                        />
                                        {this.props.t("help")}
                                    </T.PCHeader>
                                    <T.PCContent style={{ minHeight: theme.editor.height }}>
                                        <p>{this.props.t("in the table below you will find explanations of the shortcuts and buttons for this editor. You can also combine the different functions and formats.")} </p>
                                        <T.TableContainer>
                                            <EditorToolbarHelp formats={this.props.formats ?? formats} />
                                        </T.TableContainer>
                                    </T.PCContent>
                                </T.PreviewContainer>
                                {/* <LoaderInline active={searching} /> */}
                            </div>
                        </ImgThemeManipulation>
                    );
                }
                }
            </ImgThemeConsumer>
        );
    };
    private getToolbarQuill = (setQuillInToolbar: (q: Quill) => void) => {
        this.setQuillInToolbar = setQuillInToolbar;
    };
    private onKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            if (this.state.search && this.state.search !== '' && !this.state.search.includes(' ')) {
                this.selectHashtag(this.state.search)();
                const newHashtags = _.clone(this.state.newHashtags) ?? [];
                if (_.find(newHashtags, ht => ht.tag === this.state.search) === undefined) {
                    newHashtags.push({ tag: this.state.search, count: 0 });
                    this.setState({ newHashtags });
                    if (this.props.onChangeHashtags)
                        this.props.onChangeHashtags(newHashtags);
                }
            }
        }
    };
    private setInputRef = (ref?: any) => {
        this.inputRef = ref;
    };
    private keyHit = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            this.resetSearch();
        }
    };
    private resetSearch = () => {
        this.setState({ searching: false, search: undefined, users: undefined, atActive: false, hashtagActive: false });
    };
    private setSearch = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = event.currentTarget.value;

        this.setState({ search });
        if (this.state.atActive)
            await this.searchUsers(search);
        if (this.state.hashtagActive)
            await this.searchTags(search);
    }
    private handleInsert = (insert?: any) => {
        if (insert === undefined || insert.insert === undefined)
            return;
        if (insert.insert !== '@' && insert.insert !== '#')
            return;

        const range = this.quill?.getSelection();
        if (range) {
            this.range = range;
            this.bounds = this.quill?.getBounds(range.index);

            if (this.noCharLeftSide(range)) {
                if (insert.insert === '@' && _.find(this.props.formats ?? formats, f => f === '@')) {
                    const range = this.quill?.getSelection();
                    this.range = range ?? this.range;
                    this.setState({ searching: true, search: undefined, atActive: true, hashtagActive: false },
                        () => setTimeout(() => {
                            this.inputRef?.focus();
                        }, 100)
                    );
                }
                else if (insert.insert === '#' && _.find(this.props.formats ?? formats, f => f === '#')) {
                    this.setState({ searching: true, search: undefined, hashtagActive: true, atActive: false, users: undefined, },
                        () => setTimeout(() => { this.inputRef?.focus(); }, 100)
                    );
                }
            }
        }
    };
    private noCharLeftSide = (range: RangeStatic): boolean => {
        const textBeforeCursor = this.quill?.getText(0, range.index - 1); // get text before @|#
        if (textBeforeCursor !== undefined) {
            const lastChar = textBeforeCursor[textBeforeCursor.length - 1];
            if (lastChar === undefined || lastChar === ' ' || lastChar === '\n')
                return true;
        }
        return false;
    };
    private selectHashtag = (hashtag?: string) => () => {
        if (hashtag === undefined)
            return;

        if (this.range) {
            const idx = this.range ? this.range.index - 1 : 0;
            this.quill?.insertText(idx, "#" + hashtag);
            this.setState({ searching: false }, () => setTimeout(() => {
                if (this.range)
                    this.quill?.setSelection(idx + hashtag.length + 1, 0);
            }, 300));
        }
    };
    private selectUser = (user: UserShortInfoDownloadDto) => () => {
        const translatedName = getTranslatedUserName(user);

        if (this.range) {
            const idx = this.range.index > 0 ? this.range.index - 1 : 0;

            this.quill?.insertText(idx, translatedName);
            this.quill?.setSelection(idx, translatedName.length);
            this.quill?.format(UserLink, user.id);

            this.setState({ users: undefined, searching: false }, () => setTimeout(() => {
                if (this.range)
                    this.quill?.setSelection(idx + translatedName.length, 0);
            }, 500));
        }
    };
    private setUpHandlers = (ref: any) => {
        const div = ref as HTMLDivElement;
        if (div) {
            //div.addEventListener("focusin", this.focusin)
            //div.addEventListener("focusout", this.focusout)
        }
    }
    private handleFocus = _.debounce((focus: boolean) => {
        if (focus)
            this.hasFocus();
        else
            this.hasNoFocus();
    }, 150);

    private focusin = () => {
        this.hasFocus();
        this.handleFocus(true);
    }

    private focusout = () => {
        this.handleFocus(false);
    }


    private divSet = (ref: any) => {
        if (ref !== null) {
            this.editorRef = ref;
            this.toolbar = undefined;

            ref.innerHTML = this.props.value;
            //console.log('divset', this.props.value);

            Quill.register(PPELink, true);
            Quill.register(UserProfileLink, true);
            Quill.register(HttpsMailto, true);
            Quill.register(EmojiBlot, true);

            // var bindings = {
            //     enter: {
            //         key: 13,
            //         handler: function (range: RangeStatic, context: any) {
            //             console.log("enter is hit", range, context);
            //         }
            //     },
            // }

            this.quill = new Quill(
                ref,
                {
                    //modules: this.props.modules ?? modules,
                    // modules: {
                    //     keyboard: {
                    //         bindings: bindings
                    //     }
                    // },
                    formats: this.props.formats ?? formats,
                    theme: "snow"
                }
            );
            if (this.setQuillInToolbar)
                this.setQuillInToolbar(this.quill);
            else {
                console.log("setQuillInToolbar does not exist");
            }

            if (this.range) {
                this.quill?.setSelection(this.range.index, this.range.length);
            }

            const p = (ref as HTMLDivElement).parentElement;
            const tbs: HTMLDivElement[] = [];
            _.forEach(p?.childNodes, n => {
                const d = n as HTMLDivElement;
                if (d && d.className) {
                    if (d.className.indexOf("ql-toolbar") >= 0) {
                        tbs.push(d);
                    }
                    if (d.className.indexOf("ql-container") >= 0) {
                        this.editor = _.first(d.childNodes) as HTMLDivElement;
                        this.editor.className += " inactive";
                    }

                }
            });

            if (tbs.length === 0) {
                console.log("no toolbar is available!");
            }
            else if (tbs.length > 0)
                _.forEach(tbs, (t, i) => {
                    if (i < tbs.length - 1)
                        p?.removeChild(t)
                });
            this.toolbar = _.last(tbs);

            this.quill.on('text-change', (delta: any, oldContents: any, source: any) => {
                this.handleInsert(_.find(delta?.ops, o => o.insert));
                const innerHTML = this.quill?.root.innerHTML;
                this.update(innerHTML ?? "");
            });
            this.quill.on('editor-change', (delta: any, oldContents: any, source: any) => { // need this for emoji insert
                const innerHTML = this.quill?.root.innerHTML;
                this.update(innerHTML ?? "");
            });
            // this.quill.on('selection-change', (range: RangeStatic, oldRange: RangeStatic, source: any) => {
            //     console.log('selection-change', range, oldRange, source);
            // });

            //         //console.log("off focus => ", this.props.value);
            //         this.edit = false;
            //         this.setupToolbarAndEditor();
            //     }
            //     else {
            //         this.edit = true;
            //         //console.log("on focus => ", this.props.value);
            //         this.setupToolbarAndEditor();
            //     }
            // });
            this.setupToolbarAndEditor();

            // if (this.quill) {
            //     this.quill.focus();
            //     console.log("quill [re]initialized", this.quill.getSelection());
            // }
            //this.forceUpdate();
        }
    };
    private searchTags = async (search: string) => {
        console.log("searching #", search);

        if (this.props.currentGroup) {
            const hashtags = await ControllerHelper.singleCall({ groupid: this.props.currentGroup.id }, PostController.GetTags); // replace with proper function, which can search within supplied string (when available)
            // if replaced, set result as hashtagsSearching and return, remove below

            let hashtagsSearching: TagDownloadDto[] = [];
            const t = search.toLowerCase();
            if (hashtags)
                hashtags.forEach((hashtag) => {
                    if (hashtag.tag.toLowerCase().indexOf(t) > -1) {
                        hashtagsSearching.push(hashtag);
                    }
                });
            this.setState({ hashtagsSearching });
        }
    };
    private searchUsers = async (search: string) => {
        var users = await ControllerHelper.singleCall({ searchInfo: { general: search } }, UserController.SearchUsers, true);
        this.setState({ users });
    };

    private update = _.debounce((val: string) => {
        if (this.props.onChange)
            this.props.onChange(val);
    }, 250);


    private hasFocus = () => {
        if (!this.edit) {
            this.edit = true;
            this.setupToolbarAndEditor();
        }
    }
    private hasNoFocus = () => {
        if (this.edit) {
            this.edit = false;
            this.setupToolbarAndEditor();
        }
    }
    private setupToolbarAndEditor = () => {
        if (this.toolbar) {
            if (this.edit) {
                this.toolbar.style.display = "block";
            }
            else {
                this.toolbar.style.display = "none";
            }

            if (this.editor) {
                if (this.edit) {
                    let cn = this.editor.className;
                    const i = cn.indexOf(" inactive");
                    if (i >= 0) {
                        cn = cn.substr(0, i) + cn.substr(i + 9);
                    }
                    this.editor.className = cn;
                }
                else {
                    let cn = this.editor.className;
                    const i = cn.indexOf(" inactive");
                    if (i < 0)
                        this.editor.className = cn + " inactive";
                }
            }
        }
    }
    private toggleExpandEditor = () => {
        this.setState({ expandEditor: !this.state.expandEditor });
    };
    private togglePreview = () => {
        this.setState({ showPreview: !this.state.showPreview });
    };
    private toggleHelp = () => {
        this.setState({ showHelp: !this.state.showHelp });
    };
}
export default translate("editor")(connect(mapper)(Editor));