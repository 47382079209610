import { belowBreakpointOrEqual, fadeColor, fromThemeCreator, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import { WikiTheme } from 'views/Wiki/WikiTheme';

const fromTheme = fromThemeCreator<WikiTheme>("wikiTheme");

export class PlaylistSC {

    public static Container = styled.div<{ open: boolean, backgroundToggle: boolean, done: boolean, isMobileView: boolean, active: boolean, isPlaylistHome: boolean, isFirstChild: boolean }>`
        width: calc(100% + 1px);
        font-size:0.85rem;
        min-height: ${p => p.isPlaylistHome ? "160px" : "unset"};
        //height: ${p => p.isPlaylistHome ? "unset" : "160px"};
        padding: ${p => p.isPlaylistHome ? "10px 0" : 0};
        color: ${props => props.active ? getColor(props, props.theme.colors.mainForeground) : getColor(props, props.theme.colors.mainForeground)};
        display: grid;
        grid-template-rows: ${p => p.open ? "fit-content" : p.isPlaylistHome ? "120px" : "140px"};
        grid-template-columns: ${p => p.isMobileView ? "70px auto" : p.open ? "140px auto 40px" : "140px auto minmax(min-content, max-content) 40px"};
        background-color: ${p => p.open ? fadeColor(p, "@accentBlue", 3) : p.backgroundToggle ? p.done ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 0) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 0) : p.done ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 0) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 3)};
        border-bottom: 1px solid ${p => p.open ? fadeColor(p, "@accentBlue", 30) : p.backgroundToggle ? p.done ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 12) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 5) : p.done ? fadeColor(p, getColor(p, p.theme.colors.accentGreen), 15) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 8)};
        position: relative;
        transition: 0.2s all ease-out;
        border-left: 1px solid transparent;
        margin-left: -1px;
        border-top: 1px solid ${p => p.isFirstChild ? "transparent" : p.open ? fadeColor(p, "@accentBlue", 30) : "transparent"};
     
        button>div{margin: 0;}

        &:hover {
            background-color: ${p => p.open ? fadeColor(p, "@accentBlue", 3) : fadeColor(p, "@darkGrey", 5)};
        }
    
        .IconIndicator{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.1s all ease-out;
            opacity: 0;
        }
        :hover{
            .IconIndicator{
                border-right: ${p => p.open ? 0 : 5}px solid ${p => p.done ? getColor(p, p.theme.colors.accentGreen) : getColor(p, p.theme.colors.accent)};;
                width: 5px;
                min-height: 100%;
                opacity: 1;
                left: -3px;
            }
        }
    `;

    public static LogoContainer = styled.div`
        grid-column: 1;
        -ms-grid-column: 1;
        width: 100%;
        padding: 10px;
        margin-left:10px;
    `;

    public static ButtonBar = styled.div<{ open: boolean, isFirstChild: boolean }>`
        position: absolute;
        top: ${p => p.isFirstChild ? 0 : -1}px;
        bottom: -1px;
        right: -1px;
        background: ${p => p.open ? fadeColor(p, "@accentBlue", 10) : fadeColor(p, "@accentBlack", 5)};
        width: 40px;
        border-top: 1px solid ${p => p.open ? fadeColor(p, "@accentBlue", 20) : "transparent"};
        border-bottom: 1px solid ${p => p.isFirstChild ? "transparent" : p.open ? fadeColor(p, "@accentBlue", 20) : "transparent"};
`;

    public static DescriptionContainer = styled.div`
        grid-column: 2;
        -ms-grid-column: 2;
        width: 100%;
        padding: 10px;
        margin-left:10px;
    `;

    public static DescriptionContainerButton = styled.div`
        position: absolute;
        top:1px;
        right:1px;
        margin-left: 5px;
        width: 80px;
        height: 40px;
    `;

    public static Title = styled.div`
        font-weight: bolder;
        margin-bottom: 5px;
        font-size: 1rem;
        width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 30px)" : "100%"};
    `;

    public static EntriesContainerTitle = styled.div<{ accentBg?: boolean }>`
        margin-left:${props => belowBreakpointOrEqual(props, "tablet") ? "-83px" : belowBreakpointOrEqual(props, "desktop") ? "-140px" : "0px"};
        margin-bottom: 15px;
        font-size: 1.0em;
        font-weight: bolder;
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.lightGrey)};
        line-height: 40px;
        button>div{margin: 0;}
        background: ${p => p.accentBg ? getColor(p, "@veryLightGrey") : "transparent"};
        /* color: ${p => getColor(p, p.accentBg ? "@mainBackground" : "inherit")}; */
        padding-left: 10px;
    `;
    public static NodeInfoContainer = styled.div<{ color: string }>`
        display: grid;
        grid-template-columns: 1fr max-content;
        margin-top: -10py;
        margin-bottom: 15px;
        line-height: 30px;
        padding: 10px 10px 10px 38px;
        border-left: 5px solid ${p => getColor(p, p.color)};
        background: ${p => fadeColor(p, p.color, 2)};
        color: ${p => lightenColor(p, p.color, 50)};
        svg{
            stroke: ${p => lightenColor(p, p.color, 50)};
        }
`;
    public static NodeInfoContainerInfo = styled.div`
        font-weight: normal;
        font-size: 1em;
    `;
    public static NextNodesSquare = styled.span<{ color: string }>`
        float: left;
        margin-right: 10px;
        width: 14px;
        height: 14px;
        background: ${p => getColor(p, p.color)};
        margin-top: 3px;

`;
    public static NodeInfoContainerDesc = styled.div<{ color: string }>`
        grid-column-start: 1;
        grid-column-end: 3;
        line-height: 20px;
        text-align: justify;
        padding: 10px 0 0 0;
        margin-top: 10px;
        border-top: 1px solid ${p => fadeColor(p, p.color, 25)};
`;
    public static NextEntryOld = styled.div<{ accentBg?: boolean, color: string }>`
        margin-left: 20px;
        margin-bottom: 15px;
        font-size: 1.0em;
        font-weight: bolder;
        border-bottom: 1px solid ${p => getColor(p, p.color)};
        line-height: 40px;
        background: ${p => p.accentBg ? getColor(p, "@veryLightGrey") : "transparent"};
        padding-left: 10px;
        padding-top: 10px;
        display: grid;
        grid-template-columns: 6fr 1fr 2fr;
    `;

    public static NextEntry = styled.div`
        font-size: 1.0em;
        line-height: 26x;
        background: ${p => getColor(p, p.theme.colors.mainBackground)};
        padding: 0 10px 0 0;
        display: grid;
        grid-template-columns: max-content 1fr max-content max-content max-content;
    `;
    public static NextEntryHeader = styled.div<{ pad: number }>`
        margin-bottom: 10px;
        line-height: 30px;
        font-weight: bolder;
        border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
        padding-right: ${p => p.pad}px;
        grid-row: 1;
    `;
    public static NextEntryContent = styled.div<{ pad: number }>`
        line-height: 22px;
        padding-right: ${p => p.pad}px;
`;

    public static Description = styled.div<{ closed: boolean }>`
        padding-right: 15px;
        //border-right: 1px solid ${p => lightenColor(p, p.theme.colors.lightGrey, 130)};
        width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 30px)" : "100%"};
        min-height:80px;
        position: relative;
        font-size: 0.9rem;
        ${p => p.closed && `
            overflow: hidden;
            word-wrap: break-word;
            -ms-overflow-style: none;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -ms-box-orient: vertical;
            box-orient: vertical;
            -webkit-line-clamp: 4;
            -moz-line-clamp: 4;
            -ms-line-clamp: 4;
            line-clamp: 4;
        `}
    `;

    public static StatusContainer = styled.div`
        grid-column: 3;
        -ms-grid-column: 3;
        width: 100%;
        display: grid;
        display: -ms-grid;
        grid-template-rows: 24px 19px 19px 19px 19px;
        -ms-grid-rows: 24px 19px 19px 19px 19px;
        padding:10px;
        border-left: 1px solid rgba(0,0,0,0.05);
    `;
    public static StatusContainerSmall = styled.div`
        grid-column: 2;
        -ms-grid-column: 2;
        width: 100%;
        display: grid;
        display: -ms-grid;
        grid-template-rows: 20px 20px 20px;
        -ms-grid-rows: 20px 20px 20px;
        align-items: start;
        padding-left:20px;
        margin-top: -20px;
        text-align: left;
    `;

    public static WithIcon = styled.div<{ isMobileView: boolean }>`
        text-align: ${p => p.isMobileView ? "left" : "right"};
        display: inline-block;
        line-height:20px;
    `;

    public static AssignContainer = styled(PlaylistSC.WithIcon)`
        grid-row: 1;
    `;
    public static DurationAll = styled(PlaylistSC.WithIcon)`
        grid-row: 5;
    `;
    public static AuthorContainer = styled(PlaylistSC.WithIcon)`
    grid-row: 2;
    -ms-grid-row: 2;
`;
    public static DateContainer = styled(PlaylistSC.WithIcon)`
        grid-row: 2;
        -ms-grid-row: 2;
    `;
    public static LikesContainer = styled(PlaylistSC.WithIcon)`
        grid-row: 3;
        -ms-grid-row: 3;
    `;
    public static StateContainer = styled(PlaylistSC.WithIcon)`
        grid-row: 3;
        -ms-grid-row: 3;
    `;


    public static EntriesContainer = styled.div`
        width: 100%;
        min-height: 50px;
        /* background:${p => getColor(p, p.theme.colors.mainBackground)}; */
        background: transparent;
        /* border:1px solid ${p => getColor(p, p.theme.colors.veryLightGrey)}; */
        margin-top:20px;
    `;

    public static IconContainer = styled.div`
        width: 40px;
        height: 40px;
        text-align: center;
        float: right;
    `;
    public static Element = styled.div`   
    position: relative;
    top: 0;
    left: 0;
    opacity: 0.9;
    transition: opacity 0.2s ease-out;
    &:hover{
        opacity: 1;
    }
`;
    public static TabButtonContainer = styled.div`
    width: 100%;
    height: 40px;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
    public static TabButton = styled.div<{ active: boolean }>`
    height: 40px;
    line-height: 40px;
    font-weight: ${p => p.active ? "bolder" : "normal"};
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    background: ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : "transparent"};
    width: fit-content;
    display: inline-block;
    padding: 0 20px;
    cursor: ${p => p.active ? "default" : "pointer"};
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-right: -1px;
    border-bottom-color: ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.middleLightGrey)};
    transition: all 0.2s ease;
    &:hover{
        background: ${p => p.active ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.mainForeground)};
        color: ${p => p.active ? getColor(p, p.theme.colors.mainForeground) : getColor(p, p.theme.colors.mainBackground)};
    }
`;
    public static PHTabButton = styled.div<{ active: boolean, isMobile: boolean }>`
    height:40px;
    line-height:40px;
    padding: ${props => props.isMobile ? "0 10px" : "0 20px"};
    border: 1px solid ${props => props.active ? getColor(props, props.theme.colors.middleLightGrey) : getColor(props, props.theme.colors.veryLightGrey)};
    border-bottom: 1px solid ${props => props.active ? getColor(props, props.theme.colors.mainBackground) : getColor(props, props.theme.colors.lightGrey)};
    display: inline-block;
    background: ${props => props.active ? "transparent" : fadeColor(props, getColor(props, props.theme.colors.veryLightGrey), 50)};
    cursor: ${props => props.active ? "default" : "pointer"};
    font-weight: bolder;
    transition: 0.2s all ease-out;
    &:hover{
        color: ${props => props.active ? getColor(props, props.theme.colors.mainForeground) : getColor(props, props.theme.colors.accent)};
    }
`;

    public static PHTabButtonInfo = styled.div`
    float: right;
    line-height: 40px;
    font-size: 1em;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    margin-right: 10px;
    font-weight: bolder;
`;

    public static PHDescription = styled.div<{ isMobile: boolean }>`
    padding:${p => p.isMobile ? "10px" : "30px 0 10px 0"};
`;
    public static TabsContainer = styled.div`
    position: relative;
    button>div{margin: 0;}
`;
    public static PLaylistContainer = styled.div`
    width: 100%;
    margin-top: -1px;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
`;
    public static SelectContainer = styled.div`
    float: right;
    width: 150px;
   div:first-of-type{
        background-color: transparent !important;
        border: none !important;     
    }
    
   div:first-of-type:hover{   
            background-color: #D4D4D4 !important;
    }
`;
    public static SearchContainer = styled.div`
    float: right;
    width: 150px;
    background: rgba(0,0,0,0.02);
    height: 39px;
    margin-top: 1px;
    position: relative;
    overflow: hidden;
    *{
        margin: 0 !important;
        color: ${p => getColor(p, p.theme.colors.darkGrey)};
        svg{
            stroke: ${p => getColor(p, p.theme.colors.darkGrey)};
            margin-top: 7px !important;
        }
    }
`;
    public static PlItemRow = styled.div<{ done: boolean, started: boolean, showAllDescription: boolean, paddedRight: boolean }>`
        width: 100%;
        background: ${props => props.done ? fadeColor(props, getColor(props, props.theme.colors.accentGreen), 10) : props.started ? fadeColor(props, getColor(props, props.theme.colors.darkGrey), 10) : "transparent"};
        border-top: 1px solid ${props => getColor(props, props.theme.colors.veryLightGrey)};
        position: relative;
        height: ${props => belowBreakpointOrEqual(props, "desktop") ? props.showAllDescription ? "195px" : "calc(78px / (16 / 9) + 10px)" : props.showAllDescription ? "calc(118px / (16 / 9) + 10px)" : "calc(140px / (16 / 9) + 10px)"};
        border-left:3px solid transparent;
        transition: 0.2s all ease-out;
        padding-right: ${p => p.paddedRight ? "10px" : 0};
        &:hover{
            background: ${props => props.done ? fadeColor(props, getColor(props, props.theme.colors.accentGreen), 15) : props.started ? fadeColor(props, getColor(props, props.theme.colors.darkGrey), 15) : "rgba(0,0,0,0.07)"};
            border-left:3px solid ${props => props.done ? getColor(props, props.theme.colors.accentGreen) : props.started ? getColor(props, props.theme.colors.darkGrey) : getColor(props, props.theme.colors.accent)};
        }
        margin-bottom:10px;
`;
    public static PlItem = styled.div`
        position: absolute;
        top: 3px;
        right: 10px;
`;
    public static PlItemImage = styled.div`
        width: ${props => belowBreakpointOrEqual(props, "tablet") ? "78px" : belowBreakpointOrEqual(props, "desktop") ? "118px" : "140px"};
        position: absolute;
        top: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "0px"};
        left:${props => belowBreakpointOrEqual(props, "tablet") ? "-83px" : belowBreakpointOrEqual(props, "desktop") ? "-140px" : "0px"};
        padding:${props => belowBreakpointOrEqual(props, "desktop") ? "0px" : "10px"};
`;
    public static ButtonBottomRight = styled.div`
        position: absolute;
        bottom:10px;
        right: 10px;
`;
    public static PlItemType = styled.div`
        position: absolute;
        top:8px;
        left:${props => belowBreakpointOrEqual(props, "desktop") ? "0px" : "140px"};
        right:0;
        padding: 0 10px;
        font-size: 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
    public static PlItemTitle = styled.div<{ started: boolean, done: boolean }>`
        position: absolute;
        right:0;
        top:28px;
        left:${props => belowBreakpointOrEqual(props, "desktop") ? "0px" : "140px"};
        padding: 0 10px;
        font-weight: bolder;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 33px)" : "100%"};
        a{
            color:${props => props.done ? lightenColor(props, getColor(props, props.theme.colors.accentGreen), 90) : props.started ? lightenColor(props, getColor(props, props.theme.colors.mainForeground), 90) : lightenColor(props, getColor(props, props.theme.colors.accent), 90)};
        }
`;
    public static PlItemDescription = styled.div<{ showAllDescription: boolean }>`
        position: absolute;
        top:48px;
        right:0;
        left: ${props => belowBreakpointOrEqual(props, "desktop") ? "0px" : "140px"};
        padding: 0 10px;
        font-size: 0.9rem;
        overflow: hidden;
        word-wrap: break-word;
        -ms-overflow-style: none;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: ${props => props.showAllDescription ? 5 : belowBreakpointOrEqual(props, "desktop") ? 4 : 2};
        -moz-line-clamp: ${props => props.showAllDescription ? 5 : belowBreakpointOrEqual(props, "desktop") ? 4 : 2};
        -ms-line-clamp: ${props => props.showAllDescription ? 5 : belowBreakpointOrEqual(props, "desktop") ? 4 : 2};
        line-clamp: ${props => props.showAllDescription ? 5 : belowBreakpointOrEqual(props, "desktop") ? 4 : 2};
`;
    public static PlaylistContainer = styled.div<{ active: boolean }>`
    width: 100%;
    display: grid;
    display: -ms-grid;
    //grid-template-columns: 40px auto min-content;
    grid-template-columns: 40px 1fr;
    grid-template-rows: 40px;
    align-items: center;
    cursor: ${p => p.active ? "default" : "pointer"};
    transition: 0.2s all ease-out;
    border-left: 2px solid ${p => getColor(p, p.active ? "@accentRed" : "transparent")};
    margin-left: 5px;
    padding-left: 10px;
    background:  ${p => p.active ? lightenColor(p, "backgroundColor", 95) : "transparent"};
    svg{
        display: ${p => p.active ? "inline-block" : "none"};
    }
    &:hover{
        border-left: 2px solid ${p => getColor(p, "@accentRed")};
        background:  ${p => p.active ? lightenColor(p, "backgroundColor", 95) : lightenColor(p, "backgroundColor", 90)};
        svg{
        display:inline-block;
    }
    }
`;
    public static PlaylistIcon = styled.div`
    grid-column: 1;
    -ms-grid-column: 1;
    padding-left: 0px;
`;
    public static PlaylistText = styled.div`
    grid-column: 2;
    -ms-grid-column: 2;
    font-size:0.9rem;
    line-height:40px;
    padding-left: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;  
    
`;
    public static PlaylistHover = styled.div`
   grid-column: 3;
    -ms-grid-column: 3;
    line-height:40px;
    padding-right:10px;
`;
    public static PlaylistLink = styled.a`
    color: ${p => getColor(p, p.theme.color)};
`;
    public static PlaylistLinkDiv = styled.div`
    color: ${p => getColor(p, p.theme.color)};
    //cursor: pointer;
    //float: left;
    //font-size: 0.8em;
    //font-weight: normal;
    //margin-right: 5px;
`;
    public static BannerContainer = styled.div<{ imageUrl: string }>`
    width: calc(110% + 20px);
    //height: calc(${props => props.theme.content.width}px * 3 / 17);
    height: 140px;
    //padding-bottom: ${p => 100 / 17 * 3}%;
    padding-bottom: 140px;
    background-image: url('${props => props.imageUrl ?? props.theme.emptyImageUrl}');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    top: -1px;
    left: -5%;
    margin-left: -10px;
`;
    public static BannerTitleContainerBG = styled.div`
    position: absolute;
    bottom:0px;
    left:0px;
    width: 100%;
    height:60px;
    background: ${props => fadeColor(props, getColor(props, props.theme.colors.accentBlack), 50)};
`;
    public static BannerTitle = styled.div`
    position: absolute;
    bottom:0px;
    left:0px;
    font-size:1.4rem;
    line-height:60px;
    white-space: nowrap;
    width: calc(100% - 0px);                   
    overflow: hidden;   
    text-overflow: ellipsis;
    //background: ${props => fadeColor(props, getColor(props, props.theme.colors.accentBlack), 50)};
    padding: 0 20px;
    color: ${props => getColor(props, fromTheme(props, t => t.categoryTheme.bannerOverlayTitleColor, t => t.colors.mainBackground))};
    svg{
        stroke: ${props => getColor(props, fromTheme(props, t => t.categoryTheme.bannerOverlayTitleColor, t => t.colors.mainBackground))};
    }
`;
    public static MHLPTitle = styled.div`
        font-size: 32px;
        font-weight: bolder;
        margin-top: 32px;
        opacity: 1;
        color: ${p => getColor(p, "@accentBlack")};
        font-size: 32px;
        line-height: 50px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
    `;
    public static PLItemContainerOuter = styled.div`
        position: relative;
        width: 100%;
        margin-bottom: 20px;
`;
    public static PLItemContainer = styled.div`
        width: 100%;
        display: grid;
        grid-template-columns: 200px 1fr max-content;
        grid-template-rows: 40px minmax(40px, max-content) 40px;
        background-color: ${p => fadeColor(p, "@accentBlack", 2)};
        border-radius: 0px;
`;
    public static PLItemImage = styled.div<{ imageUrl: string, accentColor: string }>`
        background: ${p => fadeColor(p, p.accentColor, 30)};
        background-image: url(${p => p.imageUrl});
        background-position: right center;
        background-size: cover;
        background-repeat: no-repeat;
        grid-area: 1 / 1 / 4 / 2;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        position: relative;
        z-index: 1;
        height: 140px;
`;
    public static PLItemContaineClickArea = styled.a<{ accColor: string }>`
        // background: ${p => fadeColor(p, "@mainBackground", 0)};
        background: ${p => fadeColor(p, p.accColor, 10)};
        left: 0;
        right: 0;
        height: 140px;
        position: absolute;
        cursor: pointer;
        transition: 0.2s all ease-out;
        z-index: 6;
        &:hover{
            // background: ${p => fadeColor(p, "@mainBackground", 20)};
            background: ${p => fadeColor(p, p.accColor, 30)};
    }
`;
    public static PLItemContaineLeftConnectorLine = styled.div<{ accentColor: string }>`
        background: ${p => fadeColor(p, p.accentColor, 20)};
        left: -25px;
        right: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
`;
    public static PrevNodeContainer = styled.div`
        background: ${p => getColor(p, "@mainBackground")};
`;
    public static PrevNodeItemContainer = styled.div`
        position: relative;
`;
    public static PrevNodeColorIndicatorLine = styled.div<{ color: string }>`
        position: absolute;
        width: 1px;
        background: ${p => p.color};
        bottom: 0;
        top: 0;
        left: -2px;
`;
    public static PrevNodeSingleItemContainer = styled.div<{ color: string }>`
        transition: 0.2s all ease-out;
        opacity: 0.8;
        //border-left: 5px solid ${p => getColor(p, p.color)} ;
        //filter: grayscale(1);
        background: ${p => fadeColor(p, p.color, 5)};
        background-size: cover;
        background-blend-mode: overlay;

        &:hover{
            opacity: 1;
            //filter: grayscale(0);
        }
`;
    public static PLItemContainerCircleOuter = styled.div<{ accentColor: string }>`
        background: ${p => getColor(p, "@mainBackground")};
        border: 1px solid ${p => fadeColor(p, p.accentColor, 20)};
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        padding: 5px;
        transform: translate(-65px, -50%);
        z-index: 4;
`;
    public static PLItemContainerCircle = styled.div<{ accentColor: string }>`
        background: ${p => fadeColor(p, p.accentColor, 5)};
        width: 34px;
        height: 34px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-62px, -50%);
        z-index: 5;
        text-align: center;
        line-height: 34px;
        font-size: 1.3em;
        font-weight: bolder;
        color: ${p => getColor(p, p.accentColor)};
`;
    public static PLItemContainerTitle = styled.a<{ accentColor: string }>`
        font-size: 1.1em;
        grid-area: 1 / 2 / 2 / 3;
        padding-right: 20px;
        line-height: 40px;
        font-weight: bolder;
        background: ${p => fadeColor(p, p.accentColor, 5)};
        padding-left: 20px;
        transition: 0.2s all ease-out;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: ${p => getColor(p, "@darkerGrey")};
        &:hover{
            color: ${p => getColor(p, p.accentColor)};
        }
    `;
    public static PLItemContainerTopRight = styled.a<{ accentColor: string }>`
        background: ${p => fadeColor(p, p.accentColor, 10)};
        grid-area: 1 / 3 / 2 / 4;
        border-top-right-radius: 0px;
        display: grid;
        width: 100%;
        grid-template-columns: 40px 1fr;
        cursor: pointer;
        transition: 0.2s all ease-out;
        line-height: 40px;
        text-align: center;
        color: ${p => getColor(p, "@darkGrey")};
        &:hover{
            background: ${p => getColor(p, p.accentColor)};
            color: ${p => getColor(p, "@mainBackground")};
            svg{
                stroke: ${p => getColor(p, "@mainBackground")};
            }
        }
`;
    public static PLItemContainerDescription = styled.div<{ showFullDescription: boolean }>`
        grid-area: 2 / 2 / 3 / 4;
        padding: 20px;
        color: ${p => getColor(p, "@darkGrey")};
        width: 100%;
        height: ${p => p.showFullDescription ? "unset" : "60px"};
        line-height: 20px;
        ${p => !p.showFullDescription && `
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -ms-line-clamp: 2;
        line-clamp: 2;
`}
`;
    public static PLItemContainerBottomCat = styled.div`
        grid-area: 3 / 2 / 4 / 3;
        line-height: 40px;
        font-size: 0.85em;
        padding-left: 20px;
        align-self: end;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
    public static PLItemContainerBottomRightContainer = styled.div`
        grid-area: 3 / 3 / 4 / 3;
        line-height: 40px;
        height: 40px;
        font-size: 0.85em;
        display: grid;
        width: 100%;
        grid-template-columns: 40px 40px 40px 40px minmax(50px, max-content);
        button>div{
            margin: 0;
        }
`;
    public static PLItemContainerBottomRightContainerRating = styled.div`
    grid-area: 3 / 3 / 4 / 3;
    line-height: 40px;
    height: 40px;
    font-size: 0.85em;
    display: grid;
    width: 100%;
    grid-template-columns: 40px 40px 40px max-content;
    button>div{
        margin: 0;
    }
`;

    public static PLItemContainerBottomRightButton = styled.div<{ accentColor: string }>`
        background: ${p => fadeColor(p, p.accentColor, 0)};
        line-height: 40px;
        height: 40px;
        transition: 0.2s all ease-out;
        cursor: default !important;
        button{cursor: default !important;}
        &:hover{
            //background: ${p => getColor(p, p.accentColor)}; /
            //color: ${p => getColor(p, "@mainBackground")} ;
           * {color: ${p => getColor(p, "@accentBlack")} ;}
            svg{
                /* stroke: ${p => getColor(p, "@mainBackground")}; */
                stroke: ${p => getColor(p, "@accentBlack")};
            }
    }
`;
    public static PLItemContainerBottomRightButtonRating = styled.div<{ accentColor: string }>`
            background: ${p => fadeColor(p, p.accentColor, 0)};
            line-height: 40px;
            height: 40px;
            transition: 0.2s all ease-out;
            padding-right: 5px;
            padding-left: 10px;
            padding-top: 3px;
            border-bottom-right-radius: 0px;
            &:hover{
                background: ${p => getColor(p, p.accentColor)};
                svg{
                    stroke: ${p => getColor(p, "@mainBackground")};
                }
        }
`;
    public static PLItemContainerBottomRightButtonTime = styled(this.PLItemContainerBottomRightButton)`
    text-align: center;
    border-bottom-right-radius: 0px;
    padding: 0 15px;
    cursor: default;
    &:hover{
        /* color: ${p => getColor(p, "@mainBackground")}; */
        stroke: ${p => getColor(p, "@accentBlack")};
    }
`;
    public static IconDoneContainer = styled.div`
        position: absolute;
        top: 30px;
        left: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 5px;
        text-align: center;
        //background: linear-gradient(135deg, #fff 45%, ${p => fadeColor(p, "@accentGreen", 50)});
        background: #fff;
        border: 1px inset #fff;
        //box-shadow: inset 1px 1px 3px -1px ${p => getColor(p, "@accentGreen")};
`;





    public static TableGrid = styled.div<{ showButtons: boolean, isDesktop: boolean }>`
    display: grid;
    grid-template-columns: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "max-content" : "calc(5% + 6px)"} 48px 1fr max-content max-content max-content max-content ${p => p.showButtons && "max-content"}  ${p => belowBreakpointOrEqual(p, "largeMobile") ? "max-content" : "5%"};
    row-gap: 14px;
    margin: 37px 0;
    //width: calc(110% + 24px);
    width: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "100%" : "calc(110% + 24px)"};
    //transform: translateX(calc(-5% - 7px));
    transform: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "unset" : "translateX(calc(-5% - 7px))"};

    .rowWrapper{
        transition: all 0.2s ease-out;
        display: contents;
}

    .rowWrapper:hover > div{
        background-color: rgba(0,0,0,0.01);

}
`;
    public static TitleRowCell = styled.div`
    font-weight: 600;
    padding: 0 0 0 24px;
    margin-bottom: -10px;
    display: grid;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
`;
    public static HeadlineRow = styled.div<{ error?: boolean }>`
    padding: 0 12px;
    grid-column-start: 1;
    background: ${p => p.error ? fadeColor(p, "@accentRed", 3) : "#F8F8F8"}; 
    padding-left: calc(5% + ${p => p.error ? 78 : 7}px);
    height: 25;
    line-height: 25px; 
    border-top: 1px solid #e9e8e8; 
    font-size: 0.8em;
    font-weight: ${p => p.error ? 400 : 600}; 
    color: ${p => p.error ? getColor(p, "@accentRed") : "#626669"}; 
    margin-bottom: 0;
`;
    public static Headline = styled.div`
    font-weight: 600;
    font-size: 1.4em;
    margin-top: 40px;
`;
    public static HeadlineInput = styled.div`
    float: right;
    position: relative;
    height: 40px;
    margin-top: -8px;
    button>div{margin: 0;}
`;
    public static HeadlineInputClear = styled.div`
    position: absolute;
    top: 12px;
    cursor: pointer;
    &:hover{
        svg{
            stroke: ${p => getColor(p, "@accentRed")}; 
        }
    }
`;
    public static CarouselContainer = styled.div`
    position: relative;
    margin-top: 19px;
    width: 100%;
    button>div{
        margin: 0;
        &:hover{
        svg{
            stroke: ${p => getColor(p, "@darkGrey")}; 
        }
        }
    }
    *:focus {
        outline: 0;
    }
    .slider-control-bottomcenter {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        button>div{margin:0;}
        ul {
            position: relative;
            display: flex;
            margin: 0px;
            padding: 0px;
            list-style-type: none;
        
            li {
                button {
                    cursor: pointer;
                    opacity: 1;
                    background: transparent;
                    border: none;
                    fill: ${p => getColor(p, p.theme.colors.middleLightGrey)};
                    padding: 1px 6px;
                    margin: 0 -6px;
                    svg{
                        transform: scale(0.5);
                    }
                    &:hover{
                        fill: ${p => getColor(p, p.theme.colors.accent)};
                    }
                }
            }
        }

        .active {
            button {
                fill: ${p => getColor(p, p.theme.colors.lightGrey)} !important;
            }
        }
}
`;
    public static CarouselButtonRight = styled.div<{ disabled: boolean }>`
        position: absolute;
        right: -7px;
        top: -50px;
        svg{
            stroke: ${p => getColor(p, p.disabled ? "@disabled" : "@accentBlack")};
            transition: all 0.2s ease-out;
            cursor: ${p => p.disabled ? "default" : "pointer"};
            &:hover{
                stroke: ${p => getColor(p, p.disabled ? "@disabled" : "@accent")};
            }
        }
    `;
    public static CarouselButtonLeft = styled(this.CarouselButtonRight)`
        right: 41px;
    `;


    public static PoiLogoContainer = styled.div`
    cursor: pointer;
`;
    public static PoiDescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    font-size: 1em;
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    line-height: 48px;

    padding-left: 24px;
    &:hover{
        color: ${p => getColor(p, "@accent")};
    }
`;
    public static PoiTitle = styled.div`
    font-size: 1em;
    width:100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    //padding-top: 5px;
    //position: absolute;
    //top: 0px;
    //display: grid;
    //grid-template-columns: max-content 1fr;

    
    line-height: 48px;
    padding: 0 0 0 24px;

    &:hover{
        color: ${p => getColor(p, "@accent")};
    }
`;
    public static PoiDescription = styled.div`
    font-size: 0.87em;
    left: 0;
    position: absolute;
    bottom: 6px;
    width: 100%;
    div{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;
    public static PoiAssignContainer = styled.div`
    line-height: 48px;
    padding: 0 0 0 24px;
`;
    public static PoiDueToContainer = styled.div<{ color: string }>`
    line-height: 48px;
    padding: 0 0 0 24px;
    color: ${p => getColor(p, p.color)};
`;
    public static PoiDurationContainer = styled.div`
    line-height: 48px;
    padding: 0 0 0 24px;
`;
    public static PoiProgressContainer = styled.div<{ color: string }>`
    line-height: 48px;
    padding-left: 24px;
    color: ${p => getColor(p, p.color)};
`;
    public static PoiActionsContainer = styled.div`
    padding-left: 24px;
`;
    public static AdminPanel = styled.div`
    margin-top: 100px;
    background: #f5f5f5;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    height: 40px;
    line-height: 40px;
    opacity: 0.4;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    transition: 0.1s all ease-out;
    z-index:500;
    &:hover{
        opacity: 0.9;
    }
`;

    public static PreviewContainer = styled.div`
`;
    public static PreviewContainerGrid = styled.div`
        display: grid;
        column-gap: 20px;
        grid-template-columns: max-content max-content 1fr;
    `;
    public static PreviewContainerAAOGrid = styled.div`
    `;
    public static PreviewContainerItemsRow = styled.div`
        display: grid;
        column-gap: 20px;
        grid-template-columns: 200px 1fr;
        margin-bottom: 20px;
`;
    public static PreviewContainerItemsRowAAO = styled.div`
        display: grid;
        column-gap: 20px;
        grid-template-columns: 200px 1fr;
`;
    public static PreviewContainerItemType = styled.div`
        padding-left: 20px;
`;
    public static PreviewContainerItemTypeAAO = styled.div`
        padding-left: 10px;
`;
    public static PreviewContainerItemLoading = styled.div<{ shown: boolean }>`
        display: ${p => p.shown ? "block" : "none"};
`;

};


