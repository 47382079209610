import { BestPracticePostDownloadDto, CategoryDownloadDto, ContentPostDownloadDto, FileEntryDownloadDto, PlaylistContentDownloadDto, PlaylistEdgeTransitionFunction, PlaylistItemStateDownloadDto, PodcastPostDownloadDto, PorscheMomentPostDownloadDto, PostDownloadDto, QuizDownloadDto, TubePostDownloadDto, WikiArticleDownloadDto } from 'collaboration-service';
import * as React from 'react';
import BestPracticePlaylistPostNodeItem from './PlaylistItems/BestPracticePlaylistPostNodeItem';
import ContentPostPlaylistNodeItem from './PlaylistItems/ContentPostPlaylistNodeItem';
import QuizPlaylistNodeItem from './PlaylistItems/QuizPlaylistNodeItem';
import TubePostPlaylistNodeItem from './PlaylistItems/TubePostPlaylistNodeItem';
import WikiArticlePlaylistNodeItem from './PlaylistItems/WikiArticlePlaylistNodeItem';
import { DueToType } from 'services/Helpers';
import PodcastPostPlaylistNodeItem from './PlaylistItems/PodcastPostPlaylistNodeItem';
import PorscheMomentPostPlaylistNodeItem from './PlaylistItems/PorscheMomentPostPlaylistNodeItem';
import FileEntryPlaylistNodeItem from './PlaylistItems/FileEntryPlaylistNodeItem';
export interface PlaylistItemProps {
    item: PostDownloadDto;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
}
export interface SePlaylistItemState {
    showFullDescription: boolean;
}
export interface PlaylistDetailNodeItemProps<T extends PostDownloadDto> {
    item: T;
    orderNumber: number;
    state: PlaylistItemStateDownloadDto;
    isMobile: boolean;
    categories?: CategoryDownloadDto[];
    paddedRight?: boolean;
    playlistId?: string;
    accentColor: string;
    playlistContent?: PlaylistContentDownloadDto;
    playlistStateId?: string;
    accent?: string;
    isOptional?: boolean;
    dueToType: DueToType;
    assignmentDate?: Date;
    absoluteDueDate?: Date;
    dueDateRelativeInHours?: number;
    nodeReference?: string;
    transition?: PlaylistEdgeTransitionFunction;
    warning?: boolean;
    nodeIsDone?: boolean;
    loadPlaylistStates?: () => Promise<void>;
}

const PlaylistDetailNodeItem = (p: PlaylistDetailNodeItemProps<PostDownloadDto>) => {
    const { playlistContent, item, accent, ...subProps } = p;

    switch (item.type) {
        case "WikiArticle":
            return <WikiArticlePlaylistNodeItem item={item as WikiArticleDownloadDto} {...subProps} />;
        case "TubePost":
            return <TubePostPlaylistNodeItem item={item as TubePostDownloadDto} {...subProps} />;
        case "PodcastPost":
            return <PodcastPostPlaylistNodeItem item={item as PodcastPostDownloadDto} {...subProps} />;
        case "Quiz":
            return <QuizPlaylistNodeItem item={item as QuizDownloadDto} {...subProps} />;
        case "ContentPost":
            return <ContentPostPlaylistNodeItem item={item as ContentPostDownloadDto} {...subProps} />;
        case "BestPracticePost":
            return <BestPracticePlaylistPostNodeItem item={item as BestPracticePostDownloadDto} {...subProps} />;
        case "PorscheMomentPost":
            return <PorscheMomentPostPlaylistNodeItem item={item as PorscheMomentPostDownloadDto} {...subProps} />;
        case "FileEntry":
            return <FileEntryPlaylistNodeItem item={item as FileEntryDownloadDto as any} {...subProps} />
        default:
            return <div />;
    }
}

export default PlaylistDetailNodeItem;