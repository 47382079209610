import WikiArticleSelection from "components/Wiki/WikiArticleSelection";
import { belowBreakpointOrEqual, Button, ButtonKind, getColor, ImgIcons, ImgThemeConsumer, Input, lightenColor } from "imaginarity-react-ui";
import * as _ from "lodash";
import Quill, { BoundsStatic, RangeStatic, Sources, StringMap } from "quill";
import * as React from "react";
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { Httpsmailto, PPElink } from "./EditorBlots";
import { EditorSC as T } from './EditorSC';
import Emojis, { Emoji } from "./Emojis";

const colorsDefault = ["#000000", "#3C3B3F", "#950015", "#FF00FF", "#df7410", "#dfdb10", "#019300", "#008e93", "#004B93", '#ffffff'];

const Format: StringMap = {
    'header': [undefined, 1, 2, 3],
    'bold': [true, false],
    'italic': [true, false],
    'underline': [true, false],
    'color': colorsDefault,
    'background': colorsDefault,
    'list': ['ordered', 'bullet'],
};

interface EditorToolbarProps extends ImgI18NTranslatedComponentProps {
    //quill: Quill;
    groupId?: string;
    toolbarFormat?: StringMap;
    formats?: string[];
    quillFunctionCallback: (setQuillInToolbar: (p: Quill) => void) => void;

    expand?: () => void;
    help?: () => void;
    preview?: () => void;
    expandIcon: ImgIcons;
    previewIcon: ImgIcons;
    showHelpButton?: boolean;
    showPreviewButton?: boolean;
    showExpandButton?: boolean;
    rightButtonsKind?: ButtonKind;
    blockButtonsKind?: ButtonKind;
    fontButtonsKind?: ButtonKind;
    linkButtonsKind?: ButtonKind;
    toolbarBackground?: string;
    toolbarFontColor?: string;
    buttonSize: "default" | "small";
}

const EditorToolbar = (p: EditorToolbarProps) => {
    const [format, setFormat] = React.useState<StringMap>({});
    const [range, setRange] = React.useState<RangeStatic | null | undefined>(undefined);
    const [colorSelector, setColorSelector] = React.useState<boolean>(false);
    const [colorBgSelector, setColorBgSelector] = React.useState<boolean>(false);
    const [colors, setColors] = React.useState<string[]>((p.toolbarFormat && p.toolbarFormat['color']) ?? colorsDefault);
    const [colorsBg, setColorsBg] = React.useState<string[]>((p.toolbarFormat && p.toolbarFormat['background']) ?? colorsDefault);
    const [headerSelector, setHeaderSelector] = React.useState<boolean>(false);
    const [showLink, setShowLink] = React.useState<boolean>(false);
    const [bounds, setBounds] = React.useState<BoundsStatic | undefined>(undefined);
    const [link, setLink] = React.useState<string>();
    const [showPPELink, setShowPPELink] = React.useState<boolean>(false);
    const [showHttpsMailto, setShowHttpsMailto] = React.useState<boolean>(false);
    const [quill, setQuill] = React.useState<Quill>();

    //const { quill } = p;

    const setQuillInToolbar = (q: Quill) => {
        if (q !== undefined)
            setQuill(q);
    };

    if (quill === undefined)
        p.quillFunctionCallback(setQuillInToolbar);

    React.useEffect(() => {
        setColors((p.toolbarFormat && p.toolbarFormat['color']) ?? colorsDefault);
        setColorsBg((p.toolbarFormat && p.toolbarFormat['background']) ?? colorsDefault);
        Format["color"] = (p.toolbarFormat && p.toolbarFormat['color']) ?? colorsDefault;
        Format["background"] = (p.toolbarFormat && p.toolbarFormat['background']) ?? colorsDefault;
        Format["header"] = (p.toolbarFormat && p.toolbarFormat['header']) ?? Format['header'];
        Format["bold"] = (p.toolbarFormat && p.toolbarFormat['bold']) ?? Format['bold'];
        Format["italic"] = (p.toolbarFormat && p.toolbarFormat['italic']) ?? Format['italic'];
        Format["underline"] = (p.toolbarFormat && p.toolbarFormat['underline']) ?? Format['underline'];
        Format["list"] = (p.toolbarFormat && p.toolbarFormat['list']) ?? Format['list'];
    }, [p.toolbarFormat]);

    const escKeyHit = React.useCallback((event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            setShowPPELink(false);
            setShowLink(false);
            setShowHttpsMailto(false);
        }
    }, []);
    React.useEffect(() => {
        document.addEventListener("keydown", escKeyHit, false);
        return () => {
            document.removeEventListener("keydown", escKeyHit, false);
        };
    }, [escKeyHit]);

    const regainFocus = React.useCallback(() => {
        if (quill) {
            const text = quill.getText();
            const delta = quill.insertText(text.length - 1, '');
            const r = quill.getSelection();
            console.log("regainFocus", r, delta);
            if (r !== null) {
                setRange(r);
            }
        }
    }, [quill]);

    // React.useEffect(() => {
    //     if (quill)
    //         regainFocus();
    // }, [quill, regainFocus]);

    const selectionChanged = React.useMemo(() => (range: RangeStatic, oldRange: RangeStatic, source: Sources) => {
        if (range !== null) {
            setRange(range);
            if (quill) {
                const f = quill.getFormat(range);
                setFormat(f);
                if (range.length === 0) {
                    setShowLink(false);
                    setShowPPELink(false);
                    setShowHttpsMailto(false);
                }
            }
        }
    }, [quill, setRange, setFormat, setShowLink, setShowPPELink, setShowHttpsMailto]);

    const applyFormat = (format: string, value: any) => () => {
        if (quill) {
            const r = quill.getSelection(true);
            if (r === null) {
                regainFocus();
                return;
            }
            quill.format(format, value);
            const f = quill.getFormat();
            setFormat(f);
            quill.focus();
        }
    }

    const setLinkWeb = () => {
        setLink("https://");
    }
    const setLinkEmail = () => {
        setLink("mailto://");
    }

    const setLinkValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLink(event.currentTarget.value);
    }
    const createLink = () => {
        if (quill) {
            const text = quill.getText(range?.index, range?.length);
            if (range) {
                quill.deleteText(range.index, range.length);
                quill.insertText(range.index, text, 'link', link);
            }
            setShowLink(false);
            setRange(undefined);
        }
    };
    React.useEffect(() => {
        if (quill) {
            quill.on("selection-change", selectionChanged);
            return () => {
                quill?.off("selection-change", selectionChanged);
            }
        }
    }, [quill, selectionChanged]);

    const toggleColorSelector = () => {
        setColorSelector(!colorSelector);
    };
    const toggleColorBgSelector = () => {
        setColorBgSelector(!colorBgSelector);
    };
    const toggleHeaderSelector = () => {
        setHeaderSelector(!headerSelector);
    };
    const createHttpsMailto = (create: boolean) => () => {
        if (range) {
            applyFormat(Httpsmailto, create ? link : undefined)();
        }

        setShowHttpsMailto(false);
    };
    const sectionColumnSelected = (articleId: string, sectionId?: string, columnId?: string) => {
        applyFormat(PPElink, articleId)();
        setShowPPELink(false);
        setRange(undefined);
    };
    const applyPPELink = () => {
        if (quill) {
            const range = !showPPELink ? quill.getSelection() : undefined;
            if ((range && range.length === 0) || range === null) {
                regainFocus();
                return;
            }
            setRange(range);
            setBounds(range ? quill.getBounds(range?.index ?? 0) : undefined);
            setShowPPELink(!showPPELink);
            setShowHttpsMailto(false);
        }
    };
    const applyHttpsMailto = () => {
        if (quill) {
            setLink(format[Httpsmailto] ?? "https://");
            const range = !showHttpsMailto ? quill.getSelection() : undefined;
            if ((range && range.length === 0) || range === null) {
                regainFocus();
                return;
            }
            setRange(range);
            setBounds(range ? quill.getBounds(range?.index ?? 0) : undefined);
            setShowHttpsMailto(!showHttpsMailto);
            setShowPPELink(false);
        }
    };

    const disabled = range === undefined || range?.length === 0;
    const bullet = _.find(Format['list'], l => l === "bullet");
    const ordered = _.find(Format['list'], l => l === "ordered");


    return (<ImgThemeConsumer>
        {(theme) => {
            const showHelpButton = p.showHelpButton ?? true;
            const showPreviewButton = p.showPreviewButton ?? true;
            const showExpandButton = p.showExpandButton ?? true;
            const blockButtonsKind = p.blockButtonsKind ?? "halfTransparentButton";
            const fontButtonsKind = p.fontButtonsKind ?? "halfTransparentButton";
            const linkButtonsKind = p.linkButtonsKind ?? "halfTransparentButton";
            const rightButtonsKind = p.rightButtonsKind ?? "halfTransparentButton";
            const buttonSize = p.buttonSize;
            const toolbarHeight = buttonSize === "small" ? 32 : 40;
            const toolbarBackground = p.toolbarBackground ?? lightenColor({ theme }, getColor({ theme }, "@middleLightGrey"), 114);
            const toolbarFontColor = p.toolbarFontColor ?? getColor({ theme }, "@accent");
            const maxButInRowMobile = 3;
            const maxButInRowRest = 5;
            const maxColorButtonsInRow = belowBreakpointOrEqual({ theme }, "mobile") ? colors.length <= maxButInRowMobile ? colors.length : maxButInRowMobile : colors.length <= maxButInRowRest ? colors.length : maxButInRowRest;
            const maxBackgroundButtonsInRow = belowBreakpointOrEqual({ theme }, "mobile") ? colorsBg.length <= maxButInRowMobile ? colorsBg.length : maxButInRowMobile : colorsBg.length <= maxButInRowRest ? colorsBg.length : maxButInRowRest;
            const colorSelectorHeight = (Math.ceil(colors.length / maxColorButtonsInRow) * toolbarHeight) + 1;
            const backGroundSelectorHeight = (Math.ceil(colorsBg.length / maxBackgroundButtonsInRow) * toolbarHeight) + 1;

            return (
                <>
                    {showPPELink ?
                        <T.ToolbarContainer toolbarHeight={toolbarHeight} toolbarBackground={toolbarBackground} toolbarFontColor={toolbarFontColor}>
                            <T.PpeLinkContainer toolbarHeight={toolbarHeight}>
                                {p.t("ppe link selection")}
                            </T.PpeLinkContainer>
                            <T.PpeLinkContainerClose toolbarHeight={toolbarHeight}>
                                <Button
                                    icon="questionark"
                                    kind={rightButtonsKind}
                                    onClick={p.help}
                                    size={buttonSize}
                                />
                                <Button
                                    icon="times"
                                    kind="primary"
                                    onClick={applyPPELink}
                                    size={buttonSize}
                                />
                            </T.PpeLinkContainerClose>
                        </T.ToolbarContainer>
                        :
                        <T.ToolbarContainer toolbarHeight={toolbarHeight} toolbarBackground={toolbarBackground} toolbarFontColor={toolbarFontColor}>
                            <div style={{ display: "inline-block", width: "100%" /*, border: (range === null || range === undefined) ? "1px solid red" : "none" */ }}>
                                <div style={{ float: "right" }}>
                                    {showExpandButton &&
                                        <Button
                                            icon={p.expandIcon}
                                            kind={rightButtonsKind}
                                            onClick={p.expand}
                                            size={buttonSize}
                                            tooltip={{ tooltipText: p.t("expand / decrease view"), position: "top" }}
                                        />
                                    }
                                    {showPreviewButton &&
                                        <Button
                                            icon={p.previewIcon}
                                            kind={rightButtonsKind}
                                            onClick={p.preview}
                                            size={buttonSize}
                                            tooltip={{ tooltipText: p.t("preview"), position: "top" }}
                                        />
                                    }
                                    {showHelpButton &&
                                        <Button
                                            icon="questionark"
                                            kind={rightButtonsKind}
                                            onClick={p.help}
                                            size={buttonSize}
                                            tooltip={{ tooltipText: p.t("help"), position: "top" }}
                                        />
                                    }
                                </div>
                                {_.find(p.formats, f => f === 'header') &&
                                    <T.StyledList onMouseEnter={toggleHeaderSelector} onMouseLeave={toggleHeaderSelector} toolbarHeight={toolbarHeight}>
                                        <Button
                                            icon="editor text size"
                                            kind={blockButtonsKind}
                                            size={buttonSize}
                                        />
                                        {headerSelector &&
                                            <T.StyledSelector width={3 * toolbarHeight + 2} align="left" toolbarHeight={toolbarHeight}>
                                                {_.map(Format["header"], (h, idx) => {
                                                    const name = h ? "header" + h : "normal";
                                                    return (
                                                        <Button
                                                            key={idx}
                                                            content={name}
                                                            fluid
                                                            floated="left"
                                                            kind={format["header"] === h ? "secondary" : "tertiary"}
                                                            onClick={applyFormat("header", format["header"] === h ? undefined : h)}
                                                            size={buttonSize}
                                                        />
                                                    );
                                                })}
                                            </T.StyledSelector>
                                        }
                                    </T.StyledList>
                                }
                                <T.VerticalSpacerSmall />
                                {_.find(p.formats, f => f === 'list') &&
                                    <>
                                        {bullet &&
                                            <Button
                                                icon="editor list bullet"
                                                kind={blockButtonsKind}
                                                active={format["list"] === bullet}
                                                onClick={applyFormat("list", format["list"] === bullet ? undefined : bullet)}
                                                size={buttonSize}
                                            />
                                        }
                                        <T.VerticalSpacerSmall />
                                        {ordered &&
                                            <Button
                                                icon="editor list ordered"
                                                kind={blockButtonsKind}
                                                active={format["list"] === ordered}
                                                onClick={applyFormat("list", format["list"] === ordered ? undefined : ordered)}
                                                size={buttonSize}
                                            />
                                        }
                                    </>
                                }
                                <T.VerticalSpacerLarge />
                                {_.find(p.formats, f => f === 'bold') &&
                                    <Button
                                        icon="editor text bold"
                                        kind={fontButtonsKind}
                                        active={format["bold"]}
                                        onClick={applyFormat("bold", !format["bold"])}
                                        size={buttonSize}
                                    />
                                }
                                <T.VerticalSpacerSmall />
                                {_.find(p.formats, f => f === 'italic') &&
                                    <Button
                                        icon="editor text italic"
                                        kind={fontButtonsKind}
                                        active={format["italic"]}
                                        onClick={applyFormat("italic", !format["italic"])}
                                        size={buttonSize}
                                    />
                                }
                                <T.VerticalSpacerSmall />
                                {_.find(p.formats, f => f === 'underline') &&
                                    <Button
                                        icon="editor text underline"
                                        kind={fontButtonsKind}
                                        active={format["underline"]}
                                        onClick={applyFormat("underline", !format["underline"])}
                                        size={buttonSize}
                                    />
                                }
                                <T.VerticalSpacerSmall />
                                {_.find(p.formats, f => f === "color") &&
                                    <T.StyledList onMouseEnter={toggleColorSelector} onMouseLeave={toggleColorSelector} toolbarHeight={toolbarHeight}>
                                        <Button
                                            icon="editor text color"
                                            kind={fontButtonsKind}
                                            active={format["color"]}
                                            size={buttonSize}
                                            iconColor={format["color"] ?? theme.colors.darkGrey}
                                        />
                                        {colorSelector &&
                                            <T.StyledSelector
                                                width={maxColorButtonsInRow * toolbarHeight}
                                                align="center"
                                                toolbarHeight={toolbarHeight}
                                                style={{ height: colorSelectorHeight }}
                                            >
                                                {_.map(Format["color"], (c, idx) => {
                                                    return (
                                                        <T.ColorSelectorContainer key={idx}>
                                                            <Button
                                                                icon="editor text color"
                                                                iconColor={c}
                                                                kind={format["color"] === Format["color"][idx] ? "secondary" : "tertiary"}
                                                                onClick={applyFormat("color", format["color"] === Format["color"][idx] ? undefined : Format["color"][idx])}
                                                                size={buttonSize}
                                                            />
                                                        </T.ColorSelectorContainer>

                                                    );
                                                })}
                                            </T.StyledSelector>
                                        }
                                    </T.StyledList>
                                }
                                <T.VerticalSpacerSmall />

                                {_.find(p.formats, f => f === "background") &&
                                    <T.StyledListColor
                                        onMouseEnter={toggleColorBgSelector}
                                        onMouseLeave={toggleColorBgSelector}
                                        toolbarHeight={toolbarHeight}
                                    >
                                        <Button
                                            icon="editor text background"
                                            kind={fontButtonsKind}
                                            active={format["background"]}
                                            iconColor={format["background"] ?? theme.colors.darkGrey}
                                            size={buttonSize}
                                        />
                                        {colorBgSelector &&
                                            <T.StyledSelector
                                                width={maxBackgroundButtonsInRow * toolbarHeight}
                                                align="center"
                                                toolbarHeight={toolbarHeight}
                                                style={{ height: backGroundSelectorHeight }}
                                            >
                                                {_.map(Format["background"], (c, idx) => {
                                                    return (
                                                        <T.ColorSelectorContainer key={idx}>
                                                            <Button
                                                                key={idx}
                                                                icon="editor text background"
                                                                iconColor={c}
                                                                kind={format["background"] === Format["background"][idx] ? "secondary" : "tertiary"}
                                                                onClick={applyFormat("background", format["background"] === Format["background"][idx] ? undefined : Format["background"][idx])}
                                                                size={buttonSize}
                                                            />
                                                        </T.ColorSelectorContainer>
                                                    );
                                                })}
                                            </T.StyledSelector>
                                        }
                                    </T.StyledListColor>
                                }
                                {_.find(p.formats, f => f === Emoji) &&
                                    <Emojis
                                        linkButtonsKind={linkButtonsKind}
                                        buttonSize={buttonSize}
                                        applyFormat={applyFormat}
                                        up={false}
                                        maxButtonsInRow={4}
                                        maxRows={4}
                                    />
                                }
                                <T.VerticalSpacerLarge />
                                {_.find(p.formats, f => f === Httpsmailto) &&
                                    <Button
                                        icon="link external"
                                        kind={linkButtonsKind}
                                        onClick={applyHttpsMailto}
                                        disabled={disabled}
                                        size={buttonSize}
                                        active={format[Httpsmailto]}
                                    />
                                }
                                {_.find(p.formats, f => f === PPElink) &&
                                    <>
                                        <T.VerticalSpacerSmall />
                                        <Button
                                            icon="link internal"
                                            kind={linkButtonsKind}
                                            onClick={applyPPELink}
                                            disabled={disabled}
                                            size={buttonSize}
                                            active={format[PPElink]}
                                        />
                                    </>
                                }
                            </div>
                        </T.ToolbarContainer >
                    }
                    {showLink &&
                        <T.StyledLink top={bounds?.top} left={bounds?.left}>
                            <Button
                                icon="monitor"
                                kind="tertiary"
                                onClick={setLinkWeb}
                                floated="right"
                                active={link === "https://"}
                            />
                            <Button
                                icon="mail"
                                kind="tertiary"
                                onClick={setLinkEmail}
                                floated="right"
                                active={link === "mailto://"}
                            />
                            <Input
                                value={link}
                                onChange={setLinkValue}
                                width={`calc(100% - ${format[Httpsmailto] ? 80 : 120}px)`}
                            />
                            <Button
                                icon="save"
                                kind="tertiary"
                                onClick={createLink}
                                floated="right"
                            />
                        </T.StyledLink>
                    }
                    {showPPELink && p.groupId &&
                        <T.WikiArticleSelectionContainer toolbarHeight={toolbarHeight}>
                            <WikiArticleSelection
                                sectionColumnSelected={sectionColumnSelected}
                                groupid={p.groupId}
                                value={format[PPElink]}
                                noMinHeight
                                noMarginTop
                                showCatLoader
                            />
                        </T.WikiArticleSelectionContainer>
                    }
                    {showHttpsMailto &&
                        <T.StyledLink top={bounds?.top} left={bounds?.left}>
                            {format[Httpsmailto] === undefined &&
                                <Button
                                    icon="monitor"
                                    kind="tertiary"
                                    onClick={setLinkWeb}
                                    floated="right"
                                    active={link === "https://"}
                                />
                            }
                            {format[Httpsmailto] === undefined &&
                                <Button
                                    icon="mail"
                                    kind="tertiary"
                                    onClick={setLinkEmail}
                                    floated="right"
                                    active={link === "mailto://"}
                                />
                            }
                            {format[Httpsmailto] &&
                                <Button
                                    icon="delete"
                                    kind="tertiary"
                                    onClick={createHttpsMailto(false)}
                                    floated="right"
                                />
                            }
                            <Input
                                value={link}
                                onChange={setLinkValue}
                                width={`calc(100% - ${format[Httpsmailto] ? 80 : 120}px)`}
                            />
                            <Button
                                icon="save"
                                kind="tertiary"
                                onClick={createHttpsMailto(true)}
                                floated="right"
                            />
                        </T.StyledLink>
                    }
                </>
            );
        }
        }
    </ImgThemeConsumer >
    );
};

export default React.memo(translate("editor")(EditorToolbar));