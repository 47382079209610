import { BookmarkController, ControllerHelper, MediaDownloadDto, PorscheMomentPostDownloadDto, PostController, PostInteraction } from 'collaboration-service';
import DateTimeFns from 'components/DateTime/DateTimeFns';
import CommentsRatingsView from 'components/General/CommentsRatingsView';
import UrlComponent from 'components/General/UrlComponent';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { Button, Flag, Image, ImgThemeConsumer, Media, Tooltip, belowBreakpointOrEqual, fadeColor, getColor, getUriFromLinkByName, lightenColor } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import ImageSlider from "react-simple-image-slider";
import { Actions } from 'services/ApplicationState/Actions';
import { PostProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { CurrentSettings } from 'services/Config';
import { getLink, splitTextOnlyLastLink } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getOrTranslatePostLanguageStrings, getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import styled from 'styled-components';
import { feedTheme } from 'views/Feed/FeedMain';
import { getMediaLink } from '../../../services/Helpers/MediaHelpers';
import { MediaContainer } from '../PostClasses';
import PMLikes from './SubComponents/PMLikes';

const AvatarMouseOverContainer = styled.div<{ idx: number }>`
    position: absolute;
    top: -28px;
    right: ${p => 15 * p.idx + 40}px;
    z-index: ${p => 100 - p.idx};
    cursor: pointer;
    transform: scale(1);
    transform-origin: bottom center;
    transition: all 0.2s ease-out;
    border-radius: 20px;
    img{
        border: 1px solid #fff;
    }
    &:hover{
        transform: scale(1.4) translateY(-1px);
        z-index: ${p => 100 + p.idx};
        img{
            box-shadow: 0 6px 8px -6px #000;
    }
    }
`;
const OuterContainer = styled.div<{ shadowed: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    width: ${p => belowBreakpointOrEqual(p, "tablet") ? "calc(100% + 10px)" : "100%"};
    margin-left: ${p => belowBreakpointOrEqual(p, "tablet") ? -5 : 0}px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    position: relative;
    ${p => p.shadowed && !belowBreakpointOrEqual(p, "tablet") && "box-shadow: 0px -5px 30px -20px #313639;"}
`;
const Organisation = styled.div`
    position: absolute;
    top: -20px;
    left: 100px;
    z-index: 20;
    color: ${p => lightenColor(p, "@darkGrey", 150)};
    width: calc(100% - 100px);
    font-size: smaller;
`;
const Poster = styled.div<{ hasOrganisation: boolean }>`
    position: absolute;
    top: ${p => p.hasOrganisation ? -35 : -20}px;
    left: 100px;
    z-index: 20;
    color: ${p => getColor(p, "@darkGrey")};
    font-size: 1.1em;
    font-weight: bolder;
    width: calc(100% - 100px);
`;
const ButtonBar = styled.div`
    background: ${p => getColor(p, "@veryLightGrey")};
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    button>div{margin: 0;}
    box-shadow: 0 6px 8px -6px #000;
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 10px;
`;
const ButtonBarBtnContainer = styled.div<{ active: boolean }>`
    background: ${p => p.active ? fadeColor(p, "@darkGrey", 8) : getColor(p, "@veryLightGrey")};
    button{
        color: ${p => getColor(p, "@darkGrey")};
    }
    &:hover{
        background: ${p => p.active ? fadeColor(p, "@darkGrey", 12) : fadeColor(p, "@darkGrey", 8)};
    }
`;
const PostTime = styled.div`
    padding-left: 90px;
    font-size: smaller;
`;
const BtnIndicator = styled.div`
float: left;
button>div>div>div>div{
        padding-left: 0;
}
`;
export const MoreInfoContainer = styled.div`
    background: ${p => getColor(p, "@veryLightGrey")};
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    box-shadow: 0 6px 8px -6px #000;
    button>div{margin: 0;}
`;
export const MoreInfoContainerHeader = styled.div`
    font-weight: bolder;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid ${p => getColor(p, "@middleLightGrey")};
    width: 100%;
    padding-left: 10px;
`;
export const MoreInfoContainerItems = styled.div`
    width: 100%;
    padding: 10px;
    background: #fff;
`;
const PostContainer = styled.div<{ background: string, gradColor1: string, gradColor2: string, showCarbon: boolean }>`
        color: ${p => getColor(p, "@mainBackground")};
        width: calc(100% + 0px);
        box-shadow: 0 6px 8px -6px #000;
        transform: translateX(-0px);
        z-index: 50;
        padding: 10px;
        line-height: 20px;
        position: relative;
        margin-top: 60px;
        background-color: ${p => p.background === "" ? getColor(p, "@darkGrey") : p.background};
       ${p => p.background === "" && `
        background-image: ${p.showCarbon ? `linear-gradient(120deg, ${p.gradColor1 === "" ? getColor(p, "@accent") : p.gradColor1}, ${p.gradColor2 === "" ? "transparent" : "transparent"}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(to bottom, ${p.gradColor2 === "" ? "rgb(5, 5, 5)" : lightenColor(p, p.gradColor2, 120)}, ${p.gradColor2 === "" ? "rgb(20, 20, 20)" : lightenColor(p, p.gradColor2, 170)})` :
            (p.gradColor1 === "" && p.gradColor2 === "") ? `none` :
                `
                linear-gradient(120deg, ${p.gradColor1}, ${p.gradColor2})
                `};
            background-size:cover, 10px 10px, 10px 10px, 10px 5px;
            background-position: center center,0px 0px, 5px 5px, 0px 0px;
            `}
`;
const PostContainerStandards = styled.div<{ background: string, gradColor1: string, gradColor2: string, showCarbon: boolean }>`
        width: calc(100% + 0px);
        z-index: 50;
        //border-top: 1px solid #fff;
        background-color: ${p => p.background === "" ? getColor(p, "@darkGrey") : p.background};
        display: grid;
        grid-template-columns: repeat(4, 1fr);
       ${p => p.background === "" && `
        background-image: ${p.showCarbon ? `linear-gradient(120deg, ${p.gradColor1 === "" ? getColor(p, "@accent") : p.gradColor1}, ${p.gradColor2 === "" ? "transparent" : "transparent"}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(45deg, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 25%, transparent 25%, transparent 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)} 75%, ${p.gradColor2 === "" ? "black" : getColor(p, p.gradColor2)}), 
            linear-gradient(to bottom, ${p.gradColor2 === "" ? "rgb(5, 5, 5)" : lightenColor(p, p.gradColor2, 120)}, ${p.gradColor2 === "" ? "rgb(20, 20, 20)" : lightenColor(p, p.gradColor2, 170)})` :
            (p.gradColor1 === "" && p.gradColor2 === "") ? `none` :
                `
                linear-gradient(120deg, ${p.gradColor1}, ${p.gradColor2})
                `};
            background-size:cover, 10px 10px, 10px 10px, 10px 5px;
            background-position: center center,0px 0px, 5px 5px, 0px 0px;
            `}
`;
const AudioContainer = styled.div`
        width: 100%;
        padding: 20px;
        background-color: ${p => getColor(p, "@darkGrey")};
        background-image: linear-gradient(120deg, ${p => fadeColor(p, "@accentBlack", 70)}, ${p => fadeColor(p, "@accentBlack", 30)}), 
            linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), 
            linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), 
            linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
        background-size:cover, 10px 10px, 10px 10px, 10px 5px;
        background-position: center center,0px 0px, 5px 5px, 0px 0px;
        audio{
            outline: none;
            width: 100%;
        }
`;
const MediaNoMargin = styled.div`
    margin-bottom: -5px;
`;
const TopRightFlag = styled.div`
    position: absolute;
    right: 10px;
    top: 32px;
    z-index: 500;
`;
const PostContentHeadlineFlag = styled.div`
    float: right;
    margin-top: -3px;
    margin-left: 5px;
`;
const PostContentHeadlineText = styled.div`
    float: right;
    margin-top: 0px;
    font-weight: 400;
    font-size: 0.8em;
    margin-left: 10px;
`;

const PostContentHeadline = styled.div`
    padding: 10px;
    font-size: larger;
    font-weight: bolder;
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};       
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
    padding-top: 15px;
`;

const PostContent = styled.div`
    padding: 10px;
    width: 100%;
    background: ${p => getColor(p, "@mainBackground")};       
    border-left: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-right: 1px solid ${p => getColor(p, "@middleLightGrey")};
`;
const PostContainerImage = styled.div`
        position: absolute;
        width: 80px;
        height: 80px;
        bottom: 10px;
        left: 10px;
        border-radius: 40px;
        background-color: ${p => getColor(p, "@veryLightGrey")};
        background-image: linear-gradient(-30deg, ${p => getColor(p, "@veryLightGrey")}, ${p => getColor(p, "@mainBackground")});
        padding: 5px;
        z-index: 10;
        box-shadow: -6px -3px 6px -5px ${p => getColor(p, "@lightGrey")}, 3px 6px 8px -6px ${p => getColor(p, "@darkGrey")};
`;

const LuxuryContainer = styled.div<{ right: number }>`
    position: absolute;
    right: ${p => p.right}px;
    top: -7px;
    z-index: 0;
`;

const Standard = styled.div<{ selected: boolean }>`
    //background-color: ${p => p.selected ? "rgba(0,0,0,0.2)" : "transparent"};
    color: ${p => p.selected ? getColor(p, p.theme.colors.mainBackground) : fadeColor(p, getColor(p, p.theme.colors.mainBackground), 60)};
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-weight: ${p => p.selected ? "bold" : "100"};
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? "0.65rem" : belowBreakpointOrEqual(p, "tablet") ? "0.75rem" : p.selected ? "0.85rem" : "0.75rem"};
    letter-spacing: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : "1px"};
    text-align: center;
    text-transform: uppercase;
    position: relative;
    text-shadow: ${p => p.selected ? "1px 3px 2px #000" : "unset"};
`;

const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

const mapper = (state: ApplicationState) => ({
    contentLanguage: state.contentLanguage,
    userGroups: state.userGroups,
    rerender: state.feedState.rerender,
    user: state.user
});
export interface PorscheMomentPostMobileProps extends PostProps {
}

const PorscheMomentPostMobile = (p: PorscheMomentPostMobileProps) => {
    const { post, postChanged } = p;

    const { contentLanguage, user, rerender } = useAppSelector(mapper);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [showComments, setShowComments] = React.useState<boolean>(false);
    const [showLikes, setShowLikes] = React.useState<boolean>(false);
    const [deleteConfirm, setDeleteConfirm] = React.useState<boolean>(false);
    const [mediaArray, setMediaArray] = React.useState<MediaDownloadDto[]>([]);
    const [isBookmarked, setIsBookmarked] = React.useState<boolean>(false);
    const [sliderWidth, setSliderWidth] = React.useState<number>();

    React.useEffect(() => {
        setIsBookmarked(post.isBookmarked)
        const l = async () => {
            const m: MediaDownloadDto[] = [];
            const pm = post.type === "PorscheMomentPost" ? post as PorscheMomentPostDownloadDto : undefined;
            if (pm) {
                if (pm.media)
                    m.push(pm.media);
                if (pm.media1)
                    m.push(pm.media1);
                if (pm.media2)
                    m.push(pm.media2);
                if (pm.media3)
                    m.push(pm.media3);
                if (pm.media4)
                    m.push(pm.media4);
                setMediaArray(m);
            }

        }
        l();
    }, [post]);

    React.useEffect(() => {
        const t = async () => {
            const tPost = await getOrTranslatePostLanguageStrings(post, contentLanguage);
            if (tPost) {
                setShowLikes(false);
                setShowComments(false);
                postChanged(tPost);
            }
        }
        t();
    }, [post, contentLanguage, postChanged]);

    const media0Type = mediaArray.length !== 0 ? mediaArray[0].mediaType : undefined;
    const media0uri = media0Type ? getUriFromLinkByName(mediaArray[0], "self") ?? "" : "";
    const type = post.type;
    const isExcitePost = type === "PorscheMomentPost";
    const isCommunityPost = type === "CommunityFeedPost";
    const avatar = getMediaLink(post, y => y.creator?.avatar);
    const pm = isExcitePost ? post as PorscheMomentPostDownloadDto : undefined;
    const headline = getTranslated(post.headlines, contentLanguage).text ?? "";
    const desc = getTranslated(post.descriptions, contentLanguage).text ?? "";
    const uri = getLink(post.media?.links, "self")?.uri;
    const mayDelete = getLink(post.links, "delete") !== undefined;
    const mayUpdate = getLink(post.links, "update") !== undefined;
    const fm = post.media ?? pm?.media ?? pm?.media1 ?? pm?.media2 ?? pm?.media3 ?? pm?.media4;
    const w = (fm?.width ?? 16) === 0 ? 16 : (fm?.width ?? 16);
    const h = (fm?.height ?? 9) === 0 ? 9 : (fm?.height ?? 9);
    const images = _.filter(_.map(mediaArray, m => ({ url: getUriFromLinkByName(m, "self") ?? "" })), f => f?.url !== undefined);




    const isLuxuryMoment = CurrentSettings.showLuxuryRibbon && pm?.isLuxury;
    const color = isCommunityPost ? "#FFF" : "#FFF";
    const background = isCommunityPost ? "" : "";
    const gradColor1 = isCommunityPost ? "#0061BD" : isLuxuryMoment ? "#D7A200" : "#D5001C";
    const gradColor2 = isCommunityPost ? "#001930" : isLuxuryMoment ? "#1b1400" : "#270005";
    const showCarbon = isCommunityPost ? true : true;
    const shadowed = isCommunityPost ? true : true;

    const setMediaContainerRef = React.useMemo(() => (contextRef: any) => {
        if (contextRef)
            erdUltraFast.listenTo({}, contextRef, (ele: HTMLElement) => {
                setSliderWidth(ele.offsetWidth);
            });
    }, []);

    const deletePost = () => {
        if (post)
            PostController.DeletePost({ postid: post.id },
                () => {
                    if (rerender)
                        rerender();
                },
                error => console.log(error)
            );
        setDeleteConfirm(false);
    };
    const onDelete = (mode: boolean) => () => {
        setDeleteConfirm(mode);
    };
    const commentCountChanged = (count: number) => {
        dispatch(Actions.updatePost({ ...post, commentCount: count }))
    }
    const onLike = (like: boolean) => async () => {
        const newPost = _.clone(post);
        newPost.likeCount += like ? 1 : -1;
        newPost.didLike = like;
        dispatch(Actions.updatePost(newPost));
        await ControllerHelper.singleCall({ id: post.id }, like ? PostInteraction.LikePost : PostInteraction.DislikePost);
        if (like === true)
            setShowLikes(true);
    }
    const onBookmark = (isBookmarked: boolean) => async () => {
        setIsBookmarked(isBookmarked);
        const newPost = _.clone(post);
        newPost.isBookmarked = isBookmarked;
        dispatch(Actions.updatePost(newPost));
        await ControllerHelper.singleCall({ postid: post.id }, isBookmarked ? BookmarkController.PostBookmark : BookmarkController.RemoveBookmark);
    }


    // const descLng = pm?.headlines[0].lng;
    // let flag = descLng ? (descLng.indexOf("-") > -1 ? descLng.split("-")[1] as any : descLng.toUpperCase() as any) : undefined;
    // if (flag === "EN") {
    //     flag = "GB";
    // }

    const descLng = pm && pm.headlines && pm.headlines.length > 0 ? pm.headlines[0].lng : undefined;
    let flag: any;
    if (descLng) {
        flag = descLng.indexOf("-") > -1 ? descLng.split("-")[1].toUpperCase() : descLng.toUpperCase();
        if (flag === "EN") {
            flag = "GB";
        }
    }

    let ctry = post?.organization1 as any;

    const showStandards = (_.findIndex(pm?.standards, s => s === "int") >= 0) || (_.findIndex(pm?.standards, s => s === "rel") >= 0) || (_.findIndex(pm?.standards, s => s === "exc") >= 0) || (_.findIndex(pm?.standards, s => s === "eff") >= 0);

    return (<ImgThemeConsumer>
        {(theme) => {
            return (
                <OuterContainer shadowed={shadowed} style={{ zIndex: 5 }}>
                    <PostContainer
                        background={background}
                        gradColor1={gradColor1}
                        gradColor2={gradColor2}
                        showCarbon={showCarbon}
                        style={{
                            zIndex: 5,
                        }}
                    >
                        {isLuxuryMoment && isExcitePost &&
                            <LuxuryContainer right={pm ? (((pm.responsibleUsers.length - 1) * 15) + 50) : 70}>
                                <Image src={feedTheme.luxuryRibbonUrl} width='100px' />
                            </LuxuryContainer>
                        }
                        <PostContainerImage>
                            <Image src={avatar ?? theme.emptyAvatarImageUrl} rounded />
                        </PostContainerImage>
                        <PostTime>
                            <DateTimeFns
                                iconSize={16}
                                marginTop={1}
                                date={post.created}
                                iconColor={color}
                                textColor={color}
                                dateFormat="P"
                                timeFormat='p'
                                fluid />
                        </PostTime>

                        {post?.creator?.organisation && <Organisation>{post?.creator?.organisation}</Organisation>}

                        {pm && _.filter(pm.responsibleUsers, r => r.id !== pm.creator?.id).length > 0 && _.map(pm.responsibleUsers, (r, i) => {
                            return (
                                <AvatarMouseOverContainer
                                    key={i}
                                    idx={i}
                                    onClick={gotoNamedRouteMethod("user", { id: r.id })}
                                >
                                    <Tooltip tooltipText={r.firstName + " " + r.secondName} noMargin notInline >
                                        <Image src={getMediaLink(r, u => u.avatar) ?? theme.emptyAvatarImageUrl} width="24px" rounded />
                                    </Tooltip>
                                </AvatarMouseOverContainer>
                            );
                        })
                        }
                        <Poster hasOrganisation={post?.creator?.organisation ? true : false}>
                            {post?.creator?.firstName + " " + post?.creator?.secondName ?? ""}
                        </Poster>


                    </PostContainer>
                    {uri && pm === undefined && <MediaContainer>
                        {uri.includes("images") && <Image src={uri} />}
                        {uri.includes("videos") && <video src={uri} width="100%" autoPlay={false} controls />}
                        {uri.includes("audios") && <AudioContainer><audio src={uri} controls crossOrigin="anonymous" controlsList="nodownload" /></AudioContainer>}
                    </MediaContainer>
                    }
                    {pm && <MediaContainer ref={setMediaContainerRef}>


                        {mediaArray.length === 1 ?
                            <>
                                {media0Type?.startsWith("image") && <MediaNoMargin><Media media={{ media: mediaArray[0] ?? {} as MediaDownloadDto, refName: "self" }} width="100%" /></MediaNoMargin>}
                                {media0Type?.startsWith("video") && <MediaNoMargin>
                                    <video
                                        width="100%"
                                        autoPlay={false}
                                        controls
                                        src={`${media0uri}#t=0.01`}
                                        typeof={media0Type}
                                        crossOrigin="anonymous"
                                        playsInline
                                        preload="metadata"
                                        style={{ outline: "none" }}
                                    />

                                </MediaNoMargin>}
                                {media0Type?.startsWith("audio") && <AudioContainer><audio src={media0uri} controls crossOrigin="anonymous" controlsList="nodownload" /></AudioContainer>}
                            </>
                            :
                            images.length > 0 && sliderWidth && <ImageSlider
                                width={sliderWidth}
                                height={h / w * sliderWidth}
                                showBullets
                                showNavs
                                images={images}
                            />
                        }
                    </MediaContainer>
                    }
                    {showStandards && CurrentSettings.showStandardsInNewMoments &&
                        <PostContainerStandards background={background}
                            gradColor1={gradColor1}
                            gradColor2={gradColor2}
                            showCarbon={showCarbon}
                            style={{
                                zIndex: 5,
                            }}>
                            <div><Standard selected={_.findIndex(pm?.standards, s => s === "int") >= 0}>Integrity</Standard></div>
                            <div><Standard selected={_.findIndex(pm?.standards, s => s === "rel") >= 0}>Relationship</Standard></div>
                            <div><Standard selected={_.findIndex(pm?.standards, s => s === "exc") >= 0}>Excitement</Standard></div>
                            <div><Standard selected={_.findIndex(pm?.standards, s => s === "eff") >= 0}>Efficiency</Standard></div>
                        </PostContainerStandards>
                    }
                    <PostContentHeadline>
                        {flag &&
                            <>
                                <PostContentHeadlineFlag>
                                    <Tooltip tooltipText={t("post language {{flag}}", { flag })} noMargin>
                                        <Flag name={flag} size={'18px'} />
                                    </Tooltip>
                                </PostContentHeadlineFlag>
                                <PostContentHeadlineText>
                                    {t("post language")}:
                                </PostContentHeadlineText>
                            </>
                        }
                        <SafeHTML html={headline} />
                    </PostContentHeadline>
                    <PostContent>
                        <SafeHTML html={desc ?? ""} />
                        {desc.includes("https://") && splitTextOnlyLastLink(desc, UrlComponent)}
                    </PostContent>
                    <ButtonBar>
                        <ButtonBarBtnContainer active={post.didLike || showLikes}>
                            <Button
                                kind="fullTransparentButton"
                                icon="heart"
                                floated='left'
                                iconColor={post.didLike ? "@accentRed" : "@accentBlack"}
                                onClick={onLike(!post.didLike)}
                                tooltip={{ tooltipText: post.didLike ? t("undo like") : t("like post"), position: "top" }}
                            />
                            {post.likeCount > 0 ?
                                <BtnIndicator>
                                    <Button
                                        kind="fullTransparentButton"
                                        content={post.likeCount.toString()}
                                        onClick={() => setShowLikes(t => !t)}
                                        tooltip={{ tooltipText: t("show likers"), position: "bottom" }}
                                    />
                                </BtnIndicator>
                                :
                                <div />
                            }
                        </ButtonBarBtnContainer>

                        <ButtonBarBtnContainer active={showComments}>
                            <Button
                                kind="fullTransparentButton"
                                icon="comment"
                                floated="left"
                                iconColor={"@darkGrey"}
                                onClick={() => setShowComments(c => !c)}
                                tooltip={{ tooltipText: t("show comments"), position: "top" }}
                            />
                            {post.commentCount > 0 ?
                                <BtnIndicator>
                                    <Button
                                        kind="fullTransparentButton"
                                        content={post.commentCount.toString()}
                                        onClick={() => setShowComments(c => !c)}
                                        tooltip={{ tooltipText: t("show comments"), position: "top" }}
                                    />
                                </BtnIndicator>
                                :
                                <div />
                            }
                        </ButtonBarBtnContainer>

                        <div>
                            {mayDelete &&
                                <>
                                    <Button
                                        floated="right"
                                        kind={deleteConfirm ? "secondary" : "transparentButton"}
                                        icon={p !== undefined && deleteConfirm ? "times" : "delete"}
                                        onClick={p !== undefined && deleteConfirm ? onDelete(false) : onDelete(true)}
                                        tooltip={{ tooltipText: p !== undefined && deleteConfirm ? t("cancel") : t("delete post"), position: p !== undefined && deleteConfirm ? "bottom" : "top" }}
                                    />
                                    {p !== undefined && deleteConfirm &&
                                        <Button
                                            floated="right"
                                            kind="primary"
                                            icon="check"
                                            onClick={deletePost}
                                            tooltip={{ tooltipText: t("yes, delete post"), position: "top" }}
                                        />
                                    }
                                </>
                            }
                            {mayUpdate && post?.type === "PorscheMomentPost" && !deleteConfirm &&
                                <Button
                                    floated="right"
                                    kind="transparentButton"
                                    icon="edit"
                                    onClick={gotoNamedRouteMethod("community_add_post", { id: post?.id })}
                                    tooltip={{ tooltipText: t("edit post"), position: "bottom" }}
                                />
                            }
                            {!deleteConfirm &&
                                <ButtonBarBtnContainer active={isBookmarked} style={{ float: "right" }}>
                                    <Button
                                        kind="fullTransparentButton"
                                        icon={isBookmarked ? "bookmark" : "bookmark outline"}
                                        onClick={onBookmark(!isBookmarked)}
                                        iconColor={"@darkGrey"}
                                        tooltip={{ tooltipText: isBookmarked ? t("unbookmark post") : t("bookmark post"), position: "top" }}
                                    />
                                </ButtonBarBtnContainer>
                            }
                        </div>
                    </ButtonBar>
                    <PMLikes showLikes={showLikes} user={user} id={post.id} setShowLikes={setShowLikes} likeCount={post.likeCount} />

                    {showComments &&
                        <MoreInfoContainer>
                            <MoreInfoContainerHeader>
                                {t("latest comments")}:
                                <Button kind="secondary" icon="times" floated='right' onClick={() => setShowComments(false)} tooltip={{ tooltipText: t("close comments") }} />
                            </MoreInfoContainerHeader>
                            <MoreInfoContainerItems style={{ padding: 0 }}>
                                <CommentsRatingsView
                                    post={post}
                                    onCountChanged={commentCountChanged}
                                    noLeftBorder
                                    getIncoming={PostInteraction.GetPostComments}
                                    addCommentRating={PostInteraction.AddPostComment}
                                    onDeleteComment={PostInteraction.DeletePostComment}
                                    onSubmitComment={PostInteraction.UpdatePostComment}
                                    inline={false}
                                />
                            </MoreInfoContainerItems>
                        </MoreInfoContainer>
                    }

                    {ctry &&
                        <TopRightFlag>
                            <Tooltip tooltipText={t("post organization1 {{flag}}", { flag: ctry })} noMargin position='top'>
                                <Flag name={ctry} size={'18px'} />
                            </Tooltip>
                        </TopRightFlag>
                    }
                </OuterContainer>
            );
        }
        }
    </ImgThemeConsumer >
    );
}
export default PorscheMomentPostMobile;