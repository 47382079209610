import { belowBreakpoint, belowBreakpointOrEqual, fadeColor, fadeIn, getColor, keyframes, lightenColor, styled, TextArea } from 'imaginarity-react-ui';
import { globalSettings } from 'services/Config';

export const extraFadeIn = keyframes`
    from {
        opacity:0.0; 
        transform: scale(99%);
        filter: grayscale(100%) drop-shadow(0px 0px 0px rgba(49, 54, 57, 0));
    }
    to {
        opacity:1; 
        transform: scale(100%);
        filter: grayscale(0%) drop-shadow(6px 6px 8px rgba(49, 54, 57, 0.8));
        }
`;
export class BestPracticeSC {
    public static Container = styled.div`
    padding: 0;  
    padding-bottom:40px;
`;
    public static Label = styled.div<{ color?: string, input?: boolean }>`
    color: ${p => getColor(p, p.color ?? "@darkGrey")};
    font-weight: bolder;
    line-height: ${p => p.input ? "20px" : "1.5rem"};
    font-size: 0.9rem;
    padding-right: 5px;
    padding-top: ${p => p.input ? 10 : 0}px;
`;
    public static MissingElement = styled.span`
    color: ${p => getColor(p, p.theme.colors.accentRed)};
    font-weight: bolder;
    line-height: ${props => belowBreakpointOrEqual(props, "tablet") ? "unset" : "40px"};
    font-size:0.9rem;
    margin-right:10px;
    margin-bottom: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "unset"};
`;
    public static DateContainer = styled.div`
    //margin-left: -4px;
    //width: calc(100% + 18px);
    margin-bottom:-25px;
`;
    public static GridCheckbox = styled.div`
    display: grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 1fr"}; 

`;
    public static Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "min-content"}; 
    margin-bottom: 10px;
`;
    public static TemplateRolesGrid = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 50% 40px;
    grid-template-rows: 40px;
    margin-bottom: 2px;
    transition: 0.3s all ease-out;
    &:last-child{
        margin-bottom: 0px;
    }
    &:hover{
        color: ${p => getColor(p, p.theme.colors.accentBlack)};
        background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    }
`;

    public static RolesIcon = styled.div`
    grid-column: 1;  
    padding-top: 2px;  
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    &:hover{
        background: #EBE9E9;
    }
`;
    public static TemplatesIcon = styled.div`
    grid-column: 1;  
`;
    public static TemplateDelete = styled.div`
`;

    public static RolesUser = styled.div`
    grid-column: 2;
    line-height: 44px;
    padding-left: 10px;
    `;
    public static TemplateText = styled.div`
    line-height: 44px;
    padding-left: 10px;
    `;
    public static RolesRoles = styled.div`
    grid-column: 3;
    `;
    public static RolesDel = styled.div`
    grid-columns: 4;
    `;


    public static GridDateContainer = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "40px"};     
    -ms-grid-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "1fr 2fr"}; 
    -ms-grid-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "40px"}; 

    margin-bottom: 10px;
    margin-top: ${props => belowBreakpointOrEqual(props, "tablet") ? "-20px" : "-15px"}; 
`;
    public static GridCheckboxOuter = styled(BestPracticeSC.Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
`;
    public static GridCheckboxDropzone = styled(BestPracticeSC.Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
    grid-template-columns: ${p => belowBreakpointOrEqual(p, "desktop") ? "1fr" : "1fr 1fr"};
    -ms-grid-columns: ${p => belowBreakpointOrEqual(p, "desktop") ? "1fr" : "1fr 1fr"};
`;
    public static GridCheckboxOuterText = styled(BestPracticeSC.Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr;     
    -ms-grid-columns: 1fr; 
    -ms-grid-rows: 1fr; 
`;
    public static GridCol1Text = styled.div`
    width: 100%;
    grid-column: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
`;
    public static GridCol2Text = styled.div`
    margin-top:5px;
    width: 100%;
    grid-column: 1;
    grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    overflow: auto;
    .ql-snow .ql-tooltip.ql-flip{
        height: 70px;
        background-image: url('${p => globalSettings.editorToolTipImage}');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100%;
    }
    .ql-snow .ql-tooltip[data-mode=link]::before {
        content: "Link:";
    }
    .ql-snow .ql-tooltip.ql-editing input[type=text] {
        outline: none;
    }
`;
    public static InfoUploadContainer = styled.div`
    margin-top: 3px;
    width: 100%;
`;
    public static InfoMediaContainer = styled.div`
    margin-top: 13px;
    color: ${p => getColor(p, p.theme.colors.lightGrey)};
    width: 100%;
`;
    public static InfoUpload = styled.div`
    display: ${props => belowBreakpointOrEqual(props, "desktop") ? "inline-block" : "inline-block"};
    margin-top: ${props => belowBreakpointOrEqual(props, "desktop") ? "10px" : "3px"};
    line-height: ${props => belowBreakpointOrEqual(props, "desktop") ? "unset" : "16px"};
    margin-right: ${props => belowBreakpointOrEqual(props, "desktop") ? "10px" : "unset"};
    font-weight: bolder;
    font-size: ${props => belowBreakpointOrEqual(props, "desktop") ? "1em" : "0.75rem"};
`;
    public static InfoUploadFile = styled.div`
    display: ${props => belowBreakpointOrEqual(props, "desktop") ? "inline-block" : "block"};
    line-height: ${props => belowBreakpointOrEqual(props, "desktop") ? "unset" : "16px"};
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    font-size: ${props => belowBreakpointOrEqual(props, "desktop") ? "1em" : "0.8rem"};
    width: 100%;
    svg{
        stroke: ${p => getColor(p, p.theme.colors.mainForeground)};
    }
`;
    public static InfoUploadFileDelete = styled.div`
    float: right;
    margin: 0 20px 0 10px;
    cursor: pointer;
    svg{
        stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        &:hover{
            stroke: ${p => getColor(p, p.theme.colors.accentRed)};
        }
    }
`;
    public static Headline = styled.div`
    font-size:1.5rem; 
    line-height:40px;
    font-weight: bolder;
    margin-bottom:10px;
    width:100%;
`;
    public static BPCContainer = styled.div`
    width:100%;
    cursor:default;
    overflow-x: hidden;
    position: relative;
    min-height:80px;
`;
    public static ContainerTitle = styled.div`
    width:100%;
    font-weight: bolder;
    margin-top:20px;
`;
    public static ContainerContent = styled.div`
    width:100%;
    margin-top:5px;
    min-height:460px;
`;
    public static ContainerContentGeneralSearch = styled.div`
    width:100%;
    margin-top:5px;
    
    input:focus{
        background: ${p => lightenColor(p, p.theme.backgroundColor, 98)} !important;
    }
    svg{
        stroke: ${p => getColor(p, p.theme.color)} !important;
        &:hover{
            stroke: ${p => getColor(p, p.theme.colors.hoverColor)} !important;
        }
    }
`;
    public static Hint = styled.div`
    position: absolute;
    top: 41px;
    left:0;
    color: ${p => getColor(p, p.theme.colors.accent)};
    font-size: 0.8rem;
    z-index: 2147483637;
    width: 100%;
    text-align: right;
    line-height: 20px;
    //border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    font-weight: 500;
    svg{
        stroke:${p => getColor(p, p.theme.colors.accent)};
        }
`;
    public static InfoLine = styled.div`
    font-size: 0.7rem;
    line-height:14px;
    padding: 0px 10px;
    padding-top: 6px;
    float: left;
    width: calc(100% - 160px);
    height: 40px;
    color: ${p => fadeColor(p, getColor(p, p.theme.colors.mainForeground), 80)};
`;
    public static InfoLineNew = styled.div`
    font-size: 0.95em;
    line-height:18px;
    padding: 0px;
    padding-top: 12px;
    padding-bottom: 7px;
    width: 100%;
    color: ${p => fadeColor(p, getColor(p, p.theme.colors.mainForeground), 80)};
`;
    public static GridContainer = styled.div<{ showThreeColumns: boolean }>`
    display: ${p => p.showThreeColumns ? "grid" : "block"};
    display: ${p => p.showThreeColumns ? "-ms-grid" : "block"};
    grid-template-columns: auto;
    -ms-grid-columns: 1fr;
    position: relative;
    margin-top:40px;
`;
    public static Detail = styled.div`
    grid-column: 1;
`;
    public static IconHelper = styled.div`
    display: inline-block;
`;
    public static Title = styled.div`
    font-size: 1.2rem;
    font-weight: bolder;
    margin: 20px 0;
    line-height: ${props => belowBreakpointOrEqual(props, "tablet") ? "30px" : "40px"};
    min-height: 40px;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    border-left: 3px solid ${p => getColor(p, p.theme.colors.accent)};
    padding : 0 10px;
`;
    public static Ratings = styled.div`
    float: ${props => belowBreakpointOrEqual(props, "tablet") ? "unset" : "right"};
    display: ${props => belowBreakpointOrEqual(props, "tablet") ? "block" : "unset"};
    width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 10px)" : "auto"};
    margin-top:5px;
    margin-right:10px;
`;
    public static CommentsRatingsImplementations = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: auto auto auto;
    -ms-grid-columns: 1fr 1fr 1fr;
    margin-top: 10px;
`;
    public static CommentsContainer = styled.div`
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
    public static CommentsRatingsImplementationsContent = styled.div`
    grid-row: 2;
    grid-column: 1 / span 3;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-bottom:30px;
    margin-top: 0px;
`;
    public static GalleryContainer = styled.div<{ imageCount: number }>`
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: ${p => p.imageCount === 1 ? "1fr" : p => p.imageCount === 2 ? "1fr 1fr" : "2fr 1fr 1fr"};
    -ms-grid-columns: ${p => p.imageCount === 1 ? "1fr" : p => p.imageCount === 2 ? "1fr 1fr" : "2fr 1fr 1fr"};
    grid-template-rows: ${p => p.imageCount > 2 ? "1fr 1fr" : "1fr"};
    -ms-grid-rows: ${p => p.imageCount > 2 ? "1fr 1fr" : "1fr"};
    background: ${p => getColor(p, "@veryLightGrey")};
    background-image: url('https://cdn.imaginarity.com/public/images/postBG.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    overflow: hidden;
    padding: 15px 10px 0px 15px;
`;
    public static ContentContainer = styled.div`
    padding: 0;
`;
    public static GCImage1 = styled.div<{ noScale: boolean, backgroundCover: boolean }>`
    grid-row-start: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    grid-column-start:1;
    -ms-grid-column: 1;
    grid-column: 1;
    grid-column-end:2;
    padding-right: ${props => props.noScale ? "0px" : "2%"};
    transition: 0.1s all ease-out;
    img, video, audio{
        object-fit: ${props => props.backgroundCover ? "cover" : "contain"};
        width:100%;
        height:100%;
        max-height:302px;
    }
`;
    public static GCImage2 = styled.div<{ noScale: boolean, backgroundCover: boolean }>`
    grid-row-start:1;
    -ms-grid-row: 1;
    grid-row: 1;
    grid-column-start:2;
    -ms-grid-column: 2;
    grid-column: 2;
    grid-column-end:3;
    transform-origin: top left;
    transform: ${p => p.noScale ? "scale(1)" : "scale(0.98) translateY(-2%)"};
    img, video, audio{
        object-fit: ${props => props.backgroundCover ? "cover" : "contain"};
        width:100%;
        height: 100%;
        max-height: ${p => p.noScale ? "302px" : "150px"};
        margin-top: ${p => p.noScale ? "0px" : "3px"};
    }
`;
    public static GCImage3 = styled.div<{ backgroundCover: boolean }>`
    grid-row-start:1;
    -ms-grid-row: 1;
    grid-row: 1;
    grid-row-end:2;
    grid-column-start:3;
    -ms-grid-column: 3;
    grid-column: 3;
    grid-column-end:4;
    transform-origin: top right;
    transform: ${props => belowBreakpointOrEqual(props, "tablet") ? "scale(0.98) translateY(-8%)" : "scale(0.98) translateY(-2%)"};
    img, video, audio{
        object-fit: ${props => props.backgroundCover ? "cover" : "contain"};
        width:100%;
        height: 100%;
        max-height: 150px;
        margin-top: 3px;
    }
`;
    public static GCImage4 = styled.div<{ backgroundCover: boolean }>`
    grid-row-start:2;
    -ms-grid-row: 2;
    grid-row: 2;
    grid-row-end:3;
    grid-column-start:2;
    -ms-grid-column: 2;
    grid-column: 2;
    grid-column-end:3;
    transform-origin: bottom left;
    transform: ${props => belowBreakpointOrEqual(props, "tablet") ? "scale(0.98) translateY(-8%)" : "scale(0.98) translateY(-2%)"};
    img, video, audio{
        object-fit: ${props => props.backgroundCover ? "cover" : "contain"};
        width:100%;
        height: 100%;
        max-height: 150px;
    }
`;
    public static GCImage5 = styled.div<{ backgroundCover: boolean }>`
    grid-row-start:2;
    -ms-grid-row: 2;
    grid-row: 2;
    grid-row-end:3;
    grid-column-start:3;
    -ms-grid-column: 3;
    grid-column: 3;
    grid-column-end:4;
    transform-origin: bottom right;
    transform: ${props => belowBreakpointOrEqual(props, "tablet") ? "scale(0.98) translateY(-8%)" : "scale(0.98) translateY(-2%)"};
    img, video, audio{
        object-fit: ${props => props.backgroundCover ? "cover" : "contain"};
        width:100%;
        height: 100%;
        max-height: 150px;
    }
`;
    public static SanitizeContainer = styled.div`
    ol, ul {
        padding-left: ${p => p.theme.editor.listPaddingLeft};
        font-size: ${p => p.theme.editor.listFontSize};
        font-weight: ${p => p.theme.editor.listFontWeight};
    }
    h1 {
        font-size: ${p => p.theme.editor.h1.fontSize};
        line-height: ${p => p.theme.editor.h1.lineHeight}em;
    }
    h2 {
        font-size: ${p => p.theme.editor.h2.fontSize};
        line-height: ${p => p.theme.editor.h2.lineHeight}em;
    }
    h3 {
        font-size: ${p => p.theme.editor.h3.fontSize};
        line-height: ${p => p.theme.editor.h3.lineHeight}em;
    }
    h4 {
        font-size: ${p => p.theme.editor.h4.fontSize};
        line-height: ${p => p.theme.editor.h4.lineHeight}em;
    }
    h5 {
        font-size: ${p => p.theme.editor.h5.fontSize};
        line-height: ${p => p.theme.editor.h5.lineHeight}em;
    }
    h6 {
        font-size: ${p => p.theme.editor.h6.fontSize};
        line-height: ${p => p.theme.editor.h6.lineHeight}em;
    }
    p {
        line-height: ${p => p.theme.editor.p.lineHeight}em;
        color: ${p => p.theme.colors.mainForeground};
        font-size: ${p => p.theme.editor.fontSize};
    }
    a:link { 
        color:${p => p.theme.colors.accent}; 
        font-weight: 600;
    }
    a:visited { 
        font-weight: 600;
        color: ${p => lightenColor(p, "@accent", 70)};
    }
`;
    public static Checkboxes = styled.div`
    display: grid;
    grid-template-columns: 1fr; 
    svg{
        margin-top: 5px;
        }
    *{
        stroke: ${p => p.theme.colors.mainBackground};
    }
`;
    public static Practice = styled.div<{ color: string }>`
    background-color: ${p => p.color};
    box-shadow: 0 6px 8px -8px #000;
    margin-bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "40px" : "10px"};
    margin-top: ${p => belowBreakpointOrEqual(p, "mobile") ? "20px" : "20px"};
    min-height: calc(160px + 4.4rem);
    width: 100%;
    position: relative;
    transition: 0.1s all ease-out;
`;
    public static SpacerIconMobile = styled.div`
    position: absolute;
    left: 10px;
    top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: ${p => fadeColor(p, getColor(p, p.theme.colors.mainBackground), 50)};
    box-shadow: 0 -6px 8px -6px ${p => getColor(p, p.theme.colors.accentBlack)};
    padding: 4px 7px;
    z-index:1;
`;
    public static FilterInfoBox = styled.div`
    margin-top: 40px;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 10px;
    min-height: 60px;
    line-height: 40px;
    font-weight: bolder;
`;
    public static SearchOnMobile = styled.div`
    margin-left: -5px;
    margin-top: 10px;
    min-height: 50px;
    display: block;
    width: calc(100% + 10px);
`;
    public static ImplementedElement = styled.div<{ rowColor: boolean, hoverable: boolean }>`
    width: 100%;
    min-height:60px;
    position: relative;
    background: ${p => p.rowColor ? "rgba(0,0,0,0.02)" : getColor(p, "@mainBackground")};
    border-bottom: 1px solid ${props => getColor(props, "@veryLightGrey")};
    padding:10px;
    &:hover{
        background: ${p => p.hoverable ? getColor(p, "@veryLightGrey") : undefined};
    }
`;
    public static ImplementedElementHead = styled.div`
    width: 100%;
    height:60px;
    position: relative;
`;
    public static ImageContainer = styled.div`
    position: absolute;
    top: ${props => belowBreakpointOrEqual(props, "tablet") ? "0px" : "10px"};
    left: ${props => belowBreakpointOrEqual(props, "tablet") ? "0px" : "10px"};
    width: 40px;
    height: 40px;
`;
    public static OrganisationContainer = styled.div`
    position: absolute;
    top: 12px;
    left: ${props => belowBreakpointOrEqual(props, "tablet") ? "50px" : "70px"};
    width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 50px)" : "unset"};
    height: 20px;
    font-weight: bolder;
`;
    public static NameContainer = styled.div`
    position: absolute;
    top: 30px;
    left: ${props => belowBreakpointOrEqual(props, "tablet") ? "80px" : "100px"};
    width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 50px)" : "calc(100% - 70px - 180px)"};
    height: 20px;
    font-weight: bolder;
    color: ${props => getColor(props, "@accent")};
`;
    public static FlagContainer = styled.div`
    position: absolute;
    top: 26px;
    left: ${props => belowBreakpointOrEqual(props, "tablet") ? "50px" : "70px"};
`;
    public static BPIDateContainer = styled.div`
    position: absolute;
    top: ${props => belowBreakpointOrEqual(props, "tablet") ? "-5px" : "10px"};
    right: ${props => belowBreakpointOrEqual(props, "tablet") ? "unset" : "10px"};
    left: ${props => belowBreakpointOrEqual(props, "tablet") ? "50px" : "unset"} ;
    width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 50px)" : "180px"};
    height: 20px;
    text-align: ${props => belowBreakpointOrEqual(props, "tablet") ? "left" : "right"};
    font-size: ${props => belowBreakpointOrEqual(props, "tablet") ? "0.8em" : "1em"};
`;
    public static ImplementedElementComment = styled.div`
    width: ${props => belowBreakpointOrEqual(props, "tablet") ? "calc(100% - 10px)" : "calc(100% - 60px)"};
    margin-left: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "70px"};
    border-left: 3px solid ${props => getColor(props, "@accent")};
    padding: 0 10px;
`;
    public static BPIFContainer = styled.div`
    margin: 20px;
    padding: 10px;
    background: ${props => getColor(props, "@veryLightGrey")};
    border: 1px solid ${props => getColor(props, "@lightGrey")};
`;
    public static Orga = styled.div`
    margin: 0px;
    width: calc(100% - 20px);
    margin-left:10px;
`;
    public static Name = styled.div`
    margin: 10px;
`;
    public static ImpDate = styled.div`
    margin-left:10px;
    width: calc(100% - 20px);
`;
    public static DatePickerDiv = styled.div`
    //width: calc(100% + 18px);
    //margin-left: -4px;
`;
    public static Lang = styled.div`
    margin-bottom: 10px;
    width: calc(100% - 20px);
    margin-left:10px;
`;
    public static Comment = styled.div`
    margin: 10px;
`;
    public static BPIFLabel = styled.div`
    font-weight: bolder;
    line-height:1.5rem;
    font-size:0.9rem;
`;
    public static Buttons = styled.div`
    width: calc(100% - 20px);
    margin-top:30px;
    margin-left:10px;
    height:40px;
`;
    public static CommentButtonContainer = styled.div<{ color: string }>`
    display: block;
    padding-top:1rem;
    padding-bottom:1rem;
    padding-left:1rem;
    padding-right:1rem;
    width: 100%;
    border-left:3px solid ${props => props.color};
`;

    public static CommentBox = styled.div<{ bgColor: string }>`
    display: block;
    width: 100%;
    height:80px;
    background: ${props => fadeColor(props, getColor(props, props.bgColor), 3)};
    border: 1px solid ${props => getColor(props, props.bgColor)};
    position: relative;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;

    public static CommentBoxLeft = styled.div`
    position: absolute;
    top:0;
    left: 0;
    width: calc(100% - 40px);
    height:80px;
    padding:10px;
`;
    public static CommentBoxRight = styled.div<{ bgColor: string }>`
    position: absolute;
    top:0;
    right: 0;
    width:40px;
    background: ${props => fadeColor(props, getColor(props, props.bgColor), 5)};
    height:80px;
`;
    public static CommentTextArea = styled(TextArea)`
    height:60px;
    font-size:0.8rem;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    font-weight: 400;
    padding:0;
`;
    public static RatingSelectContainer = styled.div<{ color: string }>`
    display: block;
    svg{
        stroke: ${props => getColor(props, props.color)};
        fill: ${props => fadeColor(props, getColor(props, props.color), 50)};
        &:hover{
            stroke: ${props => getColor(props, props.color)};
            fill: ${props => fadeColor(props, getColor(props, props.color), 80)};
        }
    }
`;
    public static SUContainer = styled.div`
    width: 100%;
    margin-top:10px;
    display: grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "300px 1fr"};
`;
    public static SUTitle = styled.div`
    //width: ${props => belowBreakpointOrEqual(props, "tablet") ? "unset" : "200px"};
    font-weight: bolder;
    float: ${props => belowBreakpointOrEqual(props, "tablet") ? "unset" : "left"};
    display: ${props => belowBreakpointOrEqual(props, "tablet") ? "block" : "unset"};
`;
    public static SUData = styled.div`
    width: 100%; //${props => belowBreakpointOrEqual(props, "tablet") ? "100%" : "calc(100% - 200px)"};
    padding: ${props => belowBreakpointOrEqual(props, "tablet") ? "0px" : "0 10px"};
    display: ${props => belowBreakpointOrEqual(props, "tablet") ? "block" : "inline-block"};
    margin-top: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "unset"};
`;
    public static SUElement = styled.div`
    margin-right:5px;
    display: inline-block;
    margin-left:10px;
`;
    public static BPRSanitizeContainer = styled.div`
    h1, h2, h3, h4 {
        font-size: 1em;
        line-height: 1em;
        float: left;
        padding: 0px;
        margin: 2px 10px 0 0;
    } 
    ul, ol, li {
        font-size: 1em;
        line-height: 1em;
        display: inline-block;
        padding: 0px;
        margin: 2px 10px 0 0;
    }    
    p {
        display: inline-block;
        font-size: 1em;
    }
`;
    public static BPPostContainerHidden = styled.div`
    right: 0px;
    svg{
        stroke: ${props => getColor(props, props.theme.colors.accentRed)};
    }
`;
    public static CommentCount = styled.div<{ show: boolean }>`
    float: left;
    margin-top: -4px;
    font-size: 0.65rem;
    font-weight: lighter;
    margin-right:10px;
    text-align: right;
    opacity: 0.7;
    visibility: ${props => props.show ? "hidden" : "visible"};
`;

    public static PostContainer = styled.div<{ hidden: boolean }>`
        display: grid;
        display: -ms-grid;
    ${props => belowBreakpoint(props, "desktop") ? `
        grid-template-columns: 130px 1fr;
        -ms-grid-columns: 130px 1fr;
        grid-template-rows: ${props.theme.content.width / 16 * 9}px 20px 40px 40px calc(4.4rem + 20px) 40px 40px;
        -ms-grid-rows: ${props.theme.content.width / 16 * 9}px 20px 40px 40px calc(4.4rem + 20px) 40px 40px;
        grid-template-areas: 'image image' 'moreImage moreImage' 'title title' 'user user' 'content content' 'rating space' 'info button';
    ` : `
        grid-template-columns: 177.77px 1fr 240px;
        -ms-grid-columns: 177.77px 1fr 240px;
        grid-template-rows: 40px 20px 40px calc(4.4rem + 20px) 40px;
        -ms-grid-rows: 40px 20px 40px calc(4.4rem + 20px) 40px;
        grid-template-areas: 'image title button' 'image title space' 'image user user' 'moreImage content content' 'rating info info';
    `}
    gap: 0px 0px;
    border-right: 3px solid ${p => p.hidden ? getColor(p, "@accent") : "none"};
    border-bottom: 3px solid ${p => belowBreakpointOrEqual(p, "tablet") ? getColor(p, "@accent") : "none"};
    position: relative;
`;


    public static PCImage = styled.div`
    grid-area:  image;
    background: ${p => getColor(p, "@darkGrey")};
    background-image: url('https://cdn.imaginarity.com/public/images/postBG.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
        width:100%;
        height:  ${p => belowBreakpoint(p, "desktop") ? "100%" : "100px"};
        overflow: hidden;
    img{
        object-fit: contain;
        animation: ${extraFadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
        width:100%;
        height: ${p => belowBreakpoint(p, "desktop") ? (p.theme.content.width / 16 * 9) + "px" : "100px"}; 
        cursor: pointer;
    }
    video{
        object-fit: contain;
        animation: ${extraFadeIn} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
        width:100%;
        height: ${p => belowBreakpoint(p, "desktop") ? (p.theme.content.width / 16 * 9) + "px" : "100px"}; 
        cursor: pointer;
    }
`;
    public static PCTitle = styled.div<{ hidden: boolean }>`
    color: ${p => p.hidden ? getColor(p, "@accent") : getColor(p, "@mainForeground")};
    grid-area: title;
    font-size: ${props => belowBreakpointOrEqual(props, "tablet") ? "0.9rem" : "1rem"};
    font-weight: bolder;
    padding-left: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "20px"};
    padding-top: ${props => belowBreakpointOrEqual(props, "tablet") ? "5px" : "5px"};
    padding-right: ${props => belowBreakpointOrEqual(props, "tablet") ? "5px" : "0px"};
    overflow: hidden;
    word-wrap: normal;
    -ms-overflow-style: none;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    line-clamp: 2;
    cursor: pointer;
    line-height: ${props => belowBreakpointOrEqual(props, "tablet") ? "17px" : "26px"};
    &:hover{
        color: ${p => getColor(p, "@accent")};
    }
`;
    public static PCButton = styled.div`
    grid-area: button;
    padding-top: ${props => belowBreakpoint(props, "desktop") ? "0px" : "10px"};
    padding-right: ${props => belowBreakpoint(props, "desktop") ? "0px" : "10px"};
    background:${p => belowBreakpointOrEqual(p, "tablet") ? getColor(p, "@darkGrey") : "unset"};
    
`;
    public static PCSpace = styled.div`
    grid-area: space;
    text-align: right;
    padding-right: ${props => belowBreakpoint(props, "desktop") ? "0px" : "8px"};
    padding-top: ${props => belowBreakpoint(props, "desktop") ? "5px" : "10px"};
    background:${props => belowBreakpoint(props, "desktop") ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)"};
    border-top: ${props => belowBreakpoint(props, "desktop") ? "1px solid rgba(0,0,0,0.06)" : "none"};
`;
    public static PCUser = styled.div`
    grid-area: user;
    align-items: center;
    margin-left: ${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "20px"};
    margin-right: 10px;
    padding-top: 10px;
    border-bottom: ${props => belowBreakpoint(props, "desktop") ? "none" : "1px solid rgba(0,0,0,0.06)"};
`;

    public static PCAvatar = styled.span`
    width:20px;
    height:40px;
    float: left;
    margin-right:5px;
    `;
    public static PCName = styled.span`
    height:40px;
    `;
    public static PCFlag = styled.span`
    /* float: right; */
    /* height:40px; */
`;
    public static PCmoreImage = styled.div`
    grid-area: moreImage;
    font-size: 0.65rem;
    text-align: ${props => belowBreakpoint(props, "desktop") ? "right" : "left"};
    padding-top: 0px;
`;
    public static PCContent = styled.div`
    grid-area: content;
    line-height:1.1rem;
    margin-top:10px;
    margin-left: ${props => belowBreakpointOrEqual(props, "tablet") ? "0px" : "10px"};
    padding: 0 10px;
    height: 4.4rem;
    overflow: hidden;
    word-wrap: normal;
    -ms-overflow-style: none;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    line-clamp: 4;
`;
    public static PCRating = styled.div`
    grid-area: rating;
    padding-top:10px;
    padding-left:10px;
    text-align: left;
    border-top: ${props => belowBreakpoint(props, "desktop") ? "1px solid rgba(0,0,0,0.06)" : "none"};
`;
    public static PCInfo = styled.div`
    grid-area: info;
    padding-top:10px;
    padding-left:${props => belowBreakpointOrEqual(props, "tablet") ? "10px" : "0px"};
    padding-right:10px;
    margin-left: ${props => belowBreakpointOrEqual(props, "tablet") ? "0px" : "20px"};
    background:${p => belowBreakpointOrEqual(p, "tablet") ? getColor(p, "@darkGrey") : "unset"};
    border-top: ${props => belowBreakpoint(props, "desktop") ? "none" : "1px solid rgba(0,0,0,0.06)"};
    color:${p => belowBreakpointOrEqual(p, "tablet") ? getColor(p, "@mainBackground") : getColor(p, "@darkGrey")};
    svg{
        stroke:${p => belowBreakpointOrEqual(p, "tablet") ? getColor(p, "@mainBackground") : getColor(p, "@darkGrey")};
    }
`;
    public static ModalContainer = styled.div`
    z-index: 1000;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.95);
`;
    public static ModalContainerMedia = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
        width: 95vw;
        height: 95vh;
        object-fit: contain;
        }
`;


    public static TagInputContainer = styled.div`
    
    .react-tagsinput {
        background-color: transparent;
        border: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
        padding: 0 5px;
        line-height: 43px;
        height: 43px;
    }
  
    .react-tagsinput--focused {
        border-color: ${props => getColor(props, props.theme.colors.middleLightGrey)};
    }
  
  .react-tagsinput-tag {
    background-color: ${props => getColor(props, props.theme.colors.veryLightGrey)};
    border-radius: 0px;
    border: none;
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    display: inline-block;
    font-family: inherit;
    font-size: 0.85em;
    height: 34px;
    line-height: 34px;
    padding: 0 12px 0 10px;
    margin: 0 5px;
    position: relative;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    margin-left: 10px;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
    
    
  }
  .react-tagsinput-tag a::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    width: 8px;
    height: 2px;
    background-color: ${props => getColor(props, props.theme.colors.darkGrey)};
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .react-tagsinput-tag a::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    width: 8px;
    height: 2px;
    background-color: ${props => getColor(props, props.theme.colors.darkGrey)};
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            &:hover{
    background-color: ${props => getColor(props, props.theme.colors.accent)};
    }
    }
  
  .react-tagsinput-input {
    background: transparent;
    width: unset;
    min-width: 80px;
    border: 0;
    color: ${props => getColor(props, props.theme.colors.mainForeground)};
  }
`;
    public static TemplateContainerGrid = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
`;
    public static TemplateContainer = styled.div`
        display: flex;
        flex-direction: column;
        height: 100px;
        overflow: auto;
`;
    public static TemplateContainerPreview = styled.div`
        border: 1px solid ${props => getColor(props, props.theme.colors.middleLightGrey)};
        height: 100px;
        width: 100%;
        position: relative;
        font-size: 0.85rem;
        padding-right: 10px;
`;
    public static TemplateContainerPreviewContent = styled.div`
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        right: 0;
        text-align: left;
        overflow: auto;
        font-size: 0.8rem;
        li{
            line-height: 0.95rem;
        }
`;
    public static TemplateContainerPreviewTitle = styled.div`
        position: absolute;
        color: ${props => getColor(props, props.theme.colors.lightGrey)};
        top: 5px;
        right: 10px;
        text-align: right;
        z-index: 100;
`;
}



