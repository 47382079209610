import { PostController, UserShortInfoDownloadDto } from 'collaboration-service';
import AutocompleteSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import { Icon } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { FeedSearchProps, createFetchPostFilter } from './FeedFilter';
import { FeedSC as T } from './FeedSC';

const mapper = (state: ApplicationState) => ({
    currentUser: state.user,
    rerender: state.feedState.rerender,
});

interface FeedSearchByKeywordsProps {
    setSearch: (search?: string) => void;   // is needed for reseting
    user?: UserShortInfoDownloadDto;
    search?: string;
}

const FeedSearchByKeywords = (p: FeedSearchByKeywordsProps) => {
    const { currentUser, rerender } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("feed");
    const { setSearch, user, search } = p;

    const [suggestions, setSuggestions] = React.useState<string[]>([]);

    const startSearch = React.useMemo(() => async (search?: string, user?: UserShortInfoDownloadDto) => {
        const feedSearchProps: FeedSearchProps = { search, user };
        if (rerender)
            rerender(feedSearchProps);
    }, [rerender]);

    const onSuggestionSelected = React.useMemo(() => (e: any, data: { suggestion: string }) => {
        if (search) {
            const prefixEnd = search.lastIndexOf(" ");
            let prefix = "";
            if (prefixEnd >= 0)
                prefix = search.slice(0, prefixEnd) + " ";
            startSearch(`${prefix}${data.suggestion}`, user);
        }
    }, [search, user, startSearch]);

    const onSuggestionsClearRequested = React.useMemo(() => () => {
        setSuggestions([]);
    }, []);

    const onSuggestionsFetchRequested = _.debounce((request: SuggestionsFetchRequestedParams) => {
        if (request.reason === "input-changed" || request.reason === "input-focused") {
            let terms = request.value.split(" ");
            terms = _.filter(terms, t => t.length > 0);
            const lastTerm = _.last(terms);
            if (lastTerm) {
                let filter = createFetchPostFilter(currentUser, undefined, undefined, undefined, undefined, undefined);
                PostController.SuggestPostTerm2({ term: lastTerm, filter },
                    suggestions => setSuggestions(suggestions));
            }
        }
    }, 250);

    const searchChanged = React.useMemo(() => (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => {
        setSearch(params.newValue);
    }, [setSearch]);

    const onEnterPressed = React.useMemo(() => () => {
        startSearch(search, user);
    }, [startSearch, search, user]);

    const onResetSearch = React.useMemo(() => () => {
        setSearch(undefined);
        startSearch(undefined, undefined);
    }, [startSearch, setSearch]);

    return (
        <>
            <SidebarTitleContainer icon="search" title={t("search by keyword")} />
            <T.Auto suggested={suggestions.length > 0 ? suggestions.length + 1 : 1}>
                <AutocompleteSearch
                    placeholder={t("search..")}
                    onSuggestionSelected={onSuggestionSelected}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    search={search ?? ""}
                    searchChanged={searchChanged}
                    suggestions={suggestions}
                    onEnterPressed={onEnterPressed}
                />
                {search !== "" && search !== undefined &&
                    <T.SearchDelete
                        onClick={search !== "" ? onResetSearch : undefined}
                    >
                        <Icon marginTop={9} name="times" size="20px" />
                    </T.SearchDelete>
                }
                <T.SearchIcon
                    onClick={search !== "" ? onResetSearch : undefined}
                >
                    <Icon marginTop={9} name="search" size="20px" />
                </T.SearchIcon>
            </T.Auto>
        </>
    )
}

export default FeedSearchByKeywords;
