import * as React from 'react';
import { PostContainerProps } from 'services/ApplicationState/ApplicationComponentsFactory';
import { PostContainerDiv, PostContainerDivOuter, PostContainerDivOuterBg } from './PostClasses';



export default function PostContainer(props: PostContainerProps) {
    return (
        <PostContainerDivOuter>
            <PostContainerDivOuterBg />
            <PostContainerDiv>
                {props.children}
            </PostContainerDiv>
        </PostContainerDivOuter>
    );
}
