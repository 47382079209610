import { appendPx, belowBreakpointOrEqual, fadeColor, fromThemeCreator, fromThemeCreators, getColor, keyframes, lightenColor, styled } from 'imaginarity-react-ui';
import { PodcastTheme, podcastTheme } from 'views/Podcast/PodcastMain';


const fromTheme = fromThemeCreators<PodcastTheme>("podcastTheme");
const selectFromTheme = fromThemeCreator<PodcastTheme>("podcastTheme");

const entrance = keyframes`
        from {transform: scale(3)  translateY(-50%); filter: blur(4px) grayscale(1); opacity: 0;}
        to {transform: scale(1) translateY(-50%); filter: blur(0px) grayscale(0); opacity: 1;}
  `;
const ani = keyframes`
from {
    color: ${podcastTheme.podcastAccent};
  }
  to {
    color: #FFF; 
  }
`;



export class PodcastSC {
    public static PodcastHomeMainContainer = styled.div`
        width:100%;
        text-align: left;
        margin: 24px 0;
        user-select: none;
        padding-bottom: 80px;
        position: relative;
`;
    public static PodcastBGimage = styled.div<{ width: string, right: string }>`
        width: ${p => p.width};
        aspect-ratio: 3 / 5;
        position: fixed;
        right:  ${p => p.right};
        bottom: -10px;
        z-index: 0;
        pointer-events: none;
        user-select: none;
        background-image: url('https://cdn.imaginarity.com/public/projectImages/podcastBgImage.svg');
        background-size: cover;
        background-position: center right;
        background-repeat: no-repeat;
`;
    public static PodcastHeaderBar = styled.div<{ lightMode?: boolean }>`
        width:100%;
        height: 120px;
        background: ${p => p.lightMode ?
            "linear-gradient(30deg, " + lightenColor(p, '@veryLightGrey', 99) + " 50px, " + lightenColor(p, getColor(p, "@mainBackground"), 100) + " 35%, " + lightenColor(p, getColor(p, "@middleLightGrey"), 120) + " calc(100% - 200px))"
            :
            belowBreakpointOrEqual(p, "tablet") ?
                "linear-gradient(30deg, " + lightenColor(p, '@darkerGrey', 60) + " 10px, " + fadeColor(p, getColor(p, "@darkGrey"), 50) + " 20%, #0D0D0D 50%)"
                :
                "linear-gradient(30deg, " + lightenColor(p, '@darkerGrey', 60) + " 50px, " + fadeColor(p, getColor(p, "@darkGrey"), 50) + " 20%, #0D0D0D calc(100% - 300px))"};
    
        margin: 24px 0 10px 0;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 120px 1fr;
        -ms-grid-columns: 120px 1fr;
        grid-template-rows: 60px 60px;
        -ms-grid-rows:  60px 60px;
        transition: 0.2s all ease-out;
        position: relative;
`;
    public static PodcastHeaderBarRightImage = styled.div`
        position: absolute;        
        ${p =>
            belowBreakpointOrEqual(p, "tablet") ? "background-image: none;" :
                // eslint-disable-next-line
                fromTheme.checkAndSet(p, x => x.headerRightImage.url, "background-image: url('${this}');")
        }
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        pointer-events:none;
        opacity: ${p =>
            // eslint-disable-next-line
            belowBreakpointOrEqual(p, "tablet") ? 0.1 : fromTheme.checkAndSet(p, x => x.headerRightImage.opacity, "${this}")
        };
        ${p =>
            // eslint-disable-next-line
            fromTheme.checkAndSet(p, x => x.headerRightImage.filter, "filter: ${this};")
        }
`;
    public static PodcastHeaderBarImage = styled.div<{ imageUrl: string }>`
        grid-column: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        svg{
            fill: rgba(255,255,255,0.2);
            stroke:#0D0D0D;
            filter: drop-shadow( 0px 0px 5px rgba(255, 255, 255, 0.5));
        }
`;
    public static PodcastHeaderBarRowTop = styled.div`
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        padding: 5px 10px;
`;
    public static PodcastHeaderBarSearch = styled.div<{ width: number }>`
        position: absolute;
        width: ${p => belowBreakpointOrEqual(p, "tablet") ? "calc(100% - 120px)" : appendPx(p.width)};
        //width: ${p => p.width}px;
        height: auto;
        top: 40px; 
        left: ${p => belowBreakpointOrEqual(p, "tablet") ? "120px" : "50%"};
        transform: translateX(${p => belowBreakpointOrEqual(p, "tablet") ? 0 : "-50%"});
        z-index: 100;
`;
    public static PodcastHeaderBarRowBottom = styled.div`
        grid-column: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        line-height: 35px;
        padding: 10px;
        padding-top: 15px;
        font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? 1.1 : 1.5}rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
    `;
    public static PodcastPostContainer = styled.div`
        width: 100%;
        position: relative;
        padding-bottom: 100%;
        background: ${props => getColor(props, selectFromTheme(props, t => t.postItem.backgroundColor, t => fadeColor(props, getColor(props, "@accentBlack"), 30)))};
        color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        transition: 0.2s all ease-out;
        cursor: pointer;
        transform-origin: center center;
        border-bottom: 1px solid transparent;
        &:hover{
            transform: scale(1.02);
            box-shadow: ${p => "0px 0px 60px -20px " + getColor(p, "@middleLightGrey") + ", 0px 6px 8px -10px " + getColor(p, "@mainBackground")};
            background: inherit;
            border-bottom: 1px solid transparent;
        }      
    `;
    public static PodcastPostContainerNew = styled.div`
    width: 100%;
    //max-height: 100%;
    aspect-ratio: 1 / 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content  1fr 40px ;
    background: ${props => getColor(props, selectFromTheme(props, t => t.postItem.backgroundColor, t => fadeColor(props, getColor(props, "@accentBlack"), 30)))};
    //background: red;
    color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
    transition: 0.2s all ease-out;
    cursor: pointer;
    transform-origin: center center;
    border-bottom: 1px solid transparent;
    position: relative;

    &:hover{
        transform: scale(1.01);
        box-shadow: ${p => "0px 0px 60px -20px " + getColor(p, "@middleLightGrey")};
        background: inherit;
        border-bottom: 1px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
    }      
`;

    public static PodcastCategoryContainer = styled(PodcastSC.PodcastPostContainer) <{ activated: boolean }>`
        overflow: hidden;
        aspect-ratio: 1 / 1;
        color: ${p => getColor(p, selectFromTheme(p, t => t.tile.color, t => getColor(p, "@mainBackground")))};
        transform-origin: center center;
        position: relative;
        background: ${p => getColor(p, selectFromTheme(p, t => t.tile.backgroundColor, t => getColor(p, "@accentBlack")))};
        transition: 0.2s all ease-out;
        border-bottom: 2px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
        color: ${p => getColor(p, selectFromTheme(p, t => t.tile.color, t => getColor(p, "@lightGrey")))};
        filter: ${p => p.activated ? "grayscale(0) opacity(1)" : "grayscale(1) opacity(0.3)"};
        cursor: ${p => p.activated ? "pointer" : "default"};
        cursor>svg{stroke: #FFF;}
              
        &:hover{
            color: white;
            transform: scale(1.00);
            box-shadow: ${p => p.activated ? "0px 0px 60px -20px " + getColor(p, "@middleLightGrey") + ", 0px 6px 8px -10px " + getColor(p, "@mainBackground") : "none"};
            background: ${p => p.activated ? "inherit" : getColor(p, selectFromTheme(p, t => t.postItem.backgroundColor, t => fadeColor(p, getColor(p, "@accentBlack"), 30)))};
            border-bottom: 2px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
            filter: ${p => p.activated ? "grayscale(0) opacity(1)" : "grayscale(1) opacity(0.8)"};
            img{
                transition: 0.2s all ease-out;
                filter: brightness(2);                
            }            
        }    
    `;
    public static PC = styled.div<{ imageUrl: string }>`
        //position: absolute;
        //top: 0;
        //left: 0;
        //bottom: ${p => 7 / 16 * 100}%;
        aspect-ratio: 7 / 4;
        width: 100%;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
    `;
    public static PCMobile = styled.div`
        width: 100%;
        //border: 1px solid cyan;
        height: auto;
        margin-bottom: 30px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};        
        box-shadow: 0 6px 8px -6px #000;
`;
    public static PodcastText = styled.div`
        //position: absolute;
        //top: ${p => 4 / 7 * 100}%;;
        //left: 0;
        //bottom: 40px;
        width: 100%;
    `;
    public static PodcastCategoryText = styled.div`
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 5px 10px;
        font-size: ${p => belowBreakpointOrEqual(p, "tablet") ? "0.9rem" : "inherit"};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
    `;
    public static PodcastCategoryTextCount = styled.div`
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px;
        font-size: 0.9em;
        background: ${p => fadeColor(p, "@accentBlack", 20)};
        color: ${p => fadeColor(p, "@mainBackground", 50)};
`;
    public static PodcastCategoryImage = styled.div`
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 5px 10px;
    `;

    public static CategoryVideosCount = styled.div<{ accent: string }>`
        position: absolute;
        left: 0;
        bottom: calc(42.86% - 20px);
        padding: 0px 30px 0px 10px;
        line-height: 20px;
        height: 20px;
        font-size: 0.9em;
        background: linear-gradient(135deg,  ${p => getColor(p, p.accent)} , transparent 90%);
        color: ${p => fadeColor(p, "@mainBackground", 70)};
        
`;
    public static PodcastButtons = styled.div`
        width: 100%;
        height: 40px;
        background: ${p => fadeColor(p, "@accentBlack", 80)};
        line-height: 40px;
        display: grid;
        grid-template-columns: max-content max-content 1fr max-content max-content;
        column-gap: 10px;
        align-items: center;
    `;
    public static PodcastTableViewHeaderCat = styled.div<{ odd?: boolean }>`
        width:100%;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 80px 1fr;
        -ms-grid-columns: 80px 1fr;
        grid-template-rows: 50px;
        -ms-grid-rows:  50px;
        text-align: center;
        line-height: 50px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.darkGrey)};
        margin-top: 10px;
        transition: 0.2s all ease-out;
`;
    public static PodcastCategoryContainerCat = styled(PodcastSC.PodcastTableViewHeaderCat)`
        grid-template-rows: 80px;
        -ms-grid-rows:  80px;
        line-height: 80px;
        margin-top: 0px;
        text-align: left;
        background: ${p => p.color ? "transparent" : p.odd ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"};
        cursor: pointer;
        margin-bottom: 0px;
        box-shadow: 0 0 0 0 transparent;
        &:hover{
            background: ${p => p.odd ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.3)"};
            border-bottom-color: ${p => getColor(p, p.theme.colors.accent)};
            box-shadow: 0 6px 8px -6px ${p => getColor(p, p.theme.colors.accent)};
        }   
`;
    public static PodcastCategoryContainerCatImage = styled.div`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
`;
    public static PodcastCategoryContainerCatTitle = styled.div`
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        font-size: 1.6em;
        padding: 0 20px;
        color: #515151;
        text-align: right;
        font-weight: bolder;
    `;
    public static PodcastTableViewHeader = styled.div`
        width:100%;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 80px 1fr 40px 40px 40px 40px 60px ;
        -ms-grid-columns: 80px 1fr 40px 40px 40px 40px 60px ;
        grid-template-rows: 50px;
        -ms-grid-rows:  50px;
        text-align: center;
        line-height: 50px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        border-bottom: 1px solid ${p => getColor(p, p.theme.colors.darkGrey)};
        margin-top: 10px;
`;

    public static PCMobileSource = styled.div`
        height: 30px;
        background: ${p => fadeColor(p, "@accentBlack", 70)};
        z-index: 5000;
        top: -34px;
        line-height: 30px;
        position: relative;
        left: 0;
        right: 0;
        border-bottom: 1px solid ${p => fadeColor(p, "@accentBlack", 50)};
`;
    public static PCMobileSourceImage = styled.div`
        width: 20px;
        float: right;
        line-height: 20px;
        height: 20px;
        margin-right: 6px;
        margin-top: 4px;
`;
    public static PCMobileSourceText = styled.div`
        float: right;
        margin-top: 4px;
        line-height: 20px;
        font-size: 0.8em;
`;
    public static PCMobileSourceAr = styled.div`
        font-size: 0.8em;
        float: left;
        margin-left: 8px;
        padding: 0 5px;
        line-height: 20px;
        background:rgba(255,255,255,0.03);
        border: 1px solid ${p => fadeColor(p, "@mainBackground", 80)};
        border-radius: 3px;
        margin-top: 4px;
`;
    public static PCMobileText = styled.div`
        padding: 0 10px;
        position: relative;
        top: -20px;
        margin-bottom: -10px;
`;
    public static PCMobileTextTitle = styled.div`
        font-size: 1.15em;
        width: 100%;
        margin-top: 2px;
`;
    public static PCMobileTextDescription = styled.div`
        margin: 10px 0 0 0;
        color: ${p => getColor(p, "@middleLightGrey")};
`;
    public static PodcastTableViewRow = styled(PodcastSC.PodcastTableViewHeader) <{ open?: boolean, odd: boolean, color?: "red" | "green" }>`
        grid-template-rows: 50px;
        -ms-grid-rows:  50px;
        line-height: 50px;
        font-weight: normal;
        letter-spacing: 0;
        text-transform: unset;
        margin-top: unset;
        //border-bottom: none;
        background: ${p => p.color ? "transparent" : p.odd ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.1)"};
        cursor: pointer;
        transition: 0.2s all ease-out;
        color: ${p => p.color === "red" ? getColor(p, "@accentRed") : p.color === "green" ? getColor(p, "@accentGreen") : "inherit"};
        &:hover{
            background: ${p => p.color ? "rgba(0,0,0,0.1)" : p.odd ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.3)"};
            *{
                filter: grayscale(0);
            }
        }
`;
    public static PodcastSidebarItem = styled.div<{ imageUrl: string }>`
        width:100%;
        aspect-ratio: 7 / 4;
        overflow: hidden;
        background: ${props => getColor(props, selectFromTheme(props, t => t.postItem.backgroundColor, t => fadeColor(props, getColor(props, "@accentBlack"), 30)))};
        color: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        cursor: pointer;
        position: relative;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: 0.2s all ease-out;
        margin-bottom: 10px;
`;
    public static PodcastSidebarItemTitleContainer = styled.div<{ height: number }>`
        position: absolute;
        bottom: calc(30px - 100% - 1px);
        right: 0;
        left: 0;
        width:100%;
        aspect-ratio: 7 / 4;
        //height: ${p => p.height}px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 80)};
        overflow: hidden;  
        transition: all 0.2s ease;
        &:hover   
            {
            bottom: 0;
            background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 80)};
            *{
                border-color: ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))} !important;
                }
            }
`;
    public static PodcastSidebarItemTitleContainerTeaser = styled.div`
        line-height: 29px;
        border-bottom: 1px solid ${p => fadeColor(p, getColor(p, "@accentBlack"), 40)};
        padding: 0 25px 0 10px;
        background: ${p => fadeColor(p, "backgroundColor", 20)};
        color: ${p => getColor(p, "color")};
        *{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;  
        }
`;
    public static PodcastSidebarItemTitleContainerTeaserContent = styled.div`
        padding: 10px 25px 10px 10px;
        height: 90px;
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 4;
        -moz-line-clamp: 4;
        -ms-line-clamp: 4;
        line-clamp: 4;
`;


    public static PodcastPostTableImage = styled.div<{ imageUrl?: string, background?: string, top?: number, left?: number }>`
        position: absolute;
        top: ${p => p.top ?? 5}px;
        left: ${p => p.left ?? 5}px;
        width: 32px;
        height: 32px;
        color: ${p => getColor(p, "@veryLightGrey")};
        line-height: 32px;
        text-align: center;
        border-radius: 16px;
        background: ${p => p.background ?? fadeColor(p, getColor(p, "@veryLightGrey"), 30)};
        border: 1px solid ${p => fadeColor(p, getColor(p, "@darkGrey"), 10)};
        //background-image: url('${p => p.imageUrl}');
        background-image: ${p => p.imageUrl ? "url('" + p.imageUrl + "')" : "none"};
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
`;
    public static PodcastPostTableImageLC = styled.div<{ imageUrl?: string, background?: string, top?: number, left?: number }>`
        width: 20px;
        height: 20px;
        float: left;
        line-height: 20px;
        text-align: center;
        background-image: ${p => p.imageUrl ? "url('" + p.imageUrl + "')" : "none"};
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
`;
    public static PaneResolutionInfo = styled.div`
        position: absolute;
        text-align: center;
        left: 0;
        bottom: 0;
        padding: 0px 30px 0px 0px;
        text-align: center;
        line-height: 20px;
        height: 20px;
        font-size: 0.9em;
        background: linear-gradient(135deg, #000 2%, rgba(0,0,0,0) 70%);
        color: ${p => fadeColor(p, "@mainBackground", 90)};
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
`;
    public static PodcastPostImageVideoTypeTable = styled.div<{ imageUrl?: string }>`
        width: 32px;
        height: 32px;
        background-image: ${p => p.imageUrl ? "url('" + p.imageUrl + "')" : "none"};
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
        margin-top: 9px;
        filter: grayscale(1);
`;

    public static PodcastPostTableThumbCat = styled.div<{ imageUrl: string }>`
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 80px;
        height: 80px;
`;

    public static PodcastPostTableThumb = styled.div<{ imageUrl: string }>`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: 0.2s all ease-out;
`;
    public static PodcastPostTableTitle = styled.div`
        font-size: 1rem;
        text-align: left;
        padding-left: 10px;
        grid-column-start: 2;
        grid-column-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 7 : 3};
        grid-row-start: 1;
        grid-row-end: 2;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%auto;  
`;
    public static PodcastPostTableSource = styled.div`
        font-size: 0.8em;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 1};
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
`;
    public static PodcastPostTableViews = styled.div`
        font-size: 0.8em;
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 1};
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
`;
    public static PodcastPostTableRating = styled.div`
        font-size: 0.8em;
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 1};
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
`;
    public static PodcastPostTableComment = styled.div`
        font-size: 0.8em;
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 1};
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
`;
    public static PodcastPostTableButton = styled.div`
        margin: 0 10px;
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: ${p => belowBreakpointOrEqual(p, "tablet") ? 2 : 1};
        grid-row-end: ${p => belowBreakpointOrEqual(p, "tablet") ? 3 : 2};
`;
    public static PodcastPostTableMore = styled.div`
        margin: 0 10px;
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row-start: 1;
        grid-row-end: 2;
`;

    public static PodcastPostViewedSidebar = styled.div`
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 60)};
        text-align: center;
        line-height: 12px;
        svg{
            stroke: ${p => getColor(p, "@mainForeground")};
            stroke-width: 4px;
        }
`;

    public static PodcastTableViewRowShowMore = styled.div<{ color: "@accentGreen" | "@accentRed" }>`
        text-align: left;
        padding-left: 10px;
        text-transform: uppercase;
        width: 100%;
        padding-right: 10px;
        line-height: 40px;
        color: ${p => getColor(p, p.color)};
        cursor: pointer;
        transition: 0.2s all ease-out;
        &:hover{
            background: rgba(0,0,0,0.1);
        }
`;

    public static PodcastPostDescriptionTitle = styled.div`
    font-size: 1.1em;
    letter-spacing: 0.5px;
    background: ${p => fadeColor(p, "@accentBlack", 60)};
    /* background: ${p => fadeColor(p, "color", 70)}; */
    //transform: translateY(-100%);
    padding: 10px;
    color: ${p => getColor(p, "color")};
    border-top: 1px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
`;
    public static PodcastPostDescriptionViewed = styled.div`
        float: right;
`;
    public static PodcastPostDescriptionText = styled.div`
        padding: 10px;
        height: 100%;
        background: ${p => fadeColor(p, "@accentBlack", 80)};
        overflow-x: hidden;
        overflow-y: ${p => belowBreakpointOrEqual(p, "largeMobile") ? "hidden" : "auto"};
        font-size: 0.95em;
        scrollbar-width: none;      
        position: relative;

        div{
        ${p => belowBreakpointOrEqual(p, "largeMobile") && `
        -ms-overflow-style: none;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -ms-box-orient: vertical;
        box-orient: vertical;
        -webkit-line-clamp: 4;
        -moz-line-clamp: 4;
        -ms-line-clamp: 4;
        line-clamp: 4;
        `}
    }
`;
    public static PodcastPostFooterButton = styled.div`
        position: absolute;
        top: 0px;
        right: 0px;
        button>div{
            margin:0;
        }
`;
    public static PodcastPostFooterButtonMobile = styled.div`
        float: right;
        max-width: calc(100vw - 200px);
        line-height: 40px;
        text-align: right;
`;
    public static PodcastPostFooterInfosTopLeftIcon = styled.div`
        position: absolute;
        top: 2px;
        left: 5px;
        width: 20px;
        height: 20px;
        overflow: hidden;
        svg{
            margin-top: 1px;
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static PodcastPostFooterInfosTopLeftFigure = styled.div`
        position: absolute;
        top: 2px;
        left: 30px;
        width: 62px;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        font-size: 0.7rem;
`;
    public static PodcastPostFooterInfosTopRightIcon = styled.div`
        position: absolute;
        top: 2px;
        left: 97px;
        width: 20px;
        height: 20px;
        overflow: hidden;
        svg{
            margin-top: 1px;
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static PodcastPostFooterInfosTopRightFigure = styled.div`
        position: absolute;
        top: 2px;
        left: 122px;
        width: 62px;
        line-height: 20px;
        height: 20px;
        overflow: hidden;
        font-size: 0.7rem;
`;
    public static PodcastPostFooterInfosBottomFigure = styled.div`
    position: absolute;
    top: 19px;
    left: 122px;
    width: 62px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 0.7rem;
`;
    public static PodcastBackButtonContainer = styled.div`
    float: right;
    height: 40px;
    width: 80px;
    background: ${p => fadeColor(p, "@accentBlack", 20)};
`;
    public static PodcastPostFooterInfosBottom = styled.div`
    padding-left: 10px;
        svg{
            stroke: ${props => getColor(props, selectFromTheme(props, t => t.postItem.color, t => getColor(props, "@mainBackground")))};
        }
`;
    public static PodcastPostFooterFigure = styled.div<{ color?: string }>`
        float: left;
        font-size: 0.8em;
        transform: translateY(-5px);
        color: ${p => p.color ? getColor(p, p.color) : "inherit"};
`;
    public static PCGrid = styled.div<{ mobileView: boolean, contentWidth: number }>`
        //width: ${p => p.mobileView ? p.contentWidth - 10 + "px" : "100%"};
        width: 100%;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: ${p => p.mobileView ? "1fr" : "1fr 220px"};
        -ms-grid-columns: ${p => p.mobileView ? "1fr" : "1fr 220px"};
        grid-template-rows: 45px max-content 10px max-content 10px max-content 10px max-content min-content ${p => p.mobileView && "45px 1fr"};
        -ms-grid-rows:  45px max-content 10px max-content 10px max-content 10px max-content min-content ${p => p.mobileView && "45px 1fr"};
        grid-row-gap: 0px;
        grid-column-gap: ${p => belowBreakpointOrEqual(p, "wide") ? p.mobileView ? 0 : 20 : 80}px;
        transition: 0.2s all ease-out;
`;
    public static PCGridRight = styled.div<{ mobileView: boolean }>`
        grid-row-start: ${p => p.mobileView ? 11 : 2};
        grid-row-end: ${p => p.mobileView ? "unset" : 10};
        grid-column: ${p => p.mobileView ? 1 : 2};

`; public static PCGridLeftUser = styled.div<{ mobileView: boolean }>`
        grid-row: 4;        
        grid-column: 1;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 40px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        -ms-grid-columns: 40px 1fr max-content ${p => p.mobileView && "max-content max-content"};
        grid-template-rows: 40px;
        -ms-grid-rows:  40px;
`;
    public static PCGridLeftDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 6;
        grid-column: 1;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: ${p => p.mobileView ? "1fr max-content" : "40px max-content 1fr max-content"};
        -ms-grid-columns: ${p => p.mobileView ? "1fr max-content" : "40px max-content 1fr max-content"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
        -ms-grid-rows:  ${p => p.mobileView ? "max-content" : "max-content 10px 1fr"};
        margin-bottom: 20px;
`;
    public static PCGridLeftComments = styled.div<{ mobileView: boolean }>`
        grid-row: 8;
        grid-column: 1;
        display: grid;
        display: -ms-grid;
        grid-template-columns: ${p => p.mobileView ? "0 1fr" : "40px 1fr"};
        -ms-grid-columns: ${p => p.mobileView ? "0 1fr" : "40px 1fr"};
        grid-template-rows: 40px minmax(160px, 1fr);
        -ms-grid-rows: 40px minmax(160px, 1fr);
`;
    public static PCGridLeftCommentsCommentsContainer = styled.div`
        grid-row: 1;
        grid-column: 2;
        background: rgba(0,0,0,0.1);
`;
    public static PCGridLeftButtons = styled.div<{ active: boolean, color: string }>`
        border-bottom: 1px solid ${p => p.active ? getColor(p, p.color) : "transparent"};
        background: ${p => p.active ? fadeColor(p, p.color, 10) : "transparent"};
        float: left;
        transition: 0.2s all ease-out;
        &>*{
            transition: 0.2s all ease-out;
            color: ${p => p.active ? getColor(p, p.color) : fadeColor(p, p.color, 60)} !important;
            stroke: ${p => p.active ? getColor(p, p.color) : fadeColor(p, p.color, 60)} !important;
            &:hover{
                color: ${p => p.active ? getColor(p, p.color) : fadeColor(p, p.color, 90)} !important;
                stroke: ${p => p.active ? getColor(p, p.color) : fadeColor(p, p.color, 90)} !important;
            }
        }
    `;
    public static PCGridLeftCommentsCommentsRatings = styled.div`
        grid-row: 2;
        grid-column: 2;
        border: 1px solid #2B2A2D;
        margin-bottom:30px;
        margin-top:-1px;
`;


    public static PCGridLeftDescriptionTitle = styled.div`
        grid-row: 1;
        grid-column: 2;
        padding-left: 10px;
`;
    public static PCGridLeftDescriptionDescription = styled.div<{ mobileView: boolean }>`
        grid-row: 1;
        grid-column: ${p => p.mobileView ? 1 : 3};
        padding: 0 10px;
`;

    public static PCGridLeftDescriptionButton = styled.div<{ mobileView: boolean }>`
    grid-row: 1;
    grid-column: ${p => p.mobileView ? 2 : 4};
    align-self: end;
    button>div{
        margin: 0;
        }
`;
    public static PCGridLeftMoreInfo = styled.div<{ mobileView: boolean }>`
        grid-row: ${p => p.mobileView ? 2 : 3};
        grid-column-start: ${p => p.mobileView ? 1 : 2};
        grid-column-end: ${p => p.mobileView ? 3 : 5};
        background: rgba(0,0,0,0.1);
        display: grid;
        display: -ms-grid; 
        margin-top: ${p => p.mobileView ? "10px" : 0};
        margin-bottom: ${p => p.mobileView ? "10px" : "10px"};
        grid-template-columns: ${p => p.mobileView ? "max-content 1fr" : "max-content max-content 1fr max-content max-content 1fr max-content max-content"};
        -ms-grid-columns: ${p => p.mobileView ? "max-content 1fr" : "max-content max-content 1fr max-content max-content 1fr max-content max-content"};
        grid-template-rows: ${p => p.mobileView ? "max-content" : "max-content max-content minmax(0, 1fr)"};
        -ms-grid-rows: ${p => p.mobileView ? "max-content" : "max-content max-content minmax(0, 1fr)"};
        transition: 0.2s all ease-out;
        padding-top: 5px;
`;
    public static PCGridLeftMoreInfoTitle = styled.div`
        font-weight: 400;
        line-height: 40px;
        padding: 0 10px;
`;
    public static PCGridLeftMoreInfoContent = styled.div`
        line-height: 40px;
        padding: 0 10px 0 0 ;
        button>div{margin:0;}
        svg>rect{
                fill: ${p => getColor(p, "color")};
            }
`;

    public static PCQrContainerLink = styled.div`
        font-size: 0.9em;
        line-height: 20px;
        cursor: pointer;
        transition: 0.2s all ease-out;
        color: ${p => getColor(p, "@darkGrey")};
        
        &:hover{
            color: ${p => getColor(p, "@hoverColor")};
        }
`;




    public static PCGridLeftMoreInfoLink = styled.div<{ mobileView: boolean, second?: boolean }>`
        grid-row: ${p => p.second ? (p.mobileView ? 14 : 6) : (p.mobileView ? 7 : 3)};
        grid-column-start: ${p => p.mobileView ? 1 : 1};
        grid-column-end: ${p => p.mobileView ? 3 : 9};
        height: 40px;
        display: grid;
        display: -ms-grid; 
        grid-template-columns: 40px 1fr 40px;
        -ms-grid-columns: 40px 1fr 40px;
        grid-template-rows: 40px;
        -ms-grid-rows: 40px;
        overflow: hidden;
        //background: rgba(0,0,0,0.1);
        transition: 0.2s all ease-out;
            border-left: 3px solid transparent;
            border-right: none;
        &:hover{
            background: rgba(0,0,0,0.3);
            border-left: 3px solid ${p => getColor(p, "@accent")};
            border-right: none;
        }
        button>div{margin: 0;}
`;
    public static PCGridLeftMoreInfoLinkIcon = styled.div`
        text-align: center;
        line-height: 36px;
        height: 36px;
        button>div{margin: 0;}
`;

    public static PCGridLeftUserAvatar = styled.div`
        grid-row: 1;
        grid-column: 1;
        background: rgba(0,0,0,0.05);
`;
    public static PCGridLeftUserName = styled.div`
        grid-row: 1;
        grid-column: 2;
        padding-left: 10px;
`;
    public static PCGridLeftUserNameName = styled.div`
        line-height: 18px;
        font-size: 0.85rem;
        font-weight: 500;
        letter-spacing: 0.5px;
`;
    public static PCGridLeftUserNameDate = styled.div`
        line-height: 18px;
        font-size: 0.7rem;
`;
    public static PCGridLeftUserChangedBy = styled.span`
        padding-left: 10px;
        color: ${p => getColor(p, "@lightGrey")};
`;
    public static PCGridLeftUserRating = styled.div`
        grid-row: 1;
        grid-column: 3;
        padding-left: 5px;
        line-height: 40px;
`;
    public static PCGridLeftUserRatingFigure = styled.div`
        grid-row: 1;
        grid-column: 4;
        font-size: 0.7rem;
        line-height: 40px;
        padding-right: 5px;
`;
    public static PCGridLeftUserButtons = styled.div`
        grid-row: 1;
        grid-column: 5;
        text-align: right;
        button>div{margin:0;}
`;
    public static PCGridLeftHeaderBar = styled.div`
        grid-row: 1;
        grid-column: 1;
        line-height: 40px;
        font-size: 1.2em;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        width: 100%;
        padding-right: 45px;
        position: relative;
        
        button>div{
            margin: 0;
        }
`;
    public static PCGridLeftVideo = styled.div`
        grid-row: 2;
        grid-column: 1;
        position: relative;
        overflow: hidden;
        box-shadow: 0 6px 8px -6px #000;
`;

    public static PCGridLeftVideoNext = styled.div`
        position: absolute;
        top: 50%;
        height: 150px;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        text-align: center;
        animation: ${entrance} 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
`;
    public static PCNextItemContainer = styled.div`
        width:180px;
        height: 110px;
        display: inline-block;
        overflow: hidden;
        cursor: pointer;
        background: ${p => fadeColor(p, getColor(p, "@mainBackground"), 20)};        
        transition: 0.2s all ease-out;
        border: 1px solid ${p => getColor(p, "@mainBackground")};
        position: relative;
        margin: 0 10px;
        top: 50%;
        transform: translateY(-50%);

        &:hover{
            border: 1px solid ${p => getColor(p, "@accent")};
            background: ${p => fadeColor(p, getColor(p, "@accent"), 50)};        
        }
`;
    public static PCNextItem = styled.div<{ imageUrl: string }>`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% ,-50%);
        width: 160px;
        height: 90px;
        overflow: hidden;
        background: ${p => fadeColor(p, getColor(p, "@accentBlack"), 20)};
        background-image: url('${p => p.imageUrl}');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
`;
    public static PCNextItemTitle = styled.div`
        position: absolute;
        height: 24px;
        bottom: 9px;
        width: 160px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 24px;
        font-size: 0.8rem;
        padding-left: 10px;
        background: rgba(0,0,0,0.7);
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
`;
    public static PCNextItemCloseButton = styled.div`
        position: absolute;
        top: -40px;
        right: 0px;
        width: 40px;
        height: 40px;
        text-align: center;
        transition: 0.2s all ease-out;
        background: transparent;
        line-height: 40px;
        cursor: pointer;
        svg{
                stroke: ${p => getColor(p, "@mainBackground")};
            }
        &:hover{
            background: rgba(0,0,0,0.5);
            svg{
                stroke: ${p => getColor(p, "@accent")};
            }
        }
`;
    public static SectionConatinerTitle = styled.div`
        height: 40px;
        line-height: 40px;
        width: 100%;
        color: ${p => getColor(p, selectFromTheme(p, t => t.color, t => getColor(p, "@mainBackground")))};
        //background: ${p => getColor(p, "@darkerGrey")};
        background: ${p => getColor(p, selectFromTheme(p, t => t.postItem.backgroundColor, t => getColor(p, "@darkerGrey")))};
        font-size: 1.0rem;
        padding-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;  
        border-left: 3px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
        margin-top: 0px;
    
`;
    public static BookmarkedAction = styled.div`
        padding-top: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : "100%"};
        height: ${p => belowBreakpointOrEqual(p, "mobile") ? "100px" : "unset"};
        position: relative;
        width: 100%;
        background: rgba(0,0,0,0.05);
        transition: 0.2s all ease-out;
        cursor: pointer;
        overflow: hidden;
        .BookmarkedActionIcon{
            transition: 0.2s all ease-out;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .BookmarkedActionInfo{ 
            transition: 0.2s all ease-out;
            position: absolute;
            left: 0;
            right:0;
            bottom: ${p => belowBreakpointOrEqual(p, "mobile") ? "0px" : "-50px"};
            height: 50px;
            line-height: 50px;
            text-align: center;
            }
        svg{
            transition: 0.2s all ease-out;
            stroke: ${p => getColor(p, "@darkerGrey")};
        }
        &:hover{
            background: rgba(0,0,0,0.2);
            .BookmarkedActionIcon{
                top: calc(50% - 25px);
                transform: translate(-50%, -50%);
        }
        .BookmarkedActionInfo{ 
            bottom: 0px;
            background: rgba(0,0,0,0.3);
            transition: 0.2s all ease-out;
            }
            svg{
            transition: 0.2s all ease-out;
                stroke: ${p => getColor(p, "@middleLightGrey")};
            }
        }
`;
    public static TLVContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    max-width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    background: black;
    
`;

    public static TLVError = styled.div`
    position: fixed;
    font-size: 300%;
    line-height: 300%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
`;

    public static TLVVideo = styled.div`
    width: 100%;
    height: 100%;
    video, embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
    }
`;
    public static TBPDescription = styled.div`
    width: calc(100% - 40px);
    float: left;
    height: 40px;
    line-height: 40px;
`;
    public static TPSAuto = styled.div<{ suggested: number }>`
    height: ${p => p.suggested ? p.suggested * 40 + "px" : "40px"};
    //background: ${p => p.theme.colors.mainBackground};
    float: left;
    color: ${p => p.theme.colors.mainForeground};
    width:100%;
    position: relative;
    margin-bottom: 2px;
    transition: all 0.2s ease-out;
    input{
        height: 40px;
        outline: none;
        width: 100%;
        border-color: none;
        padding: 0 10px;
        transition: all 0.2s ease-out;
    }
        input:focus{
            background: ${p => lightenColor(p, p.theme.colors.darkerGrey, 80)} !important;
        }
`;
    public static TPSSearchDelete = styled.div`
    position: absolute;
    right: 2px;
    top: 1px;
    width: 38px;
    height: 38px;
    float: right;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    svg{
            stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        }
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static TPSFilterContainer = styled.div`
    border: 1px solid rgba(255,255,255,0.05);
    box-shadow: 0 6px 8px -6px ${p => getColor(p, "@darkerGrey")};;
    padding: 10px;
    background: ${p => getColor(p, p.theme.colors.darkerGrey)};
`;
    public static TPSFilterContainerTitle = styled.div`
    font-weight: 600;
    padding-top: 40px;
    margin-bottom:20px;
`;
    public static TPSFInfoContainer = styled.div`
    display: block;
    padding-left:5px;
    padding-bottom:10px;
    line-height:30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
    public static TPSFContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-template-rows: max-content;
    *{
        color: ${p => getColor(p, "@veryLightGrey")};
    }
`;
    public static TPSFContainerTitle = styled.div`   
        color: ${p => getColor(p, "@veryLightGrey")};
        line-height: 40px;
`;
    public static TPSFRadioButtonDescription = styled.div<{ selected: boolean }>`
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-out;
    border-right: 2px solid transparent;
    color:${p => p.selected ? p.theme.colors.mainBackground : p.theme.colors.middleLightGrey};
    padding-right: 5px;
    &:hover{
        border-right: 2px solid ${p => p.theme.colors.darkerGrey};
        color:${p => p.theme.colors.mainBackground};
    }
`;
    public static TPSFRadioButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr; 
    svg{
        margin-top: 5px;
        float: left;
        }
    *{
        stroke: ${p => p.theme.colors.mainBackground};
    }
`;
    public static TPUInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    //margin-left: 25px;
    width: calc(100%);
    cursor: default;
`;
    public static TPUTitle = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    line-height: 30px;
    font-weight: 600;
    *{
        stroke: ${p => getColor(p, "@mainBackground")};
        svg{
            margin-top: 5px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
`;
    public static Filename = styled.div`
    font-size: 0.9em; 
    line-height: 40px;
    background: ${p => lightenColor(p, p.theme.colors.darkerGrey, 90)};
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: 40px;
`;
    public static FilenameIcon = styled.div`
        grid-column: 1;
        padding-left: 11px;
`;
    public static FilenameName = styled.div`
        grid-column: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
`;
    public static FilenameBtn = styled.div`
        grid-column: 3;
`;
    public static TPUContent = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    *{
        border-color: transparent;
    }
`;
    public static TPUSpacer = styled.div`
    grid-column-start: 1;
    grid-column-end: 2;
    height: 5px;
`;
    public static TPUContentInfo = styled.div`
    font-size: 0.75rem;
    line-height: 0.9rem;
    display: block;
    margin-top: -10px;
    color: ${p => getColor(p, "@lightGrey")};
    svg{
        stroke: ${p => getColor(p, "@lightGrey")};
    }
`;
    public static TSAuto = styled.div<{ suggested: number, lightMode?: boolean, accent?: string }>`
    height: ${p => p.suggested ? p.suggested * 40 + "px" : "40px"};
    //background: ${p => p.theme.colors.mainBackground};
    float: left;
    color: ${p => p.theme.colors.mainForeground};
    width:100%;
    position: relative;
    margin-bottom: 2px;
    transition: all 0.2s ease-out;
    input{
        height: 40px;
        outline: none;
        width: 100%;
        padding: 0 10px;
        padding-left: 30px;
        transition: all 0.2s ease-out;
        border: 1px solid transparent;
        border-bottom: 1px solid ${p => p.lightMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.1)"};
        background: ${p => p.lightMode ? "rgba(0,0,0,0.03)" : belowBreakpointOrEqual(p, "mobile") ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.03)"};
        background-color: ${p => p.lightMode ? "rgba(0,0,0,0.03)" : belowBreakpointOrEqual(p, "mobile") ? "rgba(0,0,0,0.4)" : "rgba(255,255,255,0.03)"};
        color: ${p => p.lightMode ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)"};
    }
    input::placeholder {
        color: ${p => p.lightMode ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)"};
        text-transform: uppercase;
        font-size: 0.7rem;
        line-height: 38px;
    }
    input:focus{
        background: ${p => p.lightMode ? "rgba(0,0,0,0.06)" : belowBreakpointOrEqual(p, "mobile") ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.06)"};
        background-color: ${p => p.lightMode ? "rgba(0,0,0,0.06)" : belowBreakpointOrEqual(p, "mobile") ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.06)"};
        border: 1px solid ${p => p.lightMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.1)"};
        border-bottom-color: ${p => p.lightMode ? "rgba(0,0,0,0.5)" : belowBreakpointOrEqual(p, "mobile") ? p.accent ? p.accent : p.theme.colors.accent : "rgba(255,255,255,0.5)"};
        color: ${p => p.lightMode ? "rgba(0,0,0,1)" : p.accent ? p.accent : "rgba(255,255,255,1)"};
        z-index: 20;
    }
    .searchIcon{
        position: absolute;
        left: -3px;
        top: 1px;
        width: 38px;
        height: 38px;
        float: left;
        text-align: center;
        svg{
                stroke: ${p => p.lightMode ? "rgba(0,0,0,1)" : p.accent ? p.accent : "rgba(255,255,255,1)"};
            }
        
    }
`;

    public static TSSearchDelete = styled.div`
    position: absolute;
    right: 2px;
    top: 1px;
    width: 38px;
    height: 38px;
    float: right;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    svg{
            stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        }
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static Container = styled.div`
    display: block;
    padding-left:20px;
    padding-bottom:10px;
    line-height:30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
    public static RadioButtonDescription = styled.div<{ selected: boolean }>`
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-out;
    border-right: 2px solid transparent;
    color:${p => p.selected ? p.theme.colors.mainBackground : p.theme.colors.middleLightGrey};
    padding-right: 5px;
    &:hover{
        border-right: 2px solid ${p => p.theme.colors.darkerGrey};
        color:${p => p.theme.colors.mainBackground};
    }
`;
    public static RadioButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr; 
    svg{
        margin-top: 5px;
        float: left;
        }
    *{
        stroke: ${p => p.theme.colors.mainBackground};
    }
`;

    public static PodcastPostContainerResolutions = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    background: ${p => fadeColor(p, getColor(p, "@darkerGreys"), 70)};
    height: 56.25%;
`;

    public static PodcastPostContainerResolutionsIcon = styled.div`
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
`;

    public static PlaybuttonText = styled.div<{ isPlaying: boolean }>`
    color: ${p => getColor(p, p.isPlaying ? "color" : selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
`;


    public static PCButton = styled.div<{ xOffset: number, scale: number }>`
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: ${p => `calc(50% + ${p.xOffset}px)`};
    transform: translate(-50%, -50%) scale(${p => p.scale});
    text-align: center;
    color: #fff;
    background: ${p => fadeColor(p, "color", 10)};
    font-size: 1.0em;
    font-weight: 100m;
    cursor: pointer;
    transition: all 0.2s ease-out;
    
    svg{
        stroke-width: 1;
        }
    &:hover{
        background: ${p => fadeColor(p, "color", 20)};
        color: ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
        font-size: 1.2em;

    }
`;
    public static PCRange = styled.div<{ pos: "left" | "right" }>`
    width: 200px;
    position: absolute;
    top: 50%;
    ${p => `${p.pos}: 15px;`}
    right: 15px;
    transform: translateY(calc(-50% - 4px));

    input[type=range]:focus {
    outline: none;
    }

    input[type=range] {
        appearance: none;
        width: 100%;
        border-radius: 0;
        height: 1px;
        border: none;
        background-color: ${p => fadeColor(p, "@lightGrey", 100)};
        &:hover{
            background-color: ${p => fadeColor(p, "color", 100)};
        }
    }
    input[type='range']::-webkit-slider-thumb {
        appearance: none;
        background-color: ${p => getColor(p, "@lightGrey")};
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: none;
        cursor: ew-resize;
        &:hover{
            background-color: ${p => getColor(p, podcastTheme.podcastAccent)};
        }
}

    input[type=range]::-moz-range-thumb {
        background-color: ${p => getColor(p, "@lightGrey")};
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: none;
        cursor: crosshair;
        &:hover{
            background-color: ${p => getColor(p, podcastTheme.podcastAccent)};
        }
    }
`;

    public static PCOuterContainer = styled.div<{ bgUrl: string }>`
        width: 100%;
        aspect-ratio: 7 / 4;
        background-image: ${p => `url("${p.bgUrl}")`};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
`;

    public static PodcastContainer = styled.div`
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100px;
        background-color: ${p => fadeColor(p, "@accentBlack", 60)};
        &:hover{
            background-color: ${p => fadeColor(p, "@accentBlack", 80)};
        }
`;
    public static WaveContainer = styled.div<{ playing: boolean }>`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.3;
        filter: grayscale(0.8);
        transform: translateY(-40px);
`;

    public static RateFigure = styled.div<{ shown: boolean }>`
        position: absolute;
        bottom: 100px;
        font-size: 30px;
        line-height: 50px;
        width: 100%;
        transition: height 0.2s ease-out;
        transform-origin: bottom;
        height: ${p => p.shown ? 100 : 0}px;
        text-align: center;
        background-color: ${p => fadeColor(p, "@accentBlack", 80)};
        overflow: hidden;
        span{
            color: ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
        }
`;

    public static ProgressContainer = styled.div`
        position: absolute;
        left: 15px;
        right: 15px;
        top: -40px;
`;

    public static Progress = styled.div`
        border-radius: 0px;
       // cursor: crosshair;
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="rgba(0, 0, 0,0.9)" stroke-width="0" fill="rgba(45, 201, 242,0.8)" width="24px" height="24px" viewBox="0 0 10.04 10.04"><circle cx="5.02" cy="5.02" r="3.52"/></svg>') 10 10, auto;
        height: 40px;
        position: relative;
`;
    public static ProgressLine = styled.div`
        background: transparent;
        border-radius: 0px;
        height: 40px;
        position: relative;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            content: "";
            position: absolute;
            left: 0; 
            right: 0; 
            top: 50%; 
            height: 3px; 
            background-color: ${p => fadeColor(p, "@mainBackground", 50)};
            transform: translateY(-50%); 
        }
`;

    public static ProgressBar = styled.div<{ width: number }>`
        background-image: linear-gradient(to right, ${p => lightenColor(p, podcastTheme.podcastAccent, 30)} , ${p => getColor(p, podcastTheme.podcastAccent)});
        height: 7px;
        border-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        left: 0;
        right: calc(100% - ${p => p.width}%);
        top: 50%;
        transform: translateY(-50%);
        transition: 0.2s all ease-out;
        pointer-events: none;
        //border: 1px solid #000;
`;

    public static ProgressDot = styled.div<{ left: number, isPlaying: boolean }>`
        width: ${p => p.isPlaying ? 30 : 20}px;
        height: ${p => p.isPlaying ? 30 : 20}px;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        background: ${p => fadeColor(p, "@accentBlack", 30)};
        left: calc(${p => p.left}% - 3px);
        transform-origin: center center;
        transform: translate(-50%, -50%);    
        transition: 0.2s all ease-out;
        border: 1px solid ${p => fadeColor(p, podcastTheme.podcastAccent, p.isPlaying ? 10 : 50)};
        pointer-events: none;
        svg{
            margin: 5px;
            margin-top: -1px;
            margin-left: -1px;
        }
        *{
            fill: ${p => getColor(p, "@accentRed")};
            stroke: ${p => getColor(p, "@mainBackground")};
        }
`;


    public static TimeDisplay = styled.div<{ pos: "left" | "right" }>`
        position: absolute;
        ${p => `${p.pos}: 0px;`}
        top: 50px;
        z-index: 2000;
        font-size: 1em;
        color: #fff;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6), 1px 1px 1px rgba(0,0,0,0.8);
        pointer-events: none;
`;

    public static SubsContainer = styled.div`
    padding: 20px 50px;
    border: 1px solid ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
    font-size: 1.2em;
            .highlight {
            animation: ${ani} 0.4s ease-in forwards;
        }
`;

    public static IconContainer = styled.div<{ playing: boolean }>`
        position: absolute;
        top: calc(50% - 50px);
        left: 50%;
        transform-origin: center center;
        transform: translate(-50%, -${p => p.playing ? 20 : 50}%) scale(${p => p.playing ? 0.6 : 0.8});
        transition: 0.2s all ease-out;
        cursor: pointer;
        svg{
            transition: 0.2s all ease-out;
            stroke: ${p => fadeColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")), p.playing ? 50 : 70)};
            stroke-width: 0.3;
            fill: ${p => fadeColor(p, "@mainBackground", 15)};
                &:hover{
                    stroke: ${p => fadeColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")), p.playing ? 70 : 90)};
                    stroke-width: 0.5;
                    fill: ${p => fadeColor(p, "@mainBackground", 25)};
                }
        }
`;

    public static RangeTitleInfo = styled.div<{ pos: "left" | "right", showRateInfo: boolean }>`
        position: absolute;
        ${p => `${p.pos}: 0%;`}
        padding-top: 2px;
        transform: translate(0, 100%);
        color: ${p => p.showRateInfo ? getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent"))) : fadeColor(p, "color", 30)}; 
        z-index: 1; 
        pointer-events: none;
        transition: 0.2s all ease-out;
`;


    public static AudioTransContainer = styled.div`
        background: ${p => getColor(p, selectFromTheme(p, t => t.podcastAccent, t => getColor(p, "@accent")))};
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-weight: bolder;
        padding-left: 50px;
        color: ${p => getColor(p, "@accentBlack")};
`;

    public static TextShadower = styled.div <{ top: boolean }>`
        position: absolute;
        left: 0;
        right: 0;
        top: ${p => p.top ? "calc(100% * 4 / 7)" : "unset"};
        bottom: ${p => p.top ? "unset" : "40px"};
        height: ${p => p.top ? 15 : 40}px;
        background: linear-gradient(180deg, rgba(0,0,0,${p => p.top ? 0.8 : 0}) 0%, rgba(0,0,0,${p => p.top ? 0 : 0.8}) 100%);
`;


}

