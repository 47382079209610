import { ThemeColor } from 'imaginarity-react-ui';
interface PodcastTile extends ThemeColor {

}

export interface PostSettings {
    backgroundColor: string
    color: string;
}
export interface FilteredImage {
    opacity?: number;
    filter?: string;
    url: string;
}
export interface PodcastTheme extends ThemeColor {
    tile: PodcastTile;
    headerRightImage: FilteredImage;
    postItem: PostSettings;
    showPodcastTableView: boolean;
    showUploadForm: boolean;
    homeHeaderTitle: string;
    homeImage: string;
    vimeoLogoLink: string;
    youtubeLogoLink: string;
    noCategoryImage: string;
    podcastAccent: string;
    darkMode: boolean;
    showTooltips: boolean;
    showInfo: boolean;
    loadCount: boolean,
    showNewTileAppearance: boolean;
    noPreviewPodcastImage: string;

}
export interface PodcastSettings {

}

export const podcastTheme: PodcastTheme = {
    loadCount: true,
    showNewTileAppearance: true,
    backgroundColor: "lighten('@darkerGrey', 77%)", //backgroundcolor
    color: "@veryLightGrey", //fontColor
    darkMode: true,  //true if dark backgroundcolor
    noPreviewPodcastImage: "https://cdn.imaginarity.com/public/podcast/podcastNoImageV1.webp",
    tile: {
        backgroundColor: "fade('@accentBlack', 70%)", //categorytile backgroundcolor
        color: "@veryLightGrey", //categorytile fontColor
    },
    headerRightImage: { //banner right image
        opacity: 1,
        filter: "grayscale(0)",
        url: "https://cdn.imaginarity.com/public/projectImages/pagPodcastHeaderRight.jpg"
    },
    postItem: {
        backgroundColor: "fade('@accentBlack', 30%)", //audio pane backgroundcolor
        color: "@veryLightGrey", //audio pane fontColor
    },
    showPodcastTableView: true, //true if you want activate tableview
    showUploadForm: false, //true if you want activate uploadform on sidebar (currently not implemented)
    homeHeaderTitle: "imaginarity podcast", //tube banner title
    homeImage: "https://cdn.imaginarity.com/public/projectImages/imgPodcastHeaderImageV4.png", //tube banner left image (squared)
    vimeoLogoLink: "https://cdn.imaginarity.com/public/tube/logovimeo.svg", //vimeo logo icon
    youtubeLogoLink: "https://cdn.imaginarity.com/public/tube/logoyoutube.svg", //youtube logo icon
    podcastAccent: "#2DC9F2", // accent color
    noCategoryImage: "https://cdn.imaginarity.com/public/projectImages/imgNoImageAvailable.svg", //alternative image if no image is set
    showTooltips: true,
    showInfo: true,
}
