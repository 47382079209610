import { UserShortInfoDownloadDto } from 'collaboration-service';
import UserSearch from 'components/UserSearch/UserSearch';
import * as React from 'react';
import { FeedSearchProps } from './FeedFilter';

interface FeedSearchByUserProps {
    getUserSelected: (user: UserShortInfoDownloadDto) => void;  // is needed for reseting
    startSearch?: (f: FeedSearchProps) => void;
    user?: UserShortInfoDownloadDto;
}

const FeedSearchByUser = (p: FeedSearchByUserProps) => {
    const { getUserSelected, startSearch: sSearch, user } = p;

    const startSearch = React.useMemo(() => async (search?: string, user?: UserShortInfoDownloadDto) => {
        const feedSearchProps: FeedSearchProps = { user, search };
        if (sSearch)
            sSearch(feedSearchProps);
    }, [sSearch]);

    const onUsersSelected = React.useMemo(() => (users: UserShortInfoDownloadDto[]) => {
        const selected = users[users.length - 1];
        startSearch(undefined, selected);
        getUserSelected(selected);
    }, [startSearch, getUserSelected]);

    return (
        <UserSearch
            users={user ? [user] : []}
            usersSelected={onUsersSelected}
            isMulti={true}
            showAvatar
        />
    );
}
export default FeedSearchByUser;