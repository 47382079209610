import { ControllerHelper, EventController, EventUploadDto, PlaylistController, PostController, WikiArticleDownloadDto, WikiController } from 'collaboration-service';
import { Button, Divider, Loader } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { reducerBatchJob, reducerSetIfChanged, shallowCompare } from 'services/Helpers';
import { gotoNamedRoute } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { useImgI18N } from 'services/ImgI18N';
import { getWikiGroupIdForId } from 'services/StoreDependantHelpers';
import useVisibleTable from 'services/useVisibleTable';
import WikiArticleSection from 'views/Wiki/WikiArticleSection';

const mapper = (state: ApplicationState) => ({
    user: state.user,
    currentArticle: state.wikiState.currentArticle,
    categories: state.wikiState.categories,
    currentGroup: state.currentGroup,
});

export interface PreviewWikiArticleProps {
    directId: string;
}

export interface PreviewWikiArticleState {
    showPlaylistButton: boolean;
}

type WikiArticleActions =
    | { type: "batch", value: WikiArticleActions[] }
    | { type: "set_articleRead", value: boolean }
    | { type: "set_showPlaylistButton", value: boolean }
    ;

const reducer = (state: PreviewWikiArticleState, action: WikiArticleActions): PreviewWikiArticleState => {
    switch (action.type) {
        case "batch":
            return reducerBatchJob(state, action.value, reducer);
        case "set_showPlaylistButton":
            return reducerSetIfChanged(state, "showPlaylistButton", action.value);
        default:
            return state;
    }
}

const PreviewWikiArticle = (p: PreviewWikiArticleProps) => {

    const [state, dp] = React.useReducer(reducer, { showPlaylistButton: false });
    const [scrollHelper] = React.useState<ScrollHelper>(new ScrollHelper());
    const dispatch = useAppDispatch();
    const { user, currentArticle, currentGroup, categories, } = useSelector(mapper, shallowCompare);
    const { showPlaylistButton } = state;
    const { t } = useImgI18N("wiki");
    const id = p.directId;
    const { addElementRef: addRefs } = useVisibleTable({ userId: user?.id ?? "", groupId: currentGroup?.id ?? "", contextName: "PostId", contextValue: id });

    const { articleReadMethod } = React.useMemo(() =>
    ({

        articleReadMethod: async () => {
            if (currentArticle) {
                dp({
                    type: "batch", value: [
                        { type: "set_showPlaylistButton", value: false },
                        { type: "set_articleRead", value: true }
                    ]
                });
                await ControllerHelper.singleCall({ itemId: currentArticle.id }, PlaylistController.SetItemForPlaylists, true);
                const res = await ControllerHelper.singleCall({ itemId: currentArticle.id }, PlaylistController.GetPlaylistItemState, true);
                if (res) {
                    dispatch(Actions.setWikiPlaylistStatesForArticle(res.elements));
                };
            }
        },


    }), [currentArticle, dispatch, dp]);

    React.useEffect(() => {
        const load = async () => {
            if (currentGroup && id) {
                await getWikiGroupIdForId(id, false);
            }
        };
        load();
    }, [currentGroup, id]);

    React.useEffect(() => {
        const loadArticle = async (ignoreState?: boolean, afterUpdate?: () => void) => {
            const actions: AnyAction[] = [];
            let article: WikiArticleDownloadDto | undefined;
            if (!currentArticle || ignoreState) {
                const articleId = id;
                if (articleId) {
                    const result = await ControllerHelper.singleCall({ id: articleId as string }, PostController.GetSinglePost, true);
                    article = result as unknown as WikiArticleDownloadDto;
                    if (!article)
                        gotoNamedRoute("wiki");
                    else {
                        const ev: EventUploadDto = {
                            eventType: "PostViewed",
                            group_id: article.group_id,
                            payload: {
                                "PostId": article.id,
                            }
                        };
                        ControllerHelper.singleCall({ ev, createRestriction: { checkGroupId: true, checkPayload: ["PostId"], checkUserId: true, maxAgeInSeconds: 10 } }, EventController.PostEventWithRestriction);
                        PlaylistController.GetPlaylistItemState({ itemId: article.id }, (res) => {
                            dp({ type: "set_showPlaylistButton", value: res.data !== "Finished" })
                            dispatch(Actions.setWikiPlaylistStatesForArticle(res.elements));
                        });
                        actions.push(Actions.setWikiArticle(article, false));
                    }
                }
            }
            else { article = currentArticle; }

            if (article) {
                let newCategories = categories;
                if (!newCategories) {
                    newCategories = await ControllerHelper.singleCall({ groupid: article.group_id }, WikiController.GetWikiCategories);
                    actions.push(Actions.setWikiCategories(newCategories));
                }
            }
            if (actions.length > 0)
                dispatch(PluginActions.batchActions(actions));
            if (afterUpdate)
                afterUpdate();
        }

        const work = async () => {
            if (!currentGroup && id) {
                await getWikiGroupIdForId(id, false);
            }
            if (currentArticle?.id !== id) {
                await loadArticle(true);
            }
        };
        work();
    }, [currentGroup, id, categories, currentArticle, dispatch, scrollHelper]);

    return (
        <>
            {!currentArticle ?
                <Loader active={true} size="small" light />
                :
                <div >
                    {_.map(currentArticle.sections, (sec, c) => {
                        return (
                            <div key={sec.id} style={{ marginTop: c === 0 ? -30 : 0 }}>
                                <WikiArticleSection section={sec} scrollHelper={scrollHelper} addRefs={addRefs} />
                            </div>
                        );
                    })}
                    <Divider color="@veryLightGrey" />
                    {/* {
                        showPlaylistButton &&
                        <Button
                            kind="primary"
                            icon={"eye check"}
                            size="default"
                            content={t("mark article as read")}
                            onClick={articleReadMethod}
                        />
                    } */}
                </div >
            }
        </>
    );
}
export default PreviewWikiArticle;
