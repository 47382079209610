import * as React from 'react';
import { ImgAsyncSelect } from "imaginarity-react-ui"
import { UserController, UserSearchDto, UserShortInfoDownloadDto } from 'collaboration-service';
import UserTile from './UserTile';
import _ from 'lodash';

interface SelectData {
    value: string;
    label: string;
    data: UserShortInfoDownloadDto;
}
export interface UserSearchProps {
    users?: UserShortInfoDownloadDto[];    // previous value
    usersSelected?: (user: UserShortInfoDownloadDto[]) => void;
    isMulti?: boolean;
    showAvatar?: boolean;
    showEmail?: boolean;
    darkMode?: boolean;
    toHide?: string[];
    searchInfo?: Partial<UserSearchDto>;
    isDisabled?: boolean;
    isClearable?: boolean;
    linkToProfile?: boolean;
}

export interface UserSearchState {
    selectedUsers: UserShortInfoDownloadDto | UserShortInfoDownloadDto[];
}

// type AsyncUserSelecT = new () => ImgAsyncSelect<SelectData>;
// const AsyncUserSelect = ImgAsyncSelect as AsyncUserSelecT;

const UserSearch = (p: UserSearchProps) => {
    const { users, searchInfo, usersSelected, toHide, showAvatar, isMulti, isDisabled, showEmail, isClearable, linkToProfile } = p;
    const root = React.useMemo(() => document.getElementById("root"), []);


    const filter = React.useMemo(() => (o: any, inputValue: string) => toHide === undefined || _.findIndex(toHide, h => o.data.data.id === h) < 0, [toHide]);
    const changeOption = React.useMemo(() => (select: SelectData) => {
        //console.log("!!!!", select);
        return <UserTile user={select.data} showAvatar={showAvatar} showEmail={showEmail} linkToProfile={linkToProfile ?? false} />
    }, [showAvatar, showEmail, linkToProfile]);

    const userSelected = React.useMemo(() => (select?: SelectData | ReadonlyArray<SelectData>) => {
        let sel: SelectData[];
        if (select) {
            if (_.isArray(select)) {
                sel = select;   // is array
            }
            else
                sel = [select as SelectData];   // is not array

            if (usersSelected)
                usersSelected(_.map(sel, user => user.data));
        }
        else {
            if (usersSelected)
                usersSelected([]);
        }
    }, [usersSelected]);

    const mapToSelectData = React.useMemo(() => (u: UserShortInfoDownloadDto) => {
        return ({ value: u.id, label: u.firstName + " " + u.secondName, data: u });
    }, []);

    const loadOptions = React.useMemo(() => _.debounce((val: string, callback: (options: SelectData[]) => void) => {
        //console.log("val = " + val);
        return UserController.SearchUsers({ searchInfo: { ...searchInfo, general: val } }, (users) => {
            callback(_.map(users, mapToSelectData));
        });
    }, 500), [searchInfo, mapToSelectData]);

    const value = React.useMemo(() => users ? _.map(users, mapToSelectData) : undefined, [users, mapToSelectData]);

    return (
        <ImgAsyncSelect
            // trigger reloading on sarchInfo changes!
            key={JSON.stringify(searchInfo)}
            value={value}
            //cacheOptions
            defaultOptions
            isClearable={isClearable}
            isMulti={isMulti}
            onChange={userSelected}
            loadOptions={loadOptions}
            changeOption={changeOption}
            changeSingleValue={changeOption}
            changeMultiValue={changeOption}
            filterOption={filter}
            isDisabled={isDisabled}
            menuPortalTarget={root}
        />
    );
}
export default UserSearch;