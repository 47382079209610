import * as _ from "lodash";
function getUrl() {
    const loc = window.location;
    return `${loc.protocol}//${loc.hostname}/`;
};

type ServerLocation = "direct" | "test" | "excite" | "local" | "ppx" | "pri" | "img-wiki" | "develop" | "ppe-prod" | "ppx-test" | "demo";
type LoginServerLocation = "login-img" | "login-img-ppx" | "login-img-test" | "login-img-tempPPX" | "login-img-PPE" | "login-img-PRI" | "img-ad" | "none";
export type LoginServerType = "img" | "oidc-azuread"
interface LoginServer {
    address: string;
    appId: string;
    type: LoginServerType;
}

export default class Config {
    private static instance: Config;
    public static getInstance() {
        if (!this.instance)
            this.instance = new Config();
        return this.instance;
    }   private serverLocation: ServerLocation = "direct";private loginServerLocation: LoginServerLocation = "none";

    public get baseURL(): string {
        switch (this.serverLocation) {
            case "test":
                return "https://img-test.azurewebsites.net/";
            case "direct":
                return getUrl();
            case "excite":
                return "https://excite.digital/";
            case "local":
                return "http://localhost:58710/";
            case "ppx":
                return "https://ppx-campus.azurewebsites.net/";
            case "pri":
                return "https://pri-test.azurewebsites.net/";
            case "img-wiki":
                return "https://wiki.imaginarity.com/";
            case "develop":
                return "https://develop.imaginarity.com/";
            case "ppe-prod":
                return "https://ppe-porsche.com/";
            case "ppx-test":
                return "https://ppx-test.azurewebsites.net/";
            case "demo":
                return "https://demo.imaginarity.com/";

            default:
                return getUrl();
        }
    }

    public get frontendURL(): string {
        switch (this.serverLocation) {
            case "test":
                return "https://img-test.azurewebsites.net/index.html#";
            case "local":
                return "http://localhost:3001/#";
            default:
                return "https://www.smart-mobility.info/index.html#";
        }
    }

    private get loginServer(): LoginServer | undefined {
        switch (this.loginServerLocation) {
            case "login-img":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "6e13adeb-a72c-4178-92c1-b40569834aa7",
                    type: "img"
                }
            case "login-img-ppx":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "b4555ed1-7dff-4c97-aab7-4fbc1d53eab6",
                    type: "img"
                }
            case "login-img-test":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "0364ca06-66e9-41ae-b2e7-d72cfa6ac859",
                    type: "img"
                }
            case "login-img-tempPPX":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "a63227c8-cb39-4873-96bd-64bcd3b88834",
                    type: "img"
                }
            case "login-img-PPE":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "d1fd3ceb-0f43-4d7b-bd6e-5edc3e1e48ca",
                    type: "img"
                }
            case "login-img-PRI":
                return {
                    address: "https://login.imaginarity.com/",
                    appId: "edef183b-64aa-4d32-83af-d076837e152b",
                    type: "img"
                }
            case "img-ad":
                return {
                    address: `${window.location.protocol}//${window.location.host}/api/requestOidcLogin/`,
                    appId: "",
                    type: "oidc-azuread"
                }
            default:
                return undefined;
        }
    }

    public get loginServerAddress() {
        return this.loginServer?.address;
    }

    public get loginAppId() {
        return this.loginServer?.appId;
    }

    public get hasExternalLoginServer(): boolean {
        return this.loginServer !== undefined;
    }

    public get loginServerType() {
        return this.loginServer?.type
    }

    public get debug(): boolean {
        return false;
    }
}

export const languages = ["de-DE", "en-GB", "en-US", "es-ES", "fr-FR", "it-IT", "ja-JP", "ko-KR", "nl-NL", "pt-BR", "zh-CN", "zh-TW", "ru-RU", "pl-PL", "th-TH", "tr-TR"];
export const fromStandardTargetLngsForCreateProjectToExclude = ["pl-PL", "th-TH", "tr-TR"];

export const extendedLanguages: string[] = _.sortBy([...languages, ..._.uniq(_.filter(_.map(languages, l => {
    const s = l.split("-");
    if (s.length < 2)
        return undefined;
    return s[0];
}), f => f !== undefined))], s => s) as string[];


export const routeNames = {
    myhome: "myhome",
    playlist: "playlist",
    faq: "faq",
    wiki: "ppe",
    help: "help",
    cms: "cms",
    cmscontent: "cmscontent",
    chat: "chat",
    user: "user",
    content: "content",
    best: "best",
    quiz: "quiz",
    tube: "tube",
    podcast: "podcast",
    search: "search",
    scorm: "scorm",
    inbox: "inbox/:id?",
    playlistRoutes: {
        manage: "managePlaylists",
        edit: "editPlaylist/:id?",
        assign: "assignPlaylist/:id?",

    },
    cmsRoutes: {
        project: "project/:id?",
        template: "template/:id?",
        templates: "templates",
        roles: "roles",
        role: "role/:groupId?/:id?",
        directory: "directory/:id/:path*",
        translationBatches: "translationBatch/:id/:bId?",
        file: "file/:projectId/:id/:path*",
        archive: "archive/:projectId/:id/:path*",
        archiveFile: "archiveFile/:projectId/:id/:historyEntry/:path*",
        selectWorkflow: "workflows/:groupid?",
        createWorkflow: "workflow/:groupid/:id?",
        manageLocations: "locations",
        manageCategories: "categories",
        editCategory: "category/:groupid?/:id?",
        editLocation: "location/:groupid?/:id?",
    },
    contentRoutes: {
        category: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
        article: "art/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?"
    },
    bestRoutes: {
        detail: "detail/:id"
    },
    scormRoutes: {
        legals: "legals"
    },
    wikiRoutes: {
        faq: "faq",
        article: "article/:id/:secId?/:colId?",
        category: "category/:id/:sid?",
        translationSend: "translation/send",
        translationImport: "translation/import",
        copyWikiGroup: "copyWikiGroup",
        news: "addNews/:id?",
        editArticle: "edit/:id?",
    },
    chatRoutes: {
        add: "add",
        detail: "in/:id"
    },
    tubeRoutes: {
        category: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
        video: "video/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?"
    },
    podcastRoutes: {
        category: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
        audio: "audio/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?"
    },
    quizRoutes: {
        detail: "detail/:quizId",
        multiPlayergame: "multi/:quizId/:mode?",
        singlePlayergame: "single/:quizId/:mode?",
        joinPlayergame: "join/:gameId",
    },
}

export const externalLinks = {
    smicro: "https://porsche.smicro.de/itrainer",
    academy: "https://ppn.porsche.com/ppnmda/appforward.do?cn=palms_2",
    support: "https://skyway.porsche.com/jira/servicedesk/customer/portal/1581",
    pcnaMobileAcademy: "https://porschemobileacademy.com"
}

export const userProfileSettings = {
    allowChangeName: true,
    showShortDescription: true,
    showSlogan: true,
    showEmail: true,
    showPosition: true,
    showOrganisation: true,
    showSupervisor: true,
    showBirthday: true,
    showPersonalInfo: true,
    showUserStatusTooltips: true
}

export const globalSettings = {
    showGlobalGoBackButtonOnSidebarButtons: true,
    showDataPrivacy: false,
    showUserProfileDropdownButtons: true,
    editorToolTipImage: "https://cdn.imaginarity.com/public/pri/priLinkInfo.svg",
}

export const moduleBackgrounds = {
    placeHolder: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
    welcomeRoadBackground: "https://cdn.imaginarity.com/public/thepage/itBg.jpg",
    groupMailsBackground: "https://cdn.imaginarity.com/public/images/pagXreduced.svg",
    academyBackground: "https://cdn.imaginarity.com/public/thepage/Academy.jpg",
    mobileAcademyBackground: "https://cdn.imaginarity.com/public/thepage/pmaBg4.png",
    smicroBackground: "https://cdn.imaginarity.com/public/thepage/itBg.jpg",
    fallBackBackground: "https://cdn.imaginarity.com/public/thepage/Academy.jpg",
    supportBackground: "https://cdn.imaginarity.com/public/images/support.jpg",
}

export const bannerTop = {
    catImage: {
        qAndA: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
        globalSearchResult: "https://cdn.imaginarity.com/public/tube/logopagx.svg",
        wikiCopyGroup: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
        wikiSendTranslation: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
        wikiTranslationImport: "https://cdn.imaginarity.com/public/projectImages/pagXRedSquare.svg",
    },
    rightImage: {
        qAndA: "https://cdn.imaginarity.com/public/projectImages/imgQandAHeaderRight.png",
        globalSearchResult: "https://cdn.imaginarity.com/public/thepage/search_Header_right.png",
    },
    playlist: "https://cdn.imaginarity.com/public/images/playlistNewHeader.jpg",
    wikiArticleAlternative: "https://cdn.imaginarity.com/public/images/PpeArticleDemo.jpg",
    wikiCategoryAlternative: "https://cdn.imaginarity.com/public/images/PpeCategoryDemo.jpg"
}



export const CurrentSettings = {

    //  Playlist
    activateNewPlaylist: true,                      // If activateNewPlaylist is set to true and remains so permanently,  the files *_PlaylistItem.tsx (e.g. ContentPostPlaylistItem.tsx, QuizPlaylistItem.tsx, ...) in the views/Playlist/PlaylistItems folder and its links can be eleminated.
    showPlaylistInMenu: true,                       // (De-) Activate Playlist Menu Item in MenuBar and old MyHome PPX Systems
    nodeBarInfoUseSameWidths: true,

    // Global Search
    showNewGlobalSearchItem: false,                 // Modernised listing of the global entries found
    showGlobalSearchSwitchFilter: true,             // Switch instead of Checkboxed in global Search Sidebar Filter

    // MyHome
    showNewMyHomeLearningPath: false,               // (De-) Activate New MyHome Learning Path
    trainingRecords: false,                         // (De-) Activate MyHomeStart Training Records (Alexey)    
    // #################################################################################################################
    topTube: false,                                 // can be eliminated if showMyHomeLearningPath has been eliminated #
    topContent: false,                              // can be eliminated if showMyHomeLearningPath has been eliminated #
    topWiki: false,                                 // can be eliminated if showMyHomeLearningPath has been eliminated #
    news: false,                                    // can be eliminated if showMyHomeLearningPath has been eliminated #
    sidebarNavi: false,                             // can be eliminated if showMyHomeLearningPath has been eliminated #
    // #################################################################################################################

    // Moments
    showLuxuryRibbon: false,                        // will be reactivated, must NOT yet be eliminated
    showLuxuryRibbonInPostEdit: false,              // will be reactivated, must NOT yet be eliminated
    showLuxuryFilterInSidebar: false,               // will be reactivated, must NOT yet be eliminated
    showStandardsInNewMoments: true,                // must NOT yet be eliminated

}