import { getColor, ImgThemeConsumer, lightenColor } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
// https://github.com/moroshko/react-autosuggest
import Autosuggest, { RenderInputComponent, RenderSuggestionParams, RenderSuggestionsContainerParams, SuggestionsFetchRequestedParams, Theme } from 'react-autosuggest';

export interface AutocompleteSearchProps {
    search: string;
    searchChanged: (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => void;
    onEnterPressed?: () => void;
    suggestions: string[];
    onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams) => void;
    onSuggestionsClearRequested: () => void;
    onSuggestionSelected: (event: any, data: { suggestion: string }) => void;
    theme?: Theme;
    renderInputComponent?: RenderInputComponent<string>;
    placeholder: string;
    disabled?: boolean;
}
export default class AutocompleteSearch extends React.Component<AutocompleteSearchProps> {
    private showSuggestions = true;
    public render() {
        return (
            <ImgThemeConsumer>
                {(theme) => {
                    return (
                        <Autosuggest
                            inputProps={{
                                placeholder: this.props.placeholder,
                                value: this.props.search,
                                onChange: this.props.searchChanged,
                                onKeyDown: this.onSearchKeyDown,
                                type: "search",
                                disabled: this.props.disabled
                            }}
                            focusInputOnSuggestionClick={false}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestionsContainer={this.renderSuggestionContainer}
                            renderSuggestion={this.renderSuggestion}
                            suggestions={this.props.suggestions}
                            onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
                            onSuggestionSelected={this.props.onSuggestionSelected}
                            renderInputComponent={this.props.renderInputComponent}
                            theme={this.props.theme ?? {
                                input: {
                                    backgroundColor: lightenColor({ theme }, theme.backgroundColor, 110),
                                    color: getColor({ theme }, theme.color),
                                    height: 40,
                                    border: `1px solid ${lightenColor({ theme }, theme.backgroundColor, 80)}`,
                                    outline: "none",
                                    width: "100%",
                                    padding: "0 10px 0 30px",
                                    transition: "all 0.2s ease-out",
                                },
                                container: { position: "relative" },
                                suggestionsContainer: {
                                    display: "none",
                                    width: "100%"
                                    // width: "calc(100% - 2px)",
                                    // marginLeft: 1,
                                },
                                suggestionsContainerOpen: {
                                    backgroundColor: lightenColor({ theme }, theme.backgroundColor, 95),
                                    color: getColor({ theme }, theme.color),
                                    display: "block",
                                    position: "absolute",
                                    top: 40,
                                    border: `1px solid ${lightenColor({ theme }, theme.backgroundColor, 80)}`,
                                    fontSize: "1em",
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    zIndex: 3000
                                },
                                suggestionsList: {
                                    margin: 0,
                                    padding: 0,
                                    listStyleType: "none",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                },
                                suggestion: {
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingLeft: 10,
                                    lineHeight: "30px",
                                    cursor: "pointer",
                                },
                                suggestionHighlighted: {
                                    backgroundColor: getColor({ theme }, theme.colors.accent),
                                    color: getColor({ theme }, theme.colors.mainBackground),
                                }
                            }}
                        />
                    )
                }}
            </ImgThemeConsumer>
        );
    }
    private onSearchKeyDown = (event: React.KeyboardEvent<any>) => {
        if (this.props.onEnterPressed && event.keyCode === 13) {
            this.props.onEnterPressed();
            event.stopPropagation();
            this.showSuggestions = false;
        }
        else
            if (!this.showSuggestions)
                this.showSuggestions = true;
    }

    private getSuggestionValue = (suggestion: string) => {
        let terms = this.props.search.split(" ");
        const lastTerm = _.last(terms);
        if (lastTerm && lastTerm.length > 0) {
            let search: string;
            if (terms.length > 1)
                search = terms.slice(0, terms.length - 1).join(" ") + " " + suggestion;
            else
                search = suggestion;
            return search;
        } else
            return suggestion;
    }

    private renderSuggestion = (suggestion: string, params: RenderSuggestionParams) => (
        <span>{suggestion}</span>
    );

    private renderSuggestionContainer = (params: RenderSuggestionsContainerParams) =>
    (
        <div>
            {this.showSuggestions &&
                <div {...params.containerProps}>
                    {params.children}
                </div>
            }
        </div>
    );
}
