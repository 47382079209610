import { store } from "App";
import { BaseClient, CollaborationServiceSetup, ContentPostContentLocationDownloadDto, ContentPostDownloadDto, ControllerHelper, FileEntriesDownloadDto, FileEntryDownloadDto, I18NController, PodcastPostDownloadDto, QuizDownloadDto, TubePostDownloadDto } from "collaboration-service";
import { DashboardHelpers } from "components/Dashboard/Dashboard";
import { getCountryFlag } from 'imaginarity-react-ui';
import * as _ from "lodash";
import BackendCallCache from "services/BackendCallCache";
import Config, { languages } from "services/Config";
import { createReducerSetIfChanged, reducerBatchJob, reducerSetIfChanged } from "services/Helpers";
import { saveToken } from "services/Helpers/ApplicationStateHelpers";
import { ImgI18N } from "services/ImgI18N";
import { GameHubService } from "services/signalR/GameHubService";
import { getCurrentDirectory } from "views/CMS/CMSFilesGrid";
import { cmsFindIndexPathAndFileInCurrentFiles } from "views/CMS/Helpers/CMSHelpers";
import history from "../History";
import { Actions, ApplicationAction } from "./Actions";
import { AdminState, ApplicationState, BestPracticeState, CMSState, ChatState, FeedState, GlobalSearchState, MyHomeState, Ordering, PlaylistsState, QandAState, SearchAbleFeedState, SlideShowState, ThePageState, WikiState } from "./ApplicationState";
import { AppEntry } from "./MasterLayoutRouter";
import { PluginActions } from "./PluginStateHandling";
import MobileHelper from "services/MobileHelper";

export const initGlobalSearchFilter = () => ({
    includedPostTypes: ["Quiz", "PorscheMomentPost", "CommunityFeedPost", "TubePost", "BestPracticePost", "ContentPost", "Playlist", "NewsPost"],
    ignoredGroups: undefined
    //includedPostTypes: ["TubePost"],
});

const reducerSetIfChangedFeed = createReducerSetIfChanged<ApplicationState, FeedState>(s => s.feedState, s => ({ ...s, feedState: { ...s.feedState } }));
const reducerSetIfChangedSlideShow = createReducerSetIfChanged<ApplicationState, SlideShowState>(s => s.slideshowState, s => ({ ...s, slideshowState: { ...s.slideshowState } }));
const reducerSetIfChangedWiki = createReducerSetIfChanged<ApplicationState, WikiState>(s => s.wikiState, s => ({ ...s, wikiState: { ...s.wikiState } }));
const reducerSetIfChangedTube = createReducerSetIfChanged<ApplicationState, SearchAbleFeedState<TubePostDownloadDto> & { quality: "SD" | "HD" | "4K" }>(s => s.tubeState, s => ({ ...s, tubeState: { ...s.tubeState } }));
const reducerSetIfChangedPodcast = createReducerSetIfChanged<ApplicationState, SearchAbleFeedState<PodcastPostDownloadDto> & { quality: "SD" | "HD" | "4K" }>(s => s.podcastState, s => ({ ...s, podcastState: { ...s.podcastState } }));
const reducerSetIfChangedContent = createReducerSetIfChanged<ApplicationState, SearchAbleFeedState<ContentPostDownloadDto>>(s => s.contentState, s => ({ ...s, contentState: { ...s.contentState } }));
const reducerSetIfChangedQuiz = createReducerSetIfChanged<ApplicationState, SearchAbleFeedState<QuizDownloadDto>>(s => s.quizState, s => ({ ...s, quizState: { ...s.quizState } }));

const reducerSetIfChangedGlobalSearch = createReducerSetIfChanged<ApplicationState, GlobalSearchState>(s => s.globalSearchState, s => ({ ...s, globalSearchState: { ...s.globalSearchState } }));
const reducerSetIfChangedPlaylists = createReducerSetIfChanged<ApplicationState, PlaylistsState>(s => s.playlistsState, s => ({ ...s, playlistsState: { ...s.playlistsState } }));
const reducerSetIfChangedBestPractice = createReducerSetIfChanged<ApplicationState, BestPracticeState>(s => s.bestpracticeState, s => ({ ...s, bestpracticeState: { ...s.bestpracticeState } }));
const reducerSetIfChangedThePage = createReducerSetIfChanged<ApplicationState, ThePageState>(s => s.thePageState, s => ({ ...s, thePageState: { ...s.thePageState } }));
const reducerSetIfChangedCMS = createReducerSetIfChanged<ApplicationState, CMSState>(s => s.cmsState, s => ({ ...s, cmsState: { ...s.cmsState } }));
const reducerSetIfChangedChat = createReducerSetIfChanged<ApplicationState, ChatState>(s => s.chatState, s => ({ ...s, chatState: { ...s.chatState } }));
const reducerSetIfChangedAdmin = createReducerSetIfChanged<ApplicationState, AdminState>(s => s.adminState, s => ({ ...s, adminState: { ...s.adminState } }));
const reducerSetIfChangedQAndA = createReducerSetIfChanged<ApplicationState, QandAState>(s => s.qandaState, s => ({ ...s, qandaState: { ...s.qandaState } }));
const reducerSetIfChangedMyHome = createReducerSetIfChanged<ApplicationState, MyHomeState>(s => s.myHomeState, s => ({ ...s, myHomeState: { ...s.myHomeState } }));


export const initGlobalSearchSytems = () => ({
    posts: true,
    postsName: "post",
    smicro: true,
    smicroName: "iTrainer",
    wiki: true,
    wikiName: "wiki",
    palms: true,
    palmsName: "palms"
});

const initState = (): ApplicationState => {
    // setup back-end connection
    const client = new BaseClient(Config.getInstance().baseURL);
    CollaborationServiceSetup.Initialize(client);
    client.setErrorHandler(401, () => {
        saveToken(null);
        store.dispatch(Actions.removeCurrentUser());
    })
    // setup translations
    // ImgI18NSingleton.init({
    //     addPath: "api/i18n",
    //     saveMissing: true,
    //     loadPath: "api/i18n",
    //     baseLanguage: "en",
    //     initNamespace: "collaboration",
    //     client,
    //     debug: 10,
    //     searchPrefix: "@"
    // });

    ImgI18N.init({
        fallbackPrefix: "@",
        baseLanguage: "en-GB",
        standardNamespace: "collaboration",
        getTranslations: (lng: string) => ControllerHelper.promise({ lng }, I18NController.GetLng),
        addTranslations: (lng: string, namespace: string, key: string, translation: string) => new Promise<void>(r => {
            //console.log(`adding => ${lng} | ${namespace} | ${key} => ${translation}`)
            I18NController.PostNew({
                lng,
                ns: namespace,
                list: [
                    {
                        key: key.toLowerCase(),
                        value: translation
                    }
                ]
            })
            r()
        }),
        getCountryFlag,
        dontUseBaseLanguage: false,
        //dontUseBaseLanguage: process.env.NODE_ENV !== 'production',
    });

    MobileHelper.getInstance().startup();

    return {
        hasFocus: true,
        inboxState: {},
        userTasks: { posts: [] },
        loadingDataTableState: {
            showFilterOnLoadingDataTable: true,
        },
        gameHubService: new GameHubService(),
        i18n: { currentLanguage: "de" },
        modules: [],
        activeModules: [],
        appEntries: [],
        route: history.location.hash,
        feedState: {
            trending: false,
            rightSidebar: true
        },
        wikiState: {
            shownReferences: [],
            onNewsClose: () => { },
        },
        tubeState: {
            viewStats: [],
            tableView: false,
            filter: {
                includedPostTypes: ["TubePost"]
            },
            quality: "HD",
            currentCategoryStack: [],
        },
        podcastState: {
            viewStats: [],
            tableView: false,
            filter: {
                includedPostTypes: ["PodcastPost"]
            },
            quality: "HD",
            currentCategoryStack: [],
        },
        contentState: {
            viewStats: [],
            filter: {
                includedPostTypes: ["ContentPost"]
            },
            currentCategoryStack: [],
        },
        quizState: {
            viewStats: [],
            filter: {
                includedPostTypes: ["Quiz"]
            },
            currentCategoryStack: [],

        },
        globalSearchState: {
            viewStats: [],
            results: [],
            search: "",
            globalSearchFilter: initGlobalSearchFilter(),
            globalSearchSystems: initGlobalSearchSytems(),
        },
        slideshowState: {},
        playlistsState: {
            ordering: Ordering.date,
        },
        bestpracticeState: {},
        chatState: {},
        thePageState: {
            editMode: false,
            addElement: false,
            backendCache: new BackendCallCache(),
            detailIdStack: [],
            preDetailFilterStack: [],
            filter: {
                start: new Date("2000-01-01T00:00:00"),
                end: new Date(),
                //userId: "7b8b027d-9470-4f94-b01f-b931531198d1", // manager PME
                userId: "ffc9bbe7-646b-4ac0-8c1c-b9da79418b66",
                //userId: "1a52b62e-4a85-4f4b-bde7-0ec25d2ace87", //single user
                //userId: "16b219de-ddd1-4a75-8583-31c15571bd5a", // single user with pgcs
            }
        },
        cmsState: {
            filePosts: {},
            workflows: [],
            wfTypeIsGroup: true,
            showTasks: true,
            selectedDirs: [],
            selectedFiles: [],
        },
        adminState: {},
        contentLanguage: ImgI18N.getInstance().currentLanguage,

        qandaState: {},
        myHomeState: {
            showDetailedPage: false,
            sortedBy: undefined,
            sortAscending: true,
            progressOnTrack: true,
            progressIncomplete: true,
            progressOverdue: true,
            progressCompleted: true,
        },
    };
}
export const returnNewState = (state: ApplicationState) => {
    return {
        ...state,
        activeModules: _.filter(state.modules, m => !m.available || m.available(state)),
    }
}

export const reducer = (state: ApplicationState = initState(), action: ApplicationAction): ApplicationState => {
    // if (action.type >= 1 + 1000 && action.type <= 6999 + 1000) {
    //     let key: string | undefined;
    //     const t = _.forEach(ActionTypes, (v, k) => {
    //         if (v === action.type)
    //             key = k;
    //     });
    //     if (!key)
    //         console.log("******************** action => ", action);
    //     else {
    //         const { type, ...a } = action;
    //         console.log(`******************** action of type"${key}"  => `, a);

    //     }
    //     console.log("state => ", _.clone(state));
    // }
    let contentLanguage = state.contentLanguage;
    switch (action.type) {
        case "SET_FOCUS":
            return reducerSetIfChanged(state, "hasFocus", action.value);
        case "BATCH_ACTIONS":
            return reducerBatchJob(state, action.value, reducer);
        case "EXECUTE":
            action.value();
            return state;
        case "SET_LOCATION_SEARCH":
            return reducerSetIfChanged(state, "search", action.value);
        case "SET_POSTS":
            return returnNewState(reducerSetIfChanged(state, "posts", action.value));
        case "ADD_MODULES":
            {
                const mods = _.clone(state.modules);
                const a = _.isArray(action.value) ? action.value : [action.value];
                _.forEach(a, m => {
                    const idx = _.findIndex(mods, mm => mm.name === m.name);
                    if (idx < 0)
                        mods.push(m);
                })
                return returnNewState({
                    ...state,
                    modules: mods,
                });
            }
        case "ADD_APPENTRIES":
            {
                const a = _.isArray(action.value) ? action.value : [action.value];
                const appEntries: AppEntry[] = [...state.appEntries, ...a];
                const rNames: string[] = [];
                _.forEach(appEntries, a => {
                    if (a.routeName)
                        rNames.push(a.routeName);
                    _.forEach(a.views, v => {
                        if (v.routeName)
                            rNames.push(v.routeName);
                    })
                });
                const ordered = _.orderBy(rNames, r => r);
                const double: string[] = [];
                for (let i = 1; i < ordered.length; i++) {
                    if (ordered[i - 1] === ordered[i])
                        double.push(ordered[i]);
                }
                if (double.length === 0)
                    return returnNewState({
                        ...state,
                        appEntries: [...state.appEntries, ...a]
                    });
                else {
                    console.log("can not add app entries! the following route names are not unique : ", double);
                }
                return state;
            }
        case "SET_MODULE_BADGE":
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.value.module)
                        m.badge = (action.value.add ? (m.badge ?? 0) : 0) + action.value.badge;
                    return m;
                })
            });
        case "SET_MODULE_OPEN":
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.value.module)
                        m.open = action.value.open;
                    return m;
                })
            });
        case "OPEN_SINGLE_MODULE":
            return returnNewState({
                ...state,
                modules: _.map(state.modules, m => {
                    if (m === action.value.moduleToOpen)
                        m.open = true;
                    else if (m.open && _.findIndex(action.value.modulesToClose, mtc => mtc === m) >= 0)
                        m.open = false;
                    return m;
                })
            });
        case "SET_USER_GROUPS":
            return returnNewState(reducerSetIfChanged(state, "userGroups", action.value));
        case "SET_ADMIN_GROUPS":
            return returnNewState(reducerSetIfChanged(state, "adminGroups", action.value));
        case "SET_CURRENT_APP_ENTRY_AND_VIEW":
            if (!_.isEqual(state.currentAppEntry, action.value.appEntry) || !_.isEqual(state.currentAppView, action.value.appView))
                return returnNewState({
                    ...state,
                    currentAppEntry: action.value.appEntry,
                    currentAppView: action.value.appView,
                });
            return state;
        case "SET_PLATFORM_FEATURES":
            return returnNewState(reducerSetIfChanged(state, "features", action.value));

        case "REQUEST_UPDATE_RECEIVED":
            return returnNewState({
                ...state,
                requestUpdate: action.value,
            });
        case "CURRENT_USER_RECEIVED":
            if (action.value?.userSettings?.language) {
                const ul = action.value.userSettings.language;
                //console.log("ul => ", ul);
                let l: string | undefined = ul;
                if (ul) {
                    l = _.find(languages, ll => ll === ul);
                    // console.log("find l =>", l);
                    if (!l) {
                        l = _.find(languages, ll => ll.startsWith(ul));
                    }
                }
                else
                    l = languages[0];
                // console.log("lng => ", l, ",ul => ", ul, ", lngs => ", languages);
                if (l) {
                    const ll = l;
                    setTimeout(() => { ImgI18N.getInstance().currentLanguage = ll; }, 10);
                    contentLanguage = l;
                }

            }
            return returnNewState({
                ...state,
                user: action.value,
                contentLanguage,
            });
        case "CALCULATE_FILTERED_APP_ENTRIES":
            const filteredAppEntries = _.filter(state.appEntries, a => a.accessCheck !== undefined ? a.accessCheck(state.user, state.userGroups, state.adminGroups) : true);
            return returnNewState({
                ...state,
                filteredAppEntries
            });
        case "SET_USER_TASKS":
            return reducerSetIfChanged(state, "userTasks", action.value);
        case "SET_MASTER_LAYOUT_EXTENSION":
            return reducerSetIfChanged(state, "masterLayoutRootExtension", action.value);
        case "REMOVE_CURRENT_USER":
            return returnNewState({
                ...state,
                user: undefined,
                doLogin: true
            });
        case "SET_CURRENT_GROUP":
            if (state.currentGroup?.id !== action.value?.id)
                return {
                    ...state,
                    currentGroup: action.value,
                };
            return state;
        case "SET_ROUTE_AND_PARAMS_AND_RENDERER":
            if (!_.isEqual(action.value.user, state.user) && action.value.user?.userSettings?.language) {
                const ul = action.value.user.userSettings.language;
                console.log("ul => ", ul);
                let l: string | undefined = ul;
                if (ul) {
                    l = _.find(languages, ll => ll === ul);
                    // console.log("find l =>", l);
                    if (!l) {
                        l = _.find(languages, ll => ll.startsWith(ul));
                    }
                }
                else
                    l = languages[0];
                // console.log("lng => ", l, ",ul => ", ul, ", lngs => ", languages);
                if (l) {
                    const ll = l;
                    setTimeout(() => { ImgI18N.getInstance().currentLanguage = ll; }, 10);
                    contentLanguage = l;
                }

            }
            //console.log('action => ', action);
            return returnNewState({
                ...state,
                route: action.value.route,
                params: action.value.params,
                search: action.value.search,
                user: action.value.user,
                doLogin: action.value.doLogin,
                noLogin: action.value.noLogin,
                contentLanguage,
            });

        case "UPDATE_POST":
            {
                const i = _.findIndex(state.posts, p => p.id === action.value.id);
                const p = _.clone(state.posts) ?? [];
                if (i >= 0) {
                    if (!_.isEqual(p[i], action.value)) {
                        p.splice(i, 1, action.value);
                        return {
                            ...state,
                            posts: p
                        };
                    }
                }
                else {
                    p.unshift(action.value);
                    return {
                        ...state,
                        posts: p
                    };
                }
            }
            return state;
        case "REMOVE_POST":
            {
                const i = _.findIndex(state.posts, p => p.id === action.value);
                if (i >= 0) {
                    const p = _.clone(state.posts!);
                    p.splice(i, 1);
                    return {
                        ...state,
                        posts: p
                    };
                }
            }
            return state;
        case "SET_CONTENT_LANGUAGE":
            return reducerSetIfChanged(state, "contentLanguage", action.value);
        case "SET_SEARCH_RESULT_POS":
            return reducerSetIfChanged(state, "searchResultPos", action.value);
        case "SET_SEARCH_RESULT_COUNT":
            return reducerSetIfChanged(state, "searchResultIds", action.value);
        case "ADD_SEARCH_RESULT_IDS": {
            if ((action.value ?? []).length > 0) {
                //console.log("Reducer.tsx::333 => state", action);
                return reducer(state, { type: "SET_SEARCH_RESULT_COUNT", value: _.uniq([...(state.searchResultIds ?? []), ...(action.value ?? [])]) });
            }
            return state;
        }
        case "SET_NEED_SAVE":
            return reducerSetIfChanged(state, "needSave", action.value);
        case "SET_CAN_SAVE":
            return reducerSetIfChanged(state, "canSave", action.value);
        case "SET_ACTIVE":
            return reducerSetIfChanged(state, "active", action.value);
        case "SET_SAVE":
            return reducerSetIfChanged(state, "save", action.value);
        case "SET_ADD":
            return reducerSetIfChanged(state, "add", action.value);
        case "SET_CANCEL":
            return reducerSetIfChanged(state, "cancel", action.value);
        case "SET_SAVE_AND_CANCEL":
            return reducer(state, PluginActions.batchActions([Actions.setSave(action.value?.save), Actions.setCancel(action.value?.cancel)]));
        case "SET_SCROLLTO":
            return reducerSetIfChanged(state, "scrollTo", action.value);


        /* Wiki stuff */
        case "SET_WIKI_GROUP_ID":
            return reducerSetIfChangedWiki(state, "groupId", action.value);
        case "SET_WIKI_CATEGORIES":
            return reducerSetIfChangedWiki(state, "categories", action.value);
        case "SET_WIKI_REFERENCE_LNG":
            return reducerSetIfChangedWiki(state, "referenceLanguage", action.value);
        case "SET_WIKI_CATEGORY":
            return reducerSetIfChangedWiki(state, "category", action.value);
        case "SET_WIKI_CATEGORY_ID":
            return reducerSetIfChangedWiki(state, "categoryId", action.value);
        case "SET_WIKI_ARTICLE":
            if ((action.value?.editMode !== undefined && state.wikiState.editMode !== action.value?.editMode) || !_.isEqual(state.wikiState.currentArticle, action.value?.wikiArticle)) {
                return {
                    ...state,
                    wikiState: {
                        ...state.wikiState,
                        currentArticle: action.value?.wikiArticle,
                        editMode: action.value?.editMode,
                        shownReferences: [],
                    }
                };
            }
            return state;
        case "SET_WIKI_ARTICLE_BREADCRUMBS":
            return reducerSetIfChangedWiki(state, "breadcrumbs", action.value);
        // case "SET_WIKI_SCROLL_TO":
        //     return returnNewState({
        //         ...state,
        //         wikiState: {
        //             ...state.wikiState,
        //             scrollTo: action.scrollTo
        //         }
        //     });
        case "SET_WIKI_SEARCH":
            return reducerSetIfChangedWiki(state, "search", action.value);
        case "SET_WIKI_NEWS_IN_EDIT":
            return reducerSetIfChangedWiki(state, "inNewsEdit", action.value);
        case "SET_WIKI_FOCUS_TARGET":
            return reducerSetIfChangedWiki(state, "focusTarget", action.value);
        case "SET_WIKI_SAVE_ARTICLE":
            return reducerSetIfChangedWiki(state, "saveArticle", action.value);
        case "SET_WIKI_PLS_FOR_ARTICLE":
            return reducerSetIfChangedWiki(state, "playlistStatesForArticle", action.value);
        case "TOGGLE_WIKI_SHOWN_REFERENCES":
            {
                const shownReferences = _.clone(state.wikiState.shownReferences);
                const idx = _.findIndex(shownReferences, s => s === action.value);
                if (idx >= 0)
                    shownReferences.splice(idx, 1);
                else
                    shownReferences.push(action.value);
                return {
                    ...state,
                    wikiState: {
                        ...state.wikiState,
                        shownReferences,
                    }
                };
            }
        case "SET_WIKI_SET_SHOW_INTRO":
            return reducerSetIfChangedWiki(state, "showIntro", action.value);
        case "SET_WIKI_SAVEABLE":
            return reducerSetIfChangedWiki(state, "saveable", action.value);
        case "SET_WIKI_NEWS_CLOSE":
            return reducerSetIfChangedWiki(state, "onNewsClose", action.value);
        case "SET_WIKI_NEWS_DELETE":
            return reducerSetIfChangedWiki(state, "onNewsDelete", action.value);
        case "SET_WIKI_NEWS_SAVE":
            return reducerSetIfChangedWiki(state, "onNewsSave", action.value);
        /* Tube stuff */
        case "SET_TUBE_CURRENT_POST":
            return reducerSetIfChangedTube(state, "currentPost", action.value);
        case "SET_TUBE_CURRENT_POSTS":
            return reducerSetIfChangedTube(state, "currentPosts", action.value);
        case "SET_TUBE_CURRENT_CATEGORY_STACK":
            return reducerSetIfChangedTube(state, "currentCategoryStack", action.value);
        case "SET_TUBE_CATEGORIES":
            return reducerSetIfChangedTube(state, "categories", action.value);
        case "SET_TUBE_VIEW_STATS":
            return reducerSetIfChangedTube(state, "viewStats", action.value);
        case "ADD_TUBE_VIEW_STATS":
            return reducerSetIfChangedTube(state, "viewStats", [...state.tubeState.viewStats, ...action.value]);
        case "SET_TUBE_SEARCH_RESET":
            return reducerSetIfChangedTube(state, "onResetSearch", action.value);
        case "SET_TUBE_VIEW_MODE":
            return reducerSetIfChangedTube(state, "viewMode", action.value);
        case "SET_TUBE_SEARCH":
            return reducerSetIfChangedTube(state, "search", action.value);
        case "SET_TUBE_FOCUS_TARGET":
            return reducerSetIfChangedTube(state, "focusTarget", action.value);
        case "SET_TUBE_TABLEVIEW":
            return reducerSetIfChangedTube(state, "tableView", action.value);
        case "SET_TUBE_QUALITY":
            return reducerSetIfChangedTube(state, "quality", action.value);
        case "SET_TUBE_FILTER":
            {
                const oldF = _.clone(state.tubeState.filter);
                const f = { ...oldF, ...action.value };
                if (!_.isEqual(f, state.tubeState.filter))
                    return {
                        ...state,
                        tubeState: {
                            ...state.tubeState,
                            filter: f
                        }
                    };
            }
            return state;


        /* podcast stuff */
        case "SET_PODCAST_CURRENT_POST":
            return reducerSetIfChangedPodcast(state, "currentPost", action.value);
        case "SET_PODCAST_CURRENT_POSTS":
            return reducerSetIfChangedPodcast(state, "currentPosts", action.value);
        case "SET_PODCAST_CURRENT_CATEGORY_STACK":
            return reducerSetIfChangedPodcast(state, "currentCategoryStack", action.value);
        case "SET_PODCAST_CATEGORIES":
            return reducerSetIfChangedPodcast(state, "categories", action.value);
        case "SET_PODCAST_VIEW_STATS":
            return reducerSetIfChangedPodcast(state, "viewStats", action.value);
        case "ADD_PODCAST_VIEW_STATS":
            return reducerSetIfChangedPodcast(state, "viewStats", [...state.tubeState.viewStats, ...action.value]);
        case "SET_PODCAST_SEARCH_RESET":
            return reducerSetIfChangedPodcast(state, "onResetSearch", action.value);
        case "SET_PODCAST_VIEW_MODE":
            return reducerSetIfChangedPodcast(state, "viewMode", action.value);
        case "SET_PODCAST_SEARCH":
            return reducerSetIfChangedPodcast(state, "search", action.value);
        case "SET_PODCAST_FOCUS_TARGET":
            return reducerSetIfChangedPodcast(state, "focusTarget", action.value);
        case "SET_PODCAST_TABLEVIEW":
            return reducerSetIfChangedPodcast(state, "tableView", action.value);
        case "SET_PODCAST_QUALITY":
            return reducerSetIfChangedPodcast(state, "quality", action.value);
        case "SET_PODCAST_FILTER":
            {
                const oldF = _.clone(state.podcastState.filter);
                const f = { ...oldF, ...action.value };
                if (!_.isEqual(f, state.podcastState.filter))
                    return {
                        ...state,
                        podcastState: {
                            ...state.podcastState,
                            filter: f
                        }
                    };
            }
            return state;

        /* Content stuff */

        case "SET_CONTENT_CURRENT_POST":
            return reducerSetIfChangedContent(state, "currentPost", action.value);
        case "SET_CONTENT_CURRENT_POSTS":
            return reducerSetIfChangedContent(state, "currentPosts", action.value);
        case "SET_CONTENT_CURRENT_CATEGORY_STACK":
            return reducerSetIfChangedContent(state, "currentCategoryStack", action.value);
        case "SET_CONTENT_CATEGORIES":
            return reducerSetIfChangedContent(state, "categories", action.value);
        case "SET_CONTENT_VIEW_STATS":
            return reducerSetIfChangedContent(state, "viewStats", action.value);
        case "ADD_CONTENT_VIEW_STATS":
            return reducerSetIfChangedContent(state, "viewStats", [...state.contentState.viewStats, ...action.value]);
        case "SET_CONTENT_SEARCH_RESET":
            return reducerSetIfChangedContent(state, "onResetSearch", action.value);
        case "SET_CONTENT_VIEW_MODE":
            return reducerSetIfChangedContent(state, "viewMode", action.value);
        case "SET_CONTENT_SEARCH":
            return reducerSetIfChangedContent(state, "search", action.value);
        case "SET_CONTENT_FILTER_TYPE":
            return reducerSetIfChangedContent(state, "contentFilterTypes", action.value);
        case "SET_CONTENT_FILTER":
            {
                const oldF = _.clone(state.contentState.filter);
                const f = { ...oldF, ...action.value };
                if (!_.isEqual(f, state.contentState.filter))
                    return {
                        ...state,
                        contentState: {
                            ...state.contentState,
                            filter: f
                        }
                    };
            }
            return state;

        /* Quiz stuff */

        case "SET_QUIZ_CURRENT_POST":
            return reducerSetIfChangedQuiz(state, "currentPost", action.value);
        case "SET_QUIZ_CURRENT_POSTS":
            return reducerSetIfChangedQuiz(state, "currentPosts", action.value);
        case "SET_QUIZ_CURRENT_CATEGORY_STACK":
            return reducerSetIfChangedQuiz(state, "currentCategoryStack", action.value);
        case "SET_QUIZ_CATEGORIES":
            return reducerSetIfChangedQuiz(state, "categories", action.value);
        case "SET_QUIZ_VIEW_STATS":
            return reducerSetIfChangedQuiz(state, "viewStats", action.value);
        case "ADD_QUIZ_VIEW_STATS":
            return reducerSetIfChangedQuiz(state, "viewStats", [...state.quizState.viewStats, ...action.value]);
        case "SET_QUIZ_SEARCH_RESET":
            return reducerSetIfChangedQuiz(state, "onResetSearch", action.value);
        case "SET_QUIZ_VIEW_MODE":
            return reducerSetIfChangedQuiz(state, "viewMode", action.value);
        case "SET_QUIZ_FILTER":
            {
                const oldF = _.clone(state.quizState.filter);
                const f = { ...oldF, ...action.value };
                if (!_.isEqual(f, state.quizState.filter))
                    return {
                        ...state,
                        quizState: {
                            ...state.quizState,
                            filter: f
                        }
                    };
            }
            return state;

        /* Playlist stuff */
        case "SET_PL_PLAYLIST_STATES":
            return reducerSetIfChangedPlaylists(state, "playlistStates", action.value);
        case "SET_PL_PLAYLIST_STATES_TOPIC":
            return reducerSetIfChangedPlaylists(state, "playlistStatesTopic", action.value);
        case "SET_PL_EDIT_MODE":
            return reducerSetIfChangedPlaylists(state, "editMode", action.value);
        case "SET_PL_SAVE":
            return reducerSetIfChangedPlaylists(state, "save", action.value);
        case "SET_PL_MISSING":
            return reducerSetIfChangedPlaylists(state, "missing", action.value);

        case "SET_PLAYLIST_RIGHT_SIDEBAR":
            return reducerSetIfChangedPlaylists(state, "rightSidebar", action.value);
        case "SET_PLAYLIST_LEFT_SIDEBAR":
            return reducerSetIfChangedPlaylists(state, "leftSidebar", action.value);

        case "SET_PLAYLIST_FILTER":
            return reducerSetIfChangedPlaylists(state, "filter", action.value);
        case "SET_PLAYLIST_FILTER_COUNT":
            return reducerSetIfChangedPlaylists(state, "filterCount", action.value);
        case "SET_PLAYLIST_ORDERING":
            return reducerSetIfChangedPlaylists(state, "ordering", action.value);

        /* Slideshow stuff */
        case "SET_SS_SLIDESHOW":
            return reducerSetIfChangedSlideShow(state, "currentSlideShow", action.value);
        case "SET_SS_LNG_SLIDES":
            return reducerSetIfChangedSlideShow(state, "lngSlides", action.value);
        case "SET_SS_SLIDE":
            return reducerSetIfChangedSlideShow(state, "slide", action.value);
        case "SET_SS_TEMPLATES":
            return reducerSetIfChangedSlideShow(state, "templates", action.value);
        case "SET_SS_SELECT_TEMPLATE":
            return reducerSetIfChangedSlideShow(state, "selectionTemplate", action.value);
        case "SET_SS_BACKGROUNDS":
            return reducerSetIfChangedSlideShow(state, "backgrounds", action.value);
        case "SET_SS_COPY":
            return reducerSetIfChangedSlideShow(state, "copy", action.value);
        case "SET_SS_IMPORT_AS":
            return reducerSetIfChangedSlideShow(state, "importAs", action.value);
        case "SET_SS_UPDATE_SLIDE":
            return reducerSetIfChangedSlideShow(state, "updateSlide", action.value);

        /* Best Practice stuff */
        case "SET_BEST_POST":
            return reducerSetIfChangedBestPractice(state, "currentPost", action.value);
        case "SET_BEST_REPORT_IMP":
            return reducerSetIfChangedBestPractice(state, "reportImplementation", action.value);
        case "SET_BEST_EDIT":
            return reducerSetIfChangedBestPractice(state, "inEdit", action.value);
        case "SET_BEST_ORDERING":
            return reducerSetIfChangedBestPractice(state, "ordering", action.value);
        case "SET_BEST_FILTER":
            return reducerSetIfChangedBestPractice(state, "filter", action.value);
        case "SET_BEST_SEARCH_TERM":
            return reducerSetIfChangedBestPractice(state, "searchTerm", action.value);
        case "SET_BEST_RIGHT_SIDEBAR":
            return reducerSetIfChangedBestPractice(state, "rightSidebar", action.value);
        case "SET_BEST_POSTS":
            if (!_.isEqual(action.value?.posts, state.bestpracticeState.posts) || action.value?.token !== state.bestpracticeState.postToken)
                return {
                    ...state,
                    bestpracticeState: {
                        ...state.bestpracticeState,
                        postToken: action.value?.token,
                        posts: action.value?.posts
                    }
                };
            return state;
        case "SET_BEST_OWN_POSTS":
            if (!_.isEqual(action.value?.posts, state.bestpracticeState.ownPosts) || action.value?.token !== state.bestpracticeState.ownPostToken)
                return {
                    ...state,
                    bestpracticeState: {
                        ...state.bestpracticeState,
                        ownPostToken: action.value?.token,
                        ownPosts: action.value?.posts
                    }
                };
            return state;
        case "SET_BEST_CATS_AND_LOCS":
            if (!_.isEqual(action.value?.categories, state.bestpracticeState.categories) || !_.isEqual(action.value?.locations, state.bestpracticeState.locations))
                return {
                    ...state,
                    bestpracticeState: {
                        ...state.bestpracticeState,
                        locations: action.value?.locations,
                        categories: action.value?.categories,
                    }
                };
            return state;
        // case "SET_BEST_SCROLL_TO":
        //     return returnNewState({
        //         ...state,
        //         bestpracticeState: {
        //             ...state.bestpracticeState,
        //             scrollTo: action.scrollTo
        //         }
        //     });
        case "SET_BEST_FOCUS_TARGET":
            return reducerSetIfChangedBestPractice(state, "focusTarget", action.value);
        case "REMOVE_BEST_POST": {
            const p = _.clone(state.bestpracticeState.posts) ?? [];
            const idx = _.findIndex(p, pp => pp.id === action.value);
            if (idx >= 0) {
                p.splice(idx, 1);
                return {
                    ...state,
                    bestpracticeState: {
                        ...state.bestpracticeState,
                        posts: p
                    }
                };
            }
            return state;
        }
        case "SET_BEST_FACETS":
            return reducerSetIfChangedBestPractice(state, "facets", action.value);

        case "SET_THE_PAGE_EDITMODE":
            return reducerSetIfChangedThePage(state, "editMode", action.value);
        case "SET_THE_PAGE_ADD_ELEMENT":
            return reducerSetIfChangedThePage(state, "addElement", action.value);
        case "SET_THE_PAGE_DB_TEMPLATES":
            return reducerSetIfChangedThePage(state, "dashboardElementTemplates", action.value);
        case "SET_THE_PAGE_DASHBOARDS":
            return reducerSetIfChangedThePage(state, "dashboards", action.value);
        case "SET_THE_PAGE_SEL_DASHBOARD":
            {
                const dashboards = _.clone(state.thePageState.dashboards);
                const idx = _.findIndex(dashboards, d => d.id === action.value.id);
                if (idx >= 0)
                    dashboards?.splice(idx, 1, action.value);

                return {
                    ...state,
                    thePageState: {
                        ...state.thePageState,
                        dashboard: action.value,
                        dashboards: dashboards,
                    }
                };
            }
        case "SET_THE_PAGE_EDIT_LAYOUTS":
            return reducerSetIfChangedThePage(state, "editLayouts", action.value);
        case "SET_THE_PAGE_EDIT_DB":
            return reducerSetIfChangedThePage(state, "editDashboard", action.value);
        case "SET_THE_PAGE_ADD_TEMP_ELEMENT":
            {
                const res = DashboardHelpers.AddToLayouts(action.value?.template, action.value?.mouseEvent, state.thePageState.editLayouts, state.thePageState.editDashboard);
                if (res)
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            editLayouts: res
                        }
                    };

            }
            return state;
        case "SET_THE_PAGE_CLEAR_TEMP_ELEMENTS":
            {
                const res = DashboardHelpers.ClearTempFromLayouts(state.thePageState.editLayouts);
                if (res)
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            editLayouts: res
                        }
                    };
            }
            return state;
        case "SET_THE_PAGE_FINALIZE_TEMP_ELEMENT":
            {
                const res = DashboardHelpers.AddToDashboard(action.value, state.thePageState.editLayouts, state.thePageState.editDashboard);
                if (res)
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            editLayouts: res.layouts,
                            editDashboard: res.dashboard,
                        }
                    };
            }
            return state;
        case "SET_THE_PAGE_PALMS_FILTER":
            return reducerSetIfChangedThePage(state, "filter", action.value);
        case "SET_THE_PAGE_DETAILS_ID":
            if (state.thePageState.detailId !== action.value?.id)
                if (!action.value?.id) {
                    // restore old filter
                    // console.log('state (0)=> ', state.thePageState);
                    const dis = _.clone(state.thePageState.detailIdStack) ?? [];
                    const lastId = dis.pop();
                    const pdf = _.clone(state.thePageState.preDetailFilterStack) ?? [];
                    const lf = pdf.pop();
                    // console.log('dis => ', dis);
                    // console.log('lastId => ', lastId);
                    // console.log('pdf => ', pdf);
                    // console.log('lf => ', lf);
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            detailId: lastId,
                            filter: lf,
                            preDetailFilterStack: pdf,
                            detailIdStack: dis,
                        }
                    };
                }
                else {
                    // store current filter
                    const pdf = _.clone(state.thePageState.preDetailFilterStack) ?? [];
                    pdf.push(_.clone(state.thePageState.filter));
                    // store old details id if available
                    const dis = _.clone(state.thePageState.detailIdStack) ?? [];
                    dis.push(state.thePageState.detailId);
                    // console.log('state (1) => ', _.clone(state.thePageState));
                    // console.log('dis => ', dis);
                    // console.log('pdf => ', pdf);
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            detailId: action.value?.id,
                            filter: {
                                ...state.thePageState.filter,
                                ...action.value?.filter
                            },
                            preDetailFilterStack: pdf,
                            detailIdStack: dis,
                        }
                    };
                }
            return state;
        case "DELETE_ELEMENT_FROM_THE_PAGE_EDIT_DASHBOARD":
            if (state.thePageState.editDashboard && state.thePageState.editMode) {
                const db = _.clone(state.thePageState.editDashboard);
                const i = DashboardHelpers.getLayoutId(action.value);
                const idx = _.findIndex(db.elements, e => DashboardHelpers.getLayoutId(e) === i);
                if (idx >= 0) {
                    db.elements.splice(idx, 1);
                    return {
                        ...state,
                        thePageState: {
                            ...state.thePageState,
                            editDashboard: db,
                        }
                    };
                }
            }
            return state;


        case "SET_CHAT_ADD":
            return reducerSetIfChangedChat(state, "addingChat", action.value);
        case "SET_CHAT":
            return reducerSetIfChangedChat(state, "chat", action.value);
        case "SET_CHAT_MEMBERS":
            return reducerSetIfChangedChat(state, "members", action.value);
        case "SET_CHAT_UPDATE_INFO_CHAT":
            return reducerSetIfChangedChat(state, "updateInfoChat", action.value);
        case "SET_CHAT_UPDATE_INFO_CHATS":
            return reducerSetIfChangedChat(state, "updateInfoChats", action.value);
        case "SET_CHATS":
            return reducerSetIfChangedChat(state, "chats", action.value);
        case "SET_CHAT_UPDATE_MESSAGES":
            return reducerSetIfChangedChat(state, "messages", action.value);
        case "SET_FEED_TRENDING":
            return reducerSetIfChangedFeed(state, "trending", action.value);
        case "SET_FEED_RIGHT_SIDEBAR":
            return reducerSetIfChangedFeed(state, "rightSidebar", action.value);
        case "SET_FEED_SHOW":
            return reducerSetIfChangedFeed(state, "show", action.value);
        case "SET_FEED_RERENDER":
            return reducerSetIfChangedFeed(state, "rerender", action.value);
        case "SET_FEED_SAVE":
            return reducerSetIfChangedFeed(state, "save", action.value);
        case "SET_FEED_NEXT":
            return reducerSetIfChangedFeed(state, "next", action.value);
        case "SET_FEED_SELECTED_TAB":
            return reducerSetIfChangedFeed(state, "selectedTab", action.value);
        case "SET_FEED_FACETS":
            return reducerSetIfChangedFeed(state, "facets", action.value);

        // global search stuff
        // case "SET_GLOBAL_SCROLL_TO":
        //     return returnNewState({
        //         ...state,
        //         globalSearchState: {
        //             ...state.globalSearchState,
        //             scrollTo: action.scrollTo
        //         }
        //     });
        case "SET_GLOBAL_VIEW_STATS":
            return reducerSetIfChangedGlobalSearch(state, "viewStats", action.value);
        case "ADD_GLOBAL_VIEW_STATS":
            return reducerSetIfChangedGlobalSearch(state, "viewStats", [...state.globalSearchState.viewStats, ...action.value]);
        case "SET_GLOBAL_SEARCH":
            return reducerSetIfChangedGlobalSearch(state, "search", action.value);
        case "ADD_GLOBAL_SEARCH_RESULTS":
            {
                const eles = _.orderBy([...state.globalSearchState.results, ...action.value], "score", "desc");
                const results = _.uniqBy(eles, i => {
                    if (i.hasOwnProperty("id"))
                        return (i as any).id;
                    else
                        return (i as any)?.item?.id;
                });
                return {
                    ...state,
                    globalSearchState: {
                        ...state.globalSearchState,
                        results
                    }
                };
            }
        case "CLEAR_GLOBAL_SEARCH_RESULTS":
            return {
                ...state,
                globalSearchState: {
                    ...state.globalSearchState,
                    results: [],
                }
            };
        case "SET_GLOBAL_SEARCH_LAST_SEARCH":
            return reducerSetIfChangedGlobalSearch(state, "lastSearch", action.value);
        case "SET_GLOBAL_SEARCH_SET_FILTER":
            {
                const oldF = _.clone(state.globalSearchState.globalSearchFilter);
                const f = { ...oldF, ...action.value };
                if (!_.isEqual(f, state.globalSearchState.globalSearchFilter))
                    return {
                        ...state,
                        globalSearchState: {
                            ...state.globalSearchState,
                            globalSearchFilter: f
                        }
                    };
            }
            return state;
        case "SET_GLOBAL_SEARCH_SET_SYSTEMS":
            return reducerSetIfChangedGlobalSearch(state, "globalSearchSystems", action.value);
        /* CMS Stuff */
        case "SET_CMS_LOADING_PROJECT_FAILED":
            return reducerSetIfChangedCMS(state, "loadingProjectFailed", action.value);
        case "SET_CMS_LOADING_FILES_FAILED":
            return reducerSetIfChangedCMS(state, "loadingFilesFailed", action.value);
        case "SET_CMS_CURRENT_PROJECT_AND_WFS":
            if (!_.isEqual(state.cmsState.currentProject, action.value?.project)) {
                return {
                    ...state,
                    cmsState: {
                        ...state.cmsState,
                        currentProject: action.value?.project,
                        workflows: action.value?.workflows,
                    },
                };
            }
            return state;
        case "SET_CMS_TABLE_VIEW":
            return reducerSetIfChangedCMS(state, "tableView", action.value);
        case "SET_CMS_TEMPLATE":
            return reducerSetIfChangedCMS(state, "template", action.value);
        case "SET_CMS_PROJECTS":
            return reducerSetIfChangedCMS(state, "projects", action.value);
        case "SET_CMS_CONTENT_PROJECTS":
            return reducerSetIfChangedCMS(state, "contentProjects", action.value);
        case "SET_CMS_FILES":
            return reducerSetIfChangedCMS(state, "currentFiles", action.value);
        case "SET_CMS_TRANSLATION_BATCHES":
            return reducerSetIfChangedCMS(state, "translationBatches", action.value);
        case "SET_CMS_LOCATIONS":
            if (!_.isEqual(state.cmsState.locations, action.value)) {
                const l = _.find((action.value as ContentPostContentLocationDownloadDto[]), ll => ll.id === state.cmsState.location?.id) ?? (action.value ?? [])[0];
                return {
                    ...state,
                    cmsState: {
                        ...state.cmsState,
                        locations: action.value,
                        location: l
                    }
                }
            }
            return state;
        case "SET_CMS_CATEGORIES":
            return reducerSetIfChangedCMS(state, "categories", action.value);
        case "SET_CMS_CATEGORY_CONFIGURATION":
            return reducerSetIfChangedCMS(state, "categoryConfiguration", action.value);
        case "SET_CMS_LOCATION":
            return reducerSetIfChangedCMS(state, "location", action.value);
        case "UPDATE_CMS_FILE": {
            const res = cmsFindIndexPathAndFileInCurrentFiles(action.value.id, state.cmsState.currentFiles);
            if (res) {
                const nfs = _.clone(state.cmsState.currentFiles)!;
                if (!_.isEqual(nfs[res.key][res.idx], action.value)) {
                    nfs[res.key].splice(res.idx, 1, action.value);
                    return {
                        ...state,
                        cmsState: {
                            ...state.cmsState,
                            currentFiles: nfs
                        }
                    }
                }
            }
        }
            return state;
        case "SET_CMS_FILES_GRID_SELECTED_ROWS": {
            const path = state.params?.path as string[];
            const curDir = getCurrentDirectory(state.cmsState.currentProject, path);
            const selectedFiles: FileEntryDownloadDto[] = [];
            const selectedDirs: FileEntriesDownloadDto[] = [];
            _.forEach(action.value, av => {
                const s = (av as FileEntryDownloadDto);
                if (s) {
                    if (s?.contents !== undefined) {
                        // file!
                        const e = _.find(curDir?.entries, i => i === s.id);
                        if (e) {
                            const res = cmsFindIndexPathAndFileInCurrentFiles(e, state.cmsState.currentFiles);
                            if (res?.file) {
                                selectedFiles.push(res.file);
                            }
                        }
                    }
                    else {
                        const d = av as FileEntriesDownloadDto;
                        if (_.find(curDir?.directories, i => i.name === d.name))
                            selectedDirs.push(d);
                    }
                }
            });
            const sfI = _.map(state.cmsState.selectedFiles, sf => sf.id);
            const sfINew = _.map(selectedFiles, sf => sf.id);

            //console.log("Reducer.tsx::1049 => sfI", sfI);
            //console.log("Reducer.tsx::1049 => sfINew", sfINew);

            const dN = _.map(state.cmsState.selectedDirs, de => de.name);
            const dNNew = _.map(selectedDirs, de => de.name);
            //console.log("Reducer.tsx::1049 => dN", dN);
            //console.log("Reducer.tsx::1049 => dNNew", dNNew);

            const toChange = _.difference(sfI, sfINew).length > 0 || _.difference(sfINew, sfI).length > 0 || _.difference(dN, dNNew).length > 0 || _.difference(dNNew, dN).length > 0;

            //console.log("Reducer.tsx::1059 => toChange", toChange);
            if (toChange) {
                return {
                    ...state,
                    cmsState: {
                        ...state.cmsState,
                        selectedDirs,
                        selectedFiles
                    }
                }
            }

        }
            return state;
        case "REMOVE_CMS_FILE": {
            const res = cmsFindIndexPathAndFileInCurrentFiles(action.value.id, state.cmsState.currentFiles);
            if (res) {
                const nfs = _.clone(state.cmsState.currentFiles)!;
                nfs[res.key].splice(res.idx, 1);
                return {
                    ...state,
                    cmsState: {
                        ...state.cmsState,
                        currentFiles: nfs
                    }
                }
            }
        }
            return state;
        case "ADD_CMS_FILE_POSTS": {
            if (!_.isEqual(state.cmsState.filePosts[action.value.id], action.value.posts)) {
                const fp = _.clone(state.cmsState.filePosts);
                if (action.value.posts && action.value.posts.length > 0)
                    fp[action.value.id] = action.value.posts;
                else
                    delete fp[action.value.id];
                return {
                    ...state,
                    cmsState: {
                        ...state.cmsState,
                        filePosts: fp
                    }
                };
            }
            return state;
        }
        case "CLEAR_CMS_SEARCH_RESULT_ENTRIES":
            return {
                ...state,
                cmsState: {
                    ...state.cmsState,
                    searchResultsFiles: state.cmsState.searchResultsFiles ? {
                        ...state.cmsState.searchResultsFiles,
                        results: []
                    } : undefined,
                    searchResultsProjects: state.cmsState.searchResultsProjects ? {
                        ...state.cmsState.searchResultsProjects,
                        results: []
                    } : undefined,

                    contentSearchResultsFiles: state.cmsState.contentSearchResultsFiles ? {
                        ...state.cmsState.contentSearchResultsFiles,
                        results: []
                    } : undefined,
                    contentSearchResultsProjects: state.cmsState.contentSearchResultsProjects ? {
                        ...state.cmsState.contentSearchResultsProjects,
                        results: []
                    } : undefined,
                }
            }
        case "SET_CMS_SEARCH_RESULTS_FILES":
            return reducerSetIfChangedCMS(state, "searchResultsFiles", action.value);
        case "SET_CMS_SEARCH_RESULTS_PROJECTS":
            return reducerSetIfChangedCMS(state, "searchResultsProjects", action.value);
        case "SET_CMS_RIGHT_SIDEBAR":
            return reducerSetIfChangedCMS(state, "rightSidebar", action.value);
        case "SET_CMS_CONTENT_SEARCH_RESULTS_FILES":
            return reducerSetIfChangedCMS(state, "contentSearchResultsFiles", action.value);
        case "SET_CMS_CONTENT_SEARCH_RESULTS_PROJECTS":
            return reducerSetIfChangedCMS(state, "contentSearchResultsProjects", action.value);
        case "SET_CMS_FILTER_TERM":
            return reducerSetIfChangedCMS(state, "filterTerm", action.value);
        case "SET_CMS_DOWNLOADLINK":
            return reducerSetIfChangedCMS(state, "downloadLink", action.value);
        case "SET_CMS_SHOW_ARCHIVE":
            return reducerSetIfChangedCMS(state, "showArchive", action.value);
        case "SET_CMS_SHOW_UPLOAD":
            return reducerSetIfChangedCMS(state, "showUpload", action.value);
        case "SET_CMS_SHOW_ADD_REFERENCE":
            return reducerSetIfChangedCMS(state, "showAddReference", action.value);
        case "SET_CMS_FILE_TO_RENAME":
            return reducerSetIfChangedCMS(state, "fileToRename", action.value);
        case "SET_CMS_FILE_TO_MOVE":
            return reducerSetIfChangedCMS(state, "fileToMove", action.value);
        case "SET_CMS_FOLDER_TO_RENAME":
            return reducerSetIfChangedCMS(state, "folderToRename", action.value);
        case "SET_CMS_SHOW_PROJECT_INFO":
            return reducerSetIfChangedCMS(state, "showProjectInfo", action.value);
        case "SET_CMS_SHOW_DOWNLOAD_INFO":
            return reducerSetIfChangedCMS(state, "showDownloadInfo", action.value);
        case "SET_CMS_WORKFLOW_TYPE_IS_GROUP":
            return reducerSetIfChangedCMS(state, "wfTypeIsGroup", action.value);
        case "SET_CMS_SHOW_TASKS":
            return reducerSetIfChangedCMS(state, "showTasks", action.value);
        case "SET_CMS_ZIP_PROGRESS":
            return reducerSetIfChangedCMS(state, "zipProgresses", action.value);
        case "SET_CMS_DIRECTORY_AND_FILE_ACTIONS_DISABLED":
            return reducerSetIfChangedCMS(state, "directoryAndFileActionsDisabled", action.value);
        case "SET_CMS_ACTION_RESULT":
            return reducerSetIfChangedCMS(state, "actionResult", action.value);
        case "ADD_CMS_PROJECTS_FILES_COUNT":
            return reducerSetIfChangedCMS(state, "projectsFilesCount", action.value);
        /* ADMIN Stuff*/
        case "ADMIN_SET_GROUP":
            return reducerSetIfChangedAdmin(state, "group", action.value);
        case "ADMIN_SET_LEFT_SIDEBAR":
            return reducerSetIfChangedAdmin(state, "leftSidebar", action.value);
        case "ADMIN_SET_RIGHT_SIDEBAR":
            return reducerSetIfChangedAdmin(state, "rightSidebar", action.value);

        /* Q and A Stuff*/
        case "SET_QANDA_REPLY":
            return reducerSetIfChangedQAndA(state, "reply", action.value);
        case "SET_QANDA_EDIT":
            return reducerSetIfChangedQAndA(state, "edit", action.value);
        case "SET_QANDA_EDIT_FORUM":
            return reducerSetIfChangedQAndA(state, "editForum", action.value);

        /* MyHome */

        case "SET_MYHOME_SHOWDETAILED_PAGE":
            return reducerSetIfChangedMyHome(state, "showDetailedPage", action.value);
        case "SET_MYHOME_SORTED_BY":
            return reducerSetIfChangedMyHome(state, "sortedBy", action.value);
        case "SET_MYHOME_SORT_ASCENDING":
            return reducerSetIfChangedMyHome(state, "sortAscending", action.value);
        case "SET_MYHOME_SHOW_PROGRESS_ONTRACK":
            return reducerSetIfChangedMyHome(state, "progressOnTrack", action.value);
        case "SET_MYHOME_SHOW_PROGRESS_INCOMPLETE":
            return reducerSetIfChangedMyHome(state, "progressIncomplete", action.value);
        case "SET_MYHOME_SHOW_PROGRESS_OVERDUE":
            return reducerSetIfChangedMyHome(state, "progressOverdue", action.value);
        case "SET_MYHOME_SHOW_PROGRESS_COMPLETED":
            return reducerSetIfChangedMyHome(state, "progressCompleted", action.value);

        /* General */
        case "SET_SHOW_FILTER_ON_LOADINGDATATABLE": {
            return {
                ...state,
                loadingDataTableState: {
                    ...state.loadingDataTableState,
                    showFilterOnLoadingDataTable: action.value
                }
            };
        }
        case "SET_SHOW_MOBILE_MENU": {
            return {
                ...state,
                mobileMenuState: {
                    ...state.mobileMenuState,
                    showMobileMenu: action.value
                }
            };
        }
        case "SET_LOGOUT_CONFIRMED": {
            return {
                ...state,
                mobileMenuState: {
                    ...state.mobileMenuState,
                    logoutConfirmed: action.value
                }
            };
        }

        case "SET_TOP_INBOX_MESSAGES":
            if (!_.isEqual(action.value, state.inboxState.topMessages))
                return {
                    ...state,
                    inboxState: {
                        ...state.inboxState,
                        topMessages: action.value
                    }
                }
            return state;
        case "SET_INBOX_MESSAGES":
            if (!_.isEqual(action.value, state.inboxState.messages))
                return {
                    ...state,
                    inboxState: {
                        ...state.inboxState,
                        messages: action.value
                    }
                }
            return state;
    }
}