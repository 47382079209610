import * as React from "react";
import { ApplicationComponentsFactory, PostContainerProps, PostProps } from "./ApplicationState/ApplicationComponentsFactory";

type RendererMap<T> = { [key: string]: (props: T) => JSX.Element };
export default class ComponentsFactory implements ApplicationComponentsFactory {
    static _factory?: ComponentsFactory;
    public static getInstance() {
        if (!ComponentsFactory._factory)
            ComponentsFactory._factory = new ComponentsFactory();
        return ComponentsFactory._factory;
    }

    private constructor() {

    }

    _postMaps: RendererMap<PostProps> = {};
    _postContainerRenderer?: (props: PostContainerProps) => JSX.Element;
    _postStandardRenderer?: (props: PostContainerProps) => JSX.Element;

    public setStandardPostRenderer = (renderer: (props: PostProps) => JSX.Element) => {
        this._postStandardRenderer = renderer;
    }

    public setPostContainerRenderer = (renderer: (props: PostContainerProps) => JSX.Element) => {
        this._postContainerRenderer = renderer;
    }

    public registerPostRenderer = (type: string, renderer: (props: PostProps) => JSX.Element) => {
        this._postMaps[type] = renderer;
    }

    public renderPost = (type: string) => (props: PostProps) => {
        const renderer = (this._postMaps[type]) ?? this._postStandardRenderer;
        return renderer(props);
    }

    public getPostContainerRenderer = () => {
        return this._postContainerRenderer ?? (() => <div />);
    }

}
