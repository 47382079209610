import { FileEntryDownloadDto } from "collaboration-service";
import SafeHTML from "components/SafeHTML/SafeHTML";
import { getLinkByName, Icon, ImgIcons, Tooltip, useThemePart } from "imaginarity-react-ui";
import * as React from "react";
import { ApplicationState, useAppSelector } from "services/ApplicationState/ApplicationState";
import { calculateDueInfo, shallowCompare } from "services/Helpers";
import { sanitizedNothingAllowedAttributes } from "services/Helpers/SanitizedHelper";
import { getCMSContent, getTranslated } from "services/Helpers/TranslationHelpers";
import { useImgI18N } from "services/ImgI18N";
import { PlaylistDetailNodeItemProps } from "../PlaylistDetailNodeItem";
import { PlaylistDetailSC as T } from "../PlaylistDetailSC";
import PlaylistFileEntryVideoPodcastDimmer from "./Dimmer/PlaylistFileEntryVideoPodcastDimmer";
import { documentKinds } from "./usePlaylistButtonText";
import PlaylistFileEntryPDFDimmer from "./Dimmer/PlaylistFileEntryPDFDimmer";
import PlaylistFileEntryImageDimmer from "./Dimmer/PlaylistFileEntryImageDimmer";
import PlaylistFileEntryDownloadDimmer from "./Dimmer/PlaylistFileEntryDownloadDimmer";
import { isInGroup } from "services/StoreDependantHelpers";
import { createNamedRoute } from "services/Helpers/RoutingHelper";
import { getParentIdFromDataBaseId } from "./FileEntryPlaylistItem";
import { podcastTheme } from "views/Podcast/PodcastMain";

const mapper = (s: ApplicationState) => ({
    currentGroup: s.currentGroup,
    contentLanguage: s.contentLanguage,
    filter: s.playlistsState.filter,
});

export const documentKindMap: { [mediaType: string]: documentKinds } = {
    "audio/mpeg": "audio",
    "image/tiff": "download",
    "video/mp4": "video",
    "video/quicktime": "video",
    "application/pdf": "pdf",
    "application/zip": "download",
    "application/x-zip-compressed": "download"
}

export const documentKindByType = (type?: string): documentKinds => {
    if (!type)
        return "document";
    var toRet = documentKindMap[type];
    if (toRet)
        return toRet;
    if (type.startsWith("image"))
        return "image";
    if (type.startsWith("application"))
        return "document";
    return "document";
}




const FileEntryPlaylistNodeItem = (p: PlaylistDetailNodeItemProps<FileEntryDownloadDto>) => {
    const { item, state, playlistId, playlistContent, isOptional, dueToType, assignmentDate, absoluteDueDate, dueDateRelativeInHours, nodeReference, transition, warning, isMobile, nodeIsDone } = p;
    const { contentLanguage, filter } = useAppSelector(mapper, shallowCompare);
    const { t, currentLanguage } = useImgI18N("tube");
    const language = contentLanguage ?? currentLanguage;
    const emptyImageUrl = useThemePart(t => t.emptyImageUrl);
    const podcastThumb = podcastTheme.noPreviewPodcastImage;
    const content = getCMSContent({ fileEntry: item, filePosts: {}, language })
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const result = calculateDueInfo(dueToType, state.state, assignmentDate, absoluteDueDate, dueDateRelativeInHours, isOptional, warning);
    const elementIsReference = item.id === nodeReference ?? false;
    const hideFinished = filter ? !filter['finished'] : false;
    const hideOnTrack = filter ? !filter['ontrack'] : false;
    const hideOvedue = filter ? !filter['overdue'] : false;
    const hideWarning = filter ? !filter['warning'] : false;
    const hideOptional = filter ? !filter['optional'] : false;

    const { icon, image, type }: { icon: ImgIcons, image: string, type: documentKinds, dwn?: string } = React.useMemo(() => {
        const type = documentKindByType(content?.media?.mediaType);
        const linkFrom = (content?.media?.thumbnails ?? [])[0] ?? content?.media;
        const image = getLinkByName(linkFrom, "self")?.uri ?? emptyImageUrl;
        switch (type) {
            case "document":
                return { icon: "file", image, type };
            case "video":
                return { icon: "video player", image, type };
            case "audio":
                return { icon: "audio", image: podcastThumb, type };
        }
        return { icon: "feed", image, type };
    }, [content, emptyImageUrl, podcastThumb]);
    const isInGroupCMS = isInGroup("CMS");
    const togglePreview = React.useMemo(() => () => {
        setShowModal(!showModal);
    }, [showModal]);

    if (hideFinished && result.text === "100%")
        return null;
    if (hideOnTrack && result.text === "on Track")
        return null;
    if (hideOvedue && result.text === "overdue")
        return null;
    if (hideWarning && result.text === "warning")
        return null;
    if (hideOptional && result.text === "optional")
        return null;

    const title = getTranslated(item?.headlines, contentLanguage)?.text ?? content?.media?.fileName;

    return (
        <T.PLItemContainer isMobile={isMobile}>
            <T.PLItemImage imageUrl={image} isMobile={isMobile} highlighted={elementIsReference} color={warning ? "@warning" : "@accentRed"} onClick={togglePreview} />
            <T.PLItemContainerContent isMobile={isMobile} onClick={togglePreview}>
                <T.PLItemContainerTitle isMobile={isMobile}>
                    {elementIsReference &&
                        <T.PLItemContainerTitleDot color={warning ? "@warning" : "@accentRed"} />
                    }
                    <SafeHTML html={title} allowedTags={[]} allowedAttributes={{}} />
                </T.PLItemContainerTitle>
                {!isMobile &&
                    <T.PLItemContainerDescription>
                        {item.descriptions &&
                            <SafeHTML
                                html={getTranslated(item.descriptions, contentLanguage).text ?? ""}
                                allowedTags={["strong", "span", "b", "i", "u"]}
                                allowedAttributes={sanitizedNothingAllowedAttributes}
                            />
                        }
                    </T.PLItemContainerDescription>
                }
                <T.PLItemContainerBottomCat isMobile={isMobile}>
                    {isInGroupCMS ?
                        <T.PLItemContainerBottomCatIconLink href={createNamedRoute("cms_file", { projectId: getParentIdFromDataBaseId(item.group_id), id: getParentIdFromDataBaseId(item.id), path: "" }, true)} >
                            <Tooltip tooltipText={t("open in cms")} noMargin notInline position="top">
                                <Icon name={icon} style={{ float: "left", marginTop: -3, marginLeft: 2 }} size="15px" color="@accentRed" />
                            </Tooltip>
                        </T.PLItemContainerBottomCatIconLink>
                        :
                        <Icon name={icon} marginTop={3} style={{ float: "left", marginLeft: -2, marginRight: 10 }} size="16px" />
                    }
                    {item.type === "FileEntry" ? type : item.type}
                    {playlistContent?.duration &&
                        <>
                            <span style={{ padding: "0 10px" }}>&bull;</span>
                            {`${Math.floor(playlistContent.duration * 60 * 100) / 100} Min`}
                        </>
                    }
                </T.PLItemContainerBottomCat>
            </T.PLItemContainerContent>
            <T.PLItemContainerRight isMobile={isMobile} onClick={togglePreview}>
                {isOptional ?
                    <T.ColoredDiv color={state.state === "Finished" ? "@accentGreen" : "inherit"}>
                        <div style={{ float: "right" }}>{state.state === "Finished" ? t("100%") : ""}</div>
                        <Icon name={state.state === "Finished" ? "check circle" : "empty"} style={{ float: "right", marginRight: 5 }} marginTop={2} color={state.state === "Finished" ? "@accentGreen" : "inherit"} />
                    </T.ColoredDiv>
                    :
                    (transition === "ReferenceDone" || transition === "ReferenceDoneWithValue") ?
                        elementIsReference ?
                            <T.ColoredDiv color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color}>
                                <div style={{ float: "right" }}>
                                    {(result.text === "100%" || nodeIsDone) ? "100%" : warning ? "warning" : result.text}
                                </div>
                                <Icon name={(result.text === "100%" || nodeIsDone) ? "check circle" : warning ? "exclamation triangle" : result.icon as ImgIcons} style={{ float: "right", marginRight: 5 }} marginTop={4} color={(result.text === "100%" || nodeIsDone) ? "@accentGreen" : warning ? "@warning" : result.color} />
                            </T.ColoredDiv>
                            :
                            <T.ColoredDiv color={(result.text === "100%" && nodeIsDone) ? "@accentGreen" : "@middleLightGrey"}>
                                <div style={{ float: "right" }}>
                                    {(result.text === "100%" && nodeIsDone) ? "100%" : t("optional")}
                                </div>
                                <Icon name="empty" style={{ float: "right", marginRight: 5 }} marginTop={4} />
                            </T.ColoredDiv>
                        :
                        <T.ColoredDiv color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}>
                            <div style={{ float: "right" }}>
                                {result.text}
                            </div>
                            <Icon
                                name={result.icon as ImgIcons}
                                style={{ float: "right", marginRight: 5 }}
                                marginTop={4}
                                color={result.text === "100%" ? "@accentGreen" : warning ? "@warning" : result.color}
                            />
                        </T.ColoredDiv>
                }
            </T.PLItemContainerRight>
            {showModal &&
                <>
                    {(type === "audio" || type === "video") &&
                        <PlaylistFileEntryVideoPodcastDimmer
                            isPodcast={type === "audio"}
                            playlistStateId={p.playlistStateId}
                            id={p.state.referenceId}
                            item={item}
                            result={result}
                            playlistId={playlistId ?? ""}
                            isOptional={isOptional ?? false}
                            transition={transition}
                            elementIsReference={elementIsReference}
                            nodeIsDone={nodeIsDone ?? false}
                            warning={warning ?? false}
                            state={state}
                            closeDimmer={togglePreview}
                        />
                    }
                    {type === "pdf" &&
                        <PlaylistFileEntryPDFDimmer
                            playlistStateId={p.playlistStateId}
                            id={p.state.referenceId}
                            item={item}
                            result={result}
                            playlistId={playlistId ?? ""}
                            isOptional={isOptional ?? false}
                            transition={transition}
                            elementIsReference={elementIsReference}
                            nodeIsDone={nodeIsDone ?? false}
                            warning={warning ?? false}
                            state={state}
                            closeDimmer={togglePreview}
                        />

                    }
                    {type === "image" &&
                        <PlaylistFileEntryImageDimmer
                            playlistStateId={p.playlistStateId}
                            id={p.state.referenceId}
                            item={item}
                            result={result}
                            playlistId={playlistId ?? ""}
                            isOptional={isOptional ?? false}
                            transition={transition}
                            elementIsReference={elementIsReference}
                            nodeIsDone={nodeIsDone ?? false}
                            warning={warning ?? false}
                            state={state}
                            closeDimmer={togglePreview}
                        />
                    }
                    {type === "document" &&
                        <PlaylistFileEntryDownloadDimmer
                            playlistStateId={p.playlistStateId}
                            id={p.state.referenceId}
                            item={item}
                            result={result}
                            playlistId={playlistId ?? ""}
                            isOptional={isOptional ?? false}
                            transition={transition}
                            elementIsReference={elementIsReference}
                            nodeIsDone={nodeIsDone ?? false}
                            warning={warning ?? false}
                            state={state}
                            closeDimmer={togglePreview}
                        />
                    }
                </>
            }
            {/*showModal && <PlaylistTubePodcastDimmer
                playlistStateId={p.playlistStateId}
                id={p.state.referenceId}
                item={item}
                link={link ?? ""}
                result={result}
                playlistId={playlistId ?? ""}
                isOptional={isOptional ?? false}
                transition={transition}
                elementIsReference={elementIsReference}
                nodeIsDone={nodeIsDone ?? false}
                warning={warning ?? false}
                state={state}
                closeDimmer={togglePreview}
            />
            */}
        </T.PLItemContainer>
    );
}
export default FileEntryPlaylistNodeItem;